import {PageLink, PageTitle} from "../../../_metronic/layout/core";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import {OffresHeader} from "./OffresHeader";
import {Offres} from "./components/Offres";
import {Favoris} from "./components/Favoris";
import {DetailsOffre} from "./components/DetailsOffre";

const offresBreadCrumbs: Array<PageLink> = [
    {
        title: "Offres",
        path: '/offres',
    }
]

const OffresPage = () => {
    return <Routes>
        <Route element={
            <>
                <Outlet/>
            </>
        }>
            <Route
                path='liste'
                element={
                    <>
                        <PageTitle breadcrumbs={offresBreadCrumbs}>Liste</PageTitle>
                        <OffresHeader/>
                        <Offres/>
                    </>
                }
            />

            <Route
                path=':id/details'
                element={
                    <>
                        <PageTitle breadcrumbs={offresBreadCrumbs}>Détails offre</PageTitle>
                        <DetailsOffre/>
                    </>
                }
            />

            <Route
                path='mes-favoris'
                element={
                    <>
                        <PageTitle breadcrumbs={offresBreadCrumbs}>Mes favoris</PageTitle>
                        <OffresHeader/>
                        <Favoris/>
                    </>
                }
            />

            <Route index element={<Navigate to='liste'/>}/>

        </Route>
    </Routes>

}

export default OffresPage