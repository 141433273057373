import { Modal } from "react-bootstrap"
import React from "react"
import { KTSVG } from "../../_metronic/helpers"

type Props = {
     title?: JSX.Element
     titleMiddle?: JSX.Element
     show: boolean
     handleClose: () => void
     /*
      * backdrop doc
      * true: enable opacité + si on click à l'exterieur du modal = close.
      * false: pas d'opacité + si on click à l'exterieur modal = pas close
      * static: avec opacité + si click à l'exterieur = pas close
      */
     backdrop?: boolean | "static"
     /*
      * keyboard: Close the modal when escape key is pressed
      * */
     keyboard?: boolean
     centered?: boolean
     size?: "sm" | "lg" | "xl"
     fullscreen?: true | undefined
     children: React.ReactNode
     noPadding?: boolean
     noHeader?: boolean
}

export default function MyModal({
                                     title,
                                     titleMiddle,
                                     show,
                                     handleClose,
                                     backdrop = "static",
                                     keyboard = true,
                                     centered = true,
                                     noPadding = false,
                                     size,
                                     fullscreen = undefined,
                                     children,
                                     noHeader = false,
                                }: Props) {
     return (
         <Modal
             className="modal fade"
             show={show}
             onHide={handleClose}
             keyboard={keyboard}
             backdrop={backdrop}
             centered={centered}
             size={size}
             dialogClassName={"vw-80"}
             enforceFocus={false}
             fullscreen={fullscreen}
             // scrollable={true}
         >
              <div className="modal-content">
                   {!noHeader && (
                       <div className="modal-header">
                            <h3 className="modal-title">{title}</h3>

                            <div>{titleMiddle}</div>

                            <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={handleClose}>
                                 <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-2x" />
                            </div>
                       </div>
                   )}
                   <div
                       className="modal-body"
                       style={{
                            ...(noPadding ? { padding: 0, margin: 0 } : {}),
                            // ...(!fullscreen ? { maxHeight: "85vh", overflowY: "scroll" } : {}),
                       }}
                   >
                        {children}
                   </div>
              </div>
         </Modal>
     )
}
