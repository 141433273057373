import { useEffect, useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import { useAuth } from "../core/Auth"
import { activate } from "../core/_requests"

export function ActivateAccount() {
     const params = new URLSearchParams(window.location.search)
     const token = params.get("token")

     const navigate = useNavigate()

     const [error, setError] = useState<string | null>(null)
     const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
     const { saveAuth, setCurrentUser } = useAuth()

     useEffect(() => {
          const root = document.getElementById("root")
          if (root) {
               root.style.height = "100%"
          }
          return () => {
               if (root) {
                    root.style.height = "auto"
               }
          }
     }, [])

     useEffect(() => {
          HandleSubmit()
     }, [])

     const HandleSubmit = () => {
          setIsSubmitting(true)
          setError(null)

          activate(token || "")
               .then(async r => {
                    setIsSubmitting(false)
                    saveAuth({ token: token || "" })
                    setCurrentUser(r.data)
               })
               .catch(error => {
                    if (error.response.status === 401) {
                         // redirect to reset password
                         navigate("/auth/forgot-password", {
                              state: {
                                   message: "Le lien de d'activation a expiré. Veuillez saisir votre adresse email pour réinitialiser votre mot de passe.",
                              },
                         })
                    } else {
                         setError("Impossible d'activer le compte.")
                    }
                    setIsSubmitting(false)
               })
     }

     // Rester sur la page uniquement si param token in URL sinon auth
     if (token) {
          return (
               <div className={"text-center"}>
                    {error && (
                         <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">{error}</div>
                         </div>
                    )}

                    <button
                         className={"btn btn-success"}
                         disabled={isSubmitting}
                         onClick={() => {
                              HandleSubmit()
                         }}
                    >
                         {!isSubmitting && (
                              <>
                                   Activer mon compte <i className={"fas fa-check-circle fs-4"} />
                              </>
                         )}
                         {isSubmitting && <>Activation en cours...</>}
                    </button>
               </div>
          )
     } else {
          return <Navigate replace to="/auth" />
     }
}
