import React, { useRef, useState } from "react"
import * as Yup from "yup"
import { useQueryClient } from "react-query"
import { useNdfUpdateDetailsContext } from "../NdfUpdateDetailsWrapper"
import { INdfDetailEditDepenseSimpleRequestModel } from "../../../../../core/_models"
import { ConsultantNdfDetailModel, ConsultantNdfDocumentModel, IHttpErrorResponseModel } from "../../../../../../../../models"
import MyDateDayMonthYearField from "../../../../../../../../utils/fields/MyDateDayMonthYearField"
import { Form, Formik, FormikHelpers } from "formik"
import { ndfDetailAddDocument, ndfDetailDocumentDelete, ndfDetailDocumentReplace, ndfDetailEditDepenseSimple } from "../../../../../core/_request"
import { toast } from "react-toastify"
import axios, { AxiosError } from "axios"
import {
     API_URL_ESPACE_AVENTURIER,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "../../../../../../../../constants"
import MyAlert from "../../../../../../../../utils/MyAlert"
import MySelectField from "../../../../../../../../utils/fields/MySelectField"
import MySelectCreatableField from "../../../../../../../../utils/fields/MySelectCreatableField"
import MySimpleSpinner from "../../../../../../../../utils/MySimpleSpinner"
import MyInputField from "../../../../../../../../utils/fields/MyInputField"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import MyTooltip from "../../../../../../../../utils/MyTooltip"
import MyTextareaField from "../../../../../../../../utils/fields/MyTextareaField"

const NdfUpdateDetailsEditDepenseSimple = ({ detail }: INdfUpdateDetailsEditDepenseSimplePropsModel) => {
     const queryClient = useQueryClient()
     const context = useNdfUpdateDetailsContext()
     const [docToBeReplaced, setDocToBeReplaced] = useState<ConsultantNdfDocumentModel>()
     const refReplaceDocInput = useRef<any>(null)
     const refAddDocInput = useRef<any>(null)
     const MySwal = withReactContent(Swal)

     // Schema
     const schema = Yup.object().shape({
          date: Yup.string().required("Champ requis"),
          categorie: Yup.string().required("Champ requis"),
          montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect."),
          tva: Yup.number(),
          description: Yup.string().required("Champ requis"),
          documents: Yup.array().of(
               Yup.mixed()
                    .required("PJ requise")
                    .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "La PJ doit être au format JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    })
          ),
          repasSeulOuEnGroupe: Yup.string()
               .label(`"Seul ou en groupe"`)
               .when("categorie", {
                    is: CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                    then: schema => schema.required(),
               })
               .nullable(),
          invites: Yup.array()
               .label(`"Invités"`)
               .when("repasSeulOuEnGroupe", {
                    is: "REPAS_MIDI_EN_GROUPE",
                    then: schema => schema.required(),
               })
               .nullable(),
     })

     const initialValues: INdfDetailEditDepenseSimpleRequestModel = {
          date: detail.date!.format("DD/MM/YYYY"),
          categorie: detail.categorie as string,
          montantTTC: detail.montantTtc as number,
          description: detail.description || "",
          repasSeulOuEnGroupe: detail.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI ? (detail.invites ? "REPAS_MIDI_EN_GROUPE" : "REPAS_MIDI_SEUL") : undefined,
          invites: detail.invites || undefined,
     }

     function handleSubmit(values: INdfDetailEditDepenseSimpleRequestModel, helpers: FormikHelpers<INdfDetailEditDepenseSimpleRequestModel>) {
          helpers.setStatus(null)
          ndfDetailEditDepenseSimple(detail.id as number, values)
               .then(r => {
                    queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                         if (prev) {
                              const index = prev.findIndex(item => item.id === detail.id)
                              if (index >= 0) {
                                   prev[index] = {
                                        ...prev[index],
                                        ...r.data,
                                   }
                              }
                         }
                         context.computeTotalExpenses(prev || [])

                         return prev
                    })

                    helpers.setSubmitting(false)
                    toast.success("Vos modifications ont bien été prises en compte.")
                    context.setCurrentDepenseInEdition(undefined)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    if (error?.code === "REGULARATION_IS_REQUIRED") {
                         helpers.setStatus(
                              <>
                                   <p>{error?.detail}</p>
                                   <b className={"mb-2 fs-5"}>Merci de suivre les étapes suivantes:</b>
                                   <ul>
                                        <li>Accédez à la liste des déclarations.</li>
                                        <li>Demandez la régularisation de la déclaration du mois concerné.</li>
                                        <li>Attendez la validation de votre responsable (utilisez ce temps pour finaliser la déclaration du mois en cours).</li>
                                        <li>Une fois la demande validée, ajoutez votre dépense et vérifiez qu'il n'y a plus de ticket restaurant.</li>
                                        <li>Finaliser et signer votre déclaration.</li>
                                   </ul>
                              </>
                         )
                    } else {
                         helpers.setStatus(error?.detail)
                    }
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     function handleViewJustificatif(e, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Chargement du justificatif en cours ...", { autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "arraybuffer",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data], { type: mimeType }))
                    window.open(url)

                    toast.dismiss()
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     function handleAddDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Ajout en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               ndfDetailAddDocument(detail.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                              if (prev) {
                                   prev.map(item => {
                                        if (item.id === detail.id) {
                                             item.consultantNdfDocuments = [...(item.consultantNdfDocuments || []), r.data]
                                             return item
                                        }
                                   })
                              }

                              return prev
                         })

                         toast.dismiss()
                         toast.success("Le justificatif a bien été ajouté")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleReplaceDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Remplacement en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               ndfDetailDocumentReplace(docToBeReplaced?.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                              if (prev) {
                                   prev.map(item => {
                                        if (item.id === detail.id) {
                                             item.consultantNdfDocuments?.map(item_ => {
                                                  if (item_.id === docToBeReplaced?.id) {
                                                       item_.googleDriveID = r.data.googleDriveID
                                                       item_.googleDriveMimeType = r.data.googleDriveMimeType
                                                       return item_
                                                  }
                                             })
                                        }
                                   })
                              }

                              return prev
                         })

                         toast.dismiss()
                         toast.success("Le justificatif a bien été remplacé")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleRemoveDoc(id: number, index: number) {
          MySwal.fire({
               icon: "warning",
               title: `Confirmez-vous la suppression du justificatif n°${index}?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return ndfDetailDocumentDelete(id)
                         .then(() => {
                              queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                                   if (prev) {
                                        prev.map(item => {
                                             if (item.id === detail.id) {
                                                  item.consultantNdfDocuments = item.consultantNdfDocuments?.filter(item_ => item_.id !== id)
                                             }
                                        })
                                   }

                                   return prev
                              })
                              toast.success("Le justificatif a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !MySwal.isLoading(),
          }).then()
     }

     return (
          <>
               <div className={"p-4 border border-dashed mb-10"}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                         <h4>Justificatifs</h4>
                         <MyTooltip title={"Ajouter un justificatif"}>
                              <div className="btn btn-icon btn-sm btn-active-light-success" onClick={() => refAddDocInput.current.click()}>
                                   <i className={"fas fa-plus-circle fs-2 text-success"} />
                                   <input type="file" className={"d-none"} ref={refAddDocInput} onChange={handleAddDoc} />
                              </div>
                         </MyTooltip>
                    </div>
                    <div className="separator separator-dashed mb-6" />

                    {detail.consultantNdfDocuments?.map((item, key) => (
                         <div className={`d-flex align-items-center ${key > 0 && "mt-4"}`} key={key}>
                              <span className={"me-2"}>Justificatif n° {key + 1}: </span>
                              <button
                                   className={"btn btn-sm btn-primary p-0 px-2 py-1 d-flex align-items-center me-2"}
                                   onClick={e => handleViewJustificatif(e, item.googleDriveID as string, item.googleDriveMimeType as string)}
                              >
                                   Consulter <i className={"fas fa-eye ms-1"} />
                              </button>
                              <button
                                   className={"btn btn-sm btn-warning p-0 px-2 py-1 d-flex align-items-center me-2"}
                                   onClick={() => {
                                        setDocToBeReplaced(item)
                                        refReplaceDocInput.current.click()
                                   }}
                              >
                                   Remplacer <i className={"fas fa-sync ms-1"} />
                              </button>
                              <input type="file" className={"d-none"} ref={refReplaceDocInput} onChange={handleReplaceDoc} />
                              <button className={"btn btn-sm btn-danger p-0 px-2 py-1 d-flex align-items-center me-2"} onClick={() => handleRemoveDoc(item.id as number, key + 1)}>
                                   Supprimer <i className={"fas fa-trash ms-1"} />
                              </button>
                         </div>
                    ))}
               </div>

               <div className={"p-4 border border-dashed mb-4"}>
                    <h4 className={"mb-4"}>Détails</h4>
                    <div className="separator separator-dashed mb-6" />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                         {({ values, setFieldValue, isSubmitting, status, errors }) => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}

                                        <div className={"row mb-4"}>
                                             <div className={"col-lg-4 mb-lg-0 mb-2"}>
                                                  <MyDateDayMonthYearField onChange={date => setFieldValue(`date`, date)} value={values.date} name={`date`} placeholder={"Date"} />
                                                  {errors.date && <div className={"text-danger"}>{errors.date}</div>}
                                             </div>
                                             <div className={"col-lg-4 mb-lg-0 mb-2"}>
                                                  <MySelectField
                                                       options={CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                                       name={`categorie`}
                                                       isSearchable={false}
                                                       isClearable={false}
                                                       placeholder={"Catégorie"}
                                                       value={values.categorie}
                                                       onChange={value => setFieldValue(`categorie`, value)}
                                                  />
                                                  {errors.categorie && <div className={"text-danger"}>{errors.categorie}</div>}
                                             </div>
                                             <div className={"col-lg-4 mb-lg-0 mb-2"}>
                                                  <MyInputField
                                                       name={"montantTTC"}
                                                       value={values.montantTTC}
                                                       isInvalid={!!errors.montantTTC}
                                                       type={"number"}
                                                       inputMode={"numeric"}
                                                       placeholder={"Montant TTC"}
                                                       onChange={val => setFieldValue("montantTTC", val)}
                                                  />
                                                  {errors.montantTTC && <div className={"text-danger"}>{errors.montantTTC}</div>}
                                             </div>

                                             {values.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                  <>
                                                       <div className={"col-12 mt-2"}>
                                                            <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                 Veuillez indiquer s'il s'agit d'un repas seul ou en groupe
                                                            </MyAlert>
                                                            <MySelectField
                                                                 name={"repasSeulOuEnGroupe"}
                                                                 options={[
                                                                      {
                                                                           value: "REPAS_MIDI_SEUL",
                                                                           label: "Repas seul",
                                                                      },
                                                                      {
                                                                           value: "REPAS_MIDI_EN_GROUPE",
                                                                           label: "Repas en groupe",
                                                                      },
                                                                 ]}
                                                                 value={values.repasSeulOuEnGroupe}
                                                                 onChange={val => setFieldValue("repasSeulOuEnGroupe", val)}
                                                                 placeholder={"Choisissez si seul ou en groupe"}
                                                            />
                                                            {errors.repasSeulOuEnGroupe && <div className={"text-danger"}>{errors.repasSeulOuEnGroupe}</div>}
                                                       </div>

                                                       {values.repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                            <>
                                                                 <div className={"col-12 mt-2"}>
                                                                      <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                           Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un client, ajoutez le nom de la
                                                                           société en plus.
                                                                      </MyAlert>
                                                                      <MySelectCreatableField
                                                                           name={"invites"}
                                                                           options={[]}
                                                                           value={values.invites}
                                                                           onChange={val => setFieldValue("invites", val)}
                                                                           isInvalid={!!errors.invites}
                                                                           placeholder={"Saisissez les invités"}
                                                                      />
                                                                      {errors.invites && <div className={"text-danger"}>{errors.invites}</div>}
                                                                 </div>
                                                            </>
                                                       )}
                                                  </>
                                             )}
                                             <div className={"col-12 mt-5"}>
                                                  <MyTextareaField
                                                       name={"description"}
                                                       value={values.description}
                                                       isInvalid={!!errors.description}
                                                       rows={2}
                                                       onChange={val => setFieldValue("description", val)}
                                                       placeholder={"Description"}
                                                  />
                                                  {errors.description && <div className={"text-danger"}>{errors.description}</div>}
                                             </div>
                                        </div>

                                        <div className={"text-end"}>
                                             <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={isSubmitting}>
                                                  {!isSubmitting ? <>Appliquer le changement</> : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </div>
          </>
     )
}

interface INdfUpdateDetailsEditDepenseSimplePropsModel {
     detail: ConsultantNdfDetailModel
}

export default NdfUpdateDetailsEditDepenseSimple
