import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { AuthPage } from "../modules/auth"

const PublicRoutes = () => {
     const location = useLocation()

     // location.pathname does not contain auth in the string
     if (!location.pathname.includes("auth")) localStorage.setItem("prevUrl", location.pathname + location.search)

     return (
          <Routes>
               <Route path="auth/*" element={<AuthPage />} />
               <Route path="*" element={<Navigate to="/auth" />} />
          </Routes>
     )
}

export default PublicRoutes
