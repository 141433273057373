import { Link, useNavigate } from "react-router-dom"
import React, { Dispatch, SetStateAction, useMemo, useState } from "react"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { craAddLigneMission, craRemoveMission, craSetHeuresDayAbsence, craSetHeuresDayMission, craSetHeuresMissionForTheEntireMonth } from "../../../core/_request"
import { ConsultantCraModel } from "../../../../../../models"
import { useIntl } from "react-intl"
import {
     API_GET_REFERENCES_MISSION_CONSULTANT_FOR_GIVEN_MONTH,
     CONST_CRA_DETAILS_ABSCENCE_AUTORISEE,
     CONST_CRA_DETAILS_ABSCENCE_CEX,
     CONST_CRA_DETAILS_ABSCENCE_CMA,
     CONST_CRA_DETAILS_ABSCENCE_CPA,
     CONST_CRA_DETAILS_ABSCENCE_CSS,
     CONST_CRA_DETAILS_ABSCENCE_FERIES,
     CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT,
     CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR,
     CONST_CRA_DETAILS_ABSCENCE_RTT,
     CONST_CRA_DETAILS_ABSCENCE_SFP,
     CONST_ENTITE_CODE_GS,
} from "../../../../../../constants"
import moment from "moment"
import MyTooltip from "../../../../../../utils/MyTooltip"
import MySelectGetAllDataFromServer from "../../../../../../utils/fields/MySelectGetAllDataFromServer"
import MyModal from "../../../../../../utils/MyModal"
import * as Yup from "yup"
import { ErrorMessage, Form, Formik } from "formik"
import { AxiosError } from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import "./styles.scss"
import { FirstLetterUppercase, sumTwoNumbers } from "../../../../../../helpers/helper-global"
import { useAuth } from "../../../../../../modules/auth"
import { KTSVG } from "../../../../../../../_metronic/helpers"

export const CraUpdateGrilleTemps: React.FC<CraUpdateGrilleTempsProps> = ({ data, setData, editionModeEnabled = true }) => {
     const { currentUser } = useAuth()
     const MySwal = withReactContent(Swal)
     const intl = useIntl()

     const [modalAddMissionRowIsShown, setModalAddMissionRowIsShown] = useState<boolean>(false)
     const [modalFillAllRowRefMissionWithHeures, setModalFillAllRowRefMissionWithHeures] = useState<{ isShown: boolean; mission_id: null | number; mission_ref: null | string }>({
          isShown: false,
          mission_id: null,
          mission_ref: null,
     })

     const [modifyHeuresTravailCellInModal, setModifyHeuresTravailCellInModal] = useState<
          | {
                 cra_id: number
                 mission_id: number
                 mission_ref: string
                 date: moment.Moment
                 heuresParDefaut: number
            }
          | undefined
     >()

     const [modifyHeuresAbsenceCellInModal, setModifyHeuresAbsenceCellInModal] = useState<
          | {
                 cra_id: number
                 absence_type: string
                 absence_type_label: string
                 date: moment.Moment
                 heuresParDefaut: number
            }
          | undefined
     >()

     const heuresParRefMissionParJour: heuresParRefMissionParJourType[] = (() => {
          let list: heuresParRefMissionParJourType[] = []

          data.consultantCraDetails.map(craDetails => {
               craDetails.consultantCraDetailsHeures.map(craDetailsHeure => {
                    const ref = craDetailsHeure.mission ? craDetailsHeure.mission.reference : "INTERCONTRAT"
                    const id = craDetailsHeure.mission ? craDetailsHeure.mission.id : 0
                    const index = list.findIndex(item => item.refMission == ref)
                    if (index == -1) {
                         list.push({ idMission: id, refMission: ref, items: [{ jour: craDetails.date, heures: craDetailsHeure.heuresJour }] })
                    } else {
                         list[index] = { ...list[index], items: [...list[index].items, { jour: craDetails.date, heures: craDetailsHeure.heuresJour }] }
                    }
               })
          })
          return list
     })()

     type joursAvecAbsenceType = { jour: moment.Moment; absence: string; heuresAbsence: number }
     const joursAvecAbsence: joursAvecAbsenceType[] = (() => {
          let list: joursAvecAbsenceType[] = []
          data.consultantCraDetails.map(craDetails => {
               if (craDetails.absence) list.push({ absence: craDetails.absence, heuresAbsence: craDetails.absenceHeures, jour: craDetails.date })
          })

          return list
     })()

     const handleRemoveMissionRow = (mission_id: number, mission_ref: string) => {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer la ligne “${mission_ref}“?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return craRemoveMission(data.id, mission_id)
                         .then(r => {
                              setData(r.data)
                              toast.success("La ligne a bien été supprimée.")
                         })
                         .catch((e: AxiosError) => {
                              const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                              toast.error(error, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     // Chaque type d'absence a sa propre row
     const TableBodyHeuresAbsenceParTypeDeAbsenceRows = () => {
          return (
               <>
                    <tr className={""}>
                         <td scope="row" className={"border-end sticky-column"}>
                              <span className={"text-primary fw-bold ps-sm-2"}>ABSENCES</span>
                         </td>
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Congés Payés
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_CPA && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_CPA,
                                                  absence_type_label: "Congés Payés",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    {currentUser?.consultant?.consultant?.entite?.code == CONST_ENTITE_CODE_GS && (
                         <tr>
                              <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                                   RTT - Réduction du temps de travail
                              </td>
                              {data.consultantCraDetails.map((craDetails, key) => {
                                   const item = joursAvecAbsence.find(
                                        item => item.absence == CONST_CRA_DETAILS_ABSCENCE_RTT && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                                   )
                                   return editionModeEnabled ? (
                                        <td
                                             key={key}
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             onClick={() =>
                                                  setModifyHeuresAbsenceCellInModal({
                                                       cra_id: data.id,
                                                       absence_type: CONST_CRA_DETAILS_ABSCENCE_RTT,
                                                       absence_type_label: "RTT",
                                                       heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                       date: craDetails.date,
                                                  })
                                             }
                                        >
                                             {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                        </td>
                                   ) : (
                                        <td
                                             key={key}
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                        >
                                             {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                        </td>
                                   )
                              })}
                         </tr>
                    )}

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Absence autorisée
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_AUTORISEE && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_AUTORISEE,
                                                  absence_type_label: "Absence autorisée",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Fériés
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_FERIES && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_FERIES,
                                                  absence_type_label: "Férié",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Maladie
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_CMA && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_CMA,
                                                  absence_type_label: "Maladie",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Congés exceptionnels
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_CEX && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_CEX,
                                                  absence_type_label: "Congés exceptionnels",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Repos compensateur
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR,
                                                  absence_type_label: "Repos compensateur",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Stage de formation
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_SFP && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_SFP,
                                                  absence_type_label: "Stage de formation",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Congés sans solde
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item => item.absence == CONST_CRA_DETAILS_ABSCENCE_CSS && item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_CSS,
                                                  absence_type_label: "Congés sans solde",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>

                    <tr>
                         <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                              Non travaillé (forfait)
                         </td>
                         {data.consultantCraDetails.map((craDetails, key) => {
                              const item = joursAvecAbsence.find(
                                   item =>
                                        item.absence == CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT &&
                                        item.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY")
                              )
                              return editionModeEnabled ? (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                        onClick={() =>
                                             setModifyHeuresAbsenceCellInModal({
                                                  cra_id: data.id,
                                                  absence_type: CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT,
                                                  absence_type_label: "Non travaillé (forfait)",
                                                  heuresParDefaut: item && item.heuresAbsence > 0 ? item.heuresAbsence : 0,
                                                  date: craDetails.date,
                                             })
                                        }
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              ) : (
                                   <td
                                        key={key}
                                        className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                             moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                        }`}
                                   >
                                        {item && item.heuresAbsence > 0 ? item.heuresAbsence : ""}
                                   </td>
                              )
                         })}
                    </tr>
               </>
          )
     }

     // Modal pour ajouter une ligne de lission
     const schemaAddMissionRow = Yup.object().shape({ mission_id: Yup.number().required("Ce champ est requis") })
     const ModalAddMissionRow = useMemo(() => {
          return (
               <MyModal title={<span>Ajout d'une ligne d'activité</span>} show={modalAddMissionRowIsShown} handleClose={() => setModalAddMissionRowIsShown(false)}>
                    <>
                         <Formik
                              initialValues={{ mission_id: null }}
                              onSubmit={(values, { setStatus, setSubmitting }) => {
                                   setStatus(null)
                                   if (values.mission_id != null && data.id != null) {
                                        craAddLigneMission(data.id, values.mission_id)
                                             .then(r => {
                                                  toast.success("Une nouvelle ligne a été ajoutée.")
                                                  setSubmitting(false)
                                                  setData(r.data)
                                                  setModalAddMissionRowIsShown(false)
                                             })
                                             .catch(e => {
                                                  const error = e.response?.data?.detail
                                                       ? e.response.data.detail
                                                       : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                                  setStatus(error)
                                                  toast.error(error, { autoClose: false })
                                                  setSubmitting(false)
                                             })
                                   } else {
                                        setStatus(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                                        toast.error(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                                        setSubmitting(false)
                                   }
                              }}
                              validationSchema={schemaAddMissionRow}
                         >
                              {({ values, setFieldValue, isSubmitting, status }) => {
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {status}
                                                  </MyAlert>
                                             )}

                                             <div>
                                                  <MySelectGetAllDataFromServer
                                                       url={`${API_GET_REFERENCES_MISSION_CONSULTANT_FOR_GIVEN_MONTH}?mois=${data.declaration.mois.format(
                                                            "MM"
                                                       )}&annee=${data.declaration.mois.format("YYYY")}`}
                                                       name={"mission_id"}
                                                       value={values.mission_id}
                                                       isClearable={false}
                                                       isSearchable={false}
                                                       placeholder={"Choisissez votre type d'activité"}
                                                       onChange={val => setFieldValue("mission_id", val)}
                                                  />

                                                  <ErrorMessage name={`mission_id`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                             </div>

                                             <div className="text-center pt-4 d-flex flex-row-reverse">
                                                  <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isSubmitting}>
                                                       {!isSubmitting && <>Confirmer</>}
                                                       {isSubmitting && (
                                                            <span className="indicator-progress" style={{ display: "block" }}>
                                                                 Edition en cours ...
                                                                 <span className="spinner-border spinner-border-sm align-middle ms-1"></span>
                                                            </span>
                                                       )}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </>
               </MyModal>
          )
     }, [modalAddMissionRowIsShown])

     // Fill la ligne mission avec des heures
     const schemaFillAllCasesOfMissionRow = Yup.object().shape({ heures: Yup.number().required("Ce champ est requis").max(24, "Trop d'heures.").typeError("Format incorrect.") })
     const ModalFillAllCasesOfMissionRow = useMemo(() => {
          return (
               <MyModal
                    title={<span>Saisie automatique "{modalFillAllRowRefMissionWithHeures.mission_ref}"</span>}
                    show={modalFillAllRowRefMissionWithHeures.isShown}
                    handleClose={() => setModalFillAllRowRefMissionWithHeures({ isShown: false, mission_id: null, mission_ref: null })}
               >
                    <Formik
                         initialValues={{
                              heures:
                                   modalFillAllRowRefMissionWithHeures.mission_ref == "INTERCONTRAT"
                                        ? "7"
                                        : sumTwoNumbers(
                                               currentUser?.consultant?.consultant?.heuresTravailSupp || 0,
                                               currentUser?.consultant?.consultant?.heuresTravail || 0
                                          ).toString(),
                         }}
                         onSubmit={(values, { setStatus, setSubmitting }) => {
                              setStatus(null)
                              const heures = parseFloat(values.heures)
                              craSetHeuresMissionForTheEntireMonth(data.id, modalFillAllRowRefMissionWithHeures.mission_id as number, heures)
                                   .then(r => {
                                        setData(r.data)
                                        setModalFillAllRowRefMissionWithHeures({ mission_id: null, isShown: false, mission_ref: null })
                                   })
                                   .catch(e => {
                                        const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                        toast.error(error, { autoClose: false })
                                        setSubmitting(false)
                                   })
                         }}
                         validationSchema={schemaFillAllCasesOfMissionRow}
                    >
                         {({ values, setFieldValue, isSubmitting, status }) => {
                              return (
                                   <Form noValidate autoComplete={"off"}>
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}

                                        <div>
                                             <label className={"form-label"}>Heures</label>
                                             <input
                                                  type="text"
                                                  autoComplete={"off"}
                                                  className={"form-control input-sm"}
                                                  name={"heures"}
                                                  value={values.heures}
                                                  inputMode={"numeric"}
                                                  onChange={e => setFieldValue("heures", e.target.value.replace(",", "."))}
                                                  ref={input => input && input.focus({ preventScroll: true })}
                                                  onFocus={e => e.target.select()}
                                             />
                                             <ErrorMessage name={`heures`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                        </div>

                                        <div className="text-center pt-4 d-flex flex-row-reverse">
                                             <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isSubmitting}>
                                                  {!isSubmitting && <>Confirmer</>}
                                                  {isSubmitting && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            Edition en cours ...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-1"></span>
                                                       </span>
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyModal>
          )
     }, [modalFillAllRowRefMissionWithHeures.isShown])

     const schemaModifyCellHeures = Yup.object().shape({ heures: Yup.number().required("Ce champ est requis").max(24, "Trop d'heures.").typeError("Format incorrect.") })
     const ModifyHeuresTravailCellInModalMemo = useMemo(() => {
          if (!modifyHeuresTravailCellInModal) return <></>
          return (
               <MyModal noHeader={true} show={true} handleClose={() => setModifyHeuresTravailCellInModal(undefined)} size={"sm"}>
                    <Formik
                         initialValues={{
                              heures:
                                   modifyHeuresTravailCellInModal.heuresParDefaut == 0
                                        ? modifyHeuresTravailCellInModal.mission_ref == "INTERCONTRAT"
                                             ? "7"
                                             : sumTwoNumbers(
                                                    currentUser!.consultant?.consultant?.heuresTravailSupp || 0,
                                                    currentUser!.consultant?.consultant?.heuresTravail || 0
                                               ).toString()
                                        : modifyHeuresTravailCellInModal.heuresParDefaut.toString(),
                         }}
                         onSubmit={(values, { setStatus, setSubmitting }) => {
                              setStatus(null)
                              const heures = parseFloat(values.heures)
                              const jourAvecHeuresAbsence = joursAvecAbsence.find(
                                   item => item.jour.format("DDMMYYYY") == modifyHeuresTravailCellInModal.date.format("DDMMYYYY") && item.heuresAbsence > 0
                              )

                              if (jourAvecHeuresAbsence && heures > 0) {
                                   MySwal.fire({
                                        icon: "warning",
                                        text: `Vous avez déjà saisi des heures d'absence pour la date du ${modifyHeuresTravailCellInModal.date.format(
                                             "DD/MM/YYYY"
                                        )}. Voulez-vous continuer?`,
                                        showCancelButton: true,
                                        confirmButtonText: "Oui",
                                        cancelButtonText: "Annuler",
                                        showLoaderOnConfirm: true,
                                        preConfirm: async () => {
                                             return craSetHeuresDayMission(
                                                  modifyHeuresTravailCellInModal.cra_id,
                                                  modifyHeuresTravailCellInModal.mission_id,
                                                  modifyHeuresTravailCellInModal.date,
                                                  heures
                                             )
                                                  .then(r => {
                                                       setData(r.data)
                                                       setSubmitting(false)
                                                  })
                                                  .catch(e => {
                                                       const error = e.response?.data?.detail
                                                            ? e.response.data.detail
                                                            : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                                       toast.error(error, { autoClose: false })
                                                       setSubmitting(false)
                                                  })
                                        },
                                        allowOutsideClick: () => !Swal.isLoading(),
                                   }).then()
                                   setModifyHeuresTravailCellInModal(undefined)
                              } else {
                                   craSetHeuresDayMission(
                                        modifyHeuresTravailCellInModal.cra_id,
                                        modifyHeuresTravailCellInModal.mission_id,
                                        modifyHeuresTravailCellInModal.date,
                                        heures
                                   )
                                        .then(r => {
                                             setData(r.data)
                                             setSubmitting(false)
                                             setModifyHeuresTravailCellInModal(undefined)
                                        })
                                        .catch(e => {
                                             const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                             toast.error(error, { autoClose: false })
                                             setSubmitting(false)
                                        })
                              }
                         }}
                         validationSchema={schemaModifyCellHeures}
                    >
                         {({ values, setFieldValue, isSubmitting, status, errors, isValid }) => {
                              return (
                                   <Form noValidate autoComplete={"off"} className={"text-center"}>
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                             <div className={"fs-6"}>
                                                  {FirstLetterUppercase(modifyHeuresTravailCellInModal.date.format("dddd D MMMM"))} | {modifyHeuresTravailCellInModal.mission_ref}
                                             </div>
                                             <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => setModifyHeuresTravailCellInModal(undefined)}>
                                                  <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-2x" />
                                             </div>
                                        </div>
                                        <div className="separator separator-dashed mt-2 mb-4" />

                                        <div className="row">
                                             <label className="col-6 fw-bold text-muted d-flex align-items-center">
                                                  Heures jour <i className={"fas fa-sun fs-3 text-warning ms-2"} />
                                             </label>
                                             <div className="col-6">
                                                  <input
                                                       type="text"
                                                       autoComplete={"off"}
                                                       className={"form-control input-sm"}
                                                       name={"heures"}
                                                       value={values.heures}
                                                       onChange={e => setFieldValue("heures", e.target.value.replace(",", "."))}
                                                       inputMode={"numeric"}
                                                       /*ref={input => input && input.focus({preventScroll: true})} */ onFocus={e => e.target.select()}
                                                  />
                                                  {errors.heures && (
                                                       <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{errors.heures}</div>
                                                       </div>
                                                  )}
                                             </div>
                                        </div>

                                        <div className="d-flex justify-content-center mt-4">
                                             <button className={"btn btn-success btn-sm me-2 p-0 px-3 py-2 d-flex aling-items-center"} disabled={isSubmitting} type={"submit"}>
                                                  {!isSubmitting && (
                                                       <>
                                                            Valider <span className={"fas fa-check-circle fs-3 ms-1"} />
                                                       </>
                                                  )}
                                                  {isSubmitting && isValid && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                                       </span>
                                                  )}
                                             </button>
                                             <button
                                                  className={"btn btn-danger btn-sm p-0 px-3 py-2 d-flex aling-items-center"}
                                                  type={"submit"}
                                                  disabled={isSubmitting}
                                                  onClick={() => {
                                                       setFieldValue("heures", "0")
                                                  }}
                                             >
                                                  {!isSubmitting && <span className={"fas fa-trash fs-3"} />}
                                                  {isSubmitting && isValid && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                                       </span>
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyModal>
          )
     }, [modifyHeuresTravailCellInModal])

     const ModifyHeuresAbsenceCellInModalMemo = useMemo(() => {
          if (!modifyHeuresAbsenceCellInModal) return <></>
          return (
               <MyModal noHeader={true} show={true} handleClose={() => setModifyHeuresAbsenceCellInModal(undefined)} size={"sm"}>
                    <Formik
                         initialValues={{
                              heures: modifyHeuresAbsenceCellInModal.heuresParDefaut == 0 ? "7" : modifyHeuresAbsenceCellInModal.heuresParDefaut.toString(),
                         }}
                         onSubmit={(values, { setStatus, setSubmitting }) => {
                              const heures = parseFloat(values.heures)
                              const heuresTravailPresentDansJour = heuresParRefMissionParJour.find(item =>
                                   item.items.find(item_ => item_.jour.format("DDMMYYYY") == modifyHeuresAbsenceCellInModal.date.format("DDMMYYYY") && item_.heures > 0)
                              )

                              if (heuresTravailPresentDansJour && heures > 0) {
                                   MySwal.fire({
                                        icon: "warning",
                                        text: `Vous avez déjà saisi des heures de travail pour la date du ${modifyHeuresAbsenceCellInModal.date.format(
                                             "DD/MM/YYYY"
                                        )}. Voulez-vous continuer?`,
                                        showCancelButton: true,
                                        confirmButtonText: "Oui",
                                        cancelButtonText: "Annuler",
                                        showLoaderOnConfirm: true,
                                        preConfirm: async () => {
                                             return craSetHeuresDayAbsence(
                                                  modifyHeuresAbsenceCellInModal.cra_id,
                                                  modifyHeuresAbsenceCellInModal.absence_type,
                                                  modifyHeuresAbsenceCellInModal.date,
                                                  heures
                                             )
                                                  .then(r => {
                                                       setData(r.data)
                                                       setSubmitting(false)
                                                  })
                                                  .catch(e => {
                                                       const error = e.response?.data?.detail
                                                            ? e.response.data.detail
                                                            : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                                       toast.error(error, { autoClose: false })
                                                       setSubmitting(false)
                                                  })
                                        },
                                        allowOutsideClick: () => !Swal.isLoading(),
                                   }).then()
                                   setModifyHeuresAbsenceCellInModal(undefined)
                              } else {
                                   craSetHeuresDayAbsence(
                                        modifyHeuresAbsenceCellInModal.cra_id,
                                        modifyHeuresAbsenceCellInModal.absence_type,
                                        modifyHeuresAbsenceCellInModal.date,
                                        heures
                                   )
                                        .then(r => {
                                             setData(r.data)
                                             setSubmitting(false)
                                             setModifyHeuresAbsenceCellInModal(undefined)
                                        })
                                        .catch(e => {
                                             const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                                             toast.error(error, { autoClose: false })
                                             setSubmitting(false)
                                        })
                              }
                         }}
                         validationSchema={schemaModifyCellHeures}
                    >
                         {({ values, setFieldValue, isSubmitting, status, errors, isValid }) => {
                              return (
                                   <Form noValidate autoComplete={"off"} className={"text-center"}>
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}
                                        <div className="d-flex align-items-center justify-content-between">
                                             <div className={"fs-6"}>
                                                  {FirstLetterUppercase(modifyHeuresAbsenceCellInModal.date.format("dddd D MMMM"))} |{" "}
                                                  {modifyHeuresAbsenceCellInModal?.absence_type_label}
                                             </div>
                                             <div className="btn btn-icon btn-sm btn-active-light-primary ms-2" onClick={() => setModifyHeuresAbsenceCellInModal(undefined)}>
                                                  <KTSVG path="/media/icons/duotune/arrows/arr061.svg" className="svg-icon-2x" />
                                             </div>
                                        </div>
                                        <div className="separator separator-dashed mt-2 mb-4" />

                                        <div className="row">
                                             <label className="col-6 fw-bold text-muted d-flex align-items-center">Heures absence</label>
                                             <div className="col-6">
                                                  <input
                                                       type="text"
                                                       autoComplete={"off"}
                                                       className={"form-control input-sm"}
                                                       name={"heures"}
                                                       value={values.heures}
                                                       onChange={e => setFieldValue("heures", e.target.value.replace(",", "."))}
                                                       inputMode={"numeric"}
                                                       /*ref={input => input && input.focus({preventScroll: true})} */ onFocus={e => e.target.select()}
                                                  />
                                                  {errors.heures && (
                                                       <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{errors.heures}</div>
                                                       </div>
                                                  )}
                                             </div>
                                        </div>

                                        <div className="d-flex justify-content-center mt-4">
                                             <button className={"btn btn-success btn-sm me-2 p-0 px-3 py-2 d-flex aling-items-center"} disabled={isSubmitting} type={"submit"}>
                                                  {!isSubmitting && (
                                                       <>
                                                            Valider <span className={"fas fa-check-circle fs-3 ms-1"} />
                                                       </>
                                                  )}
                                                  {isSubmitting && isValid && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                                       </span>
                                                  )}
                                             </button>
                                             <button
                                                  className={"btn btn-danger btn-sm p-0 px-3 py-2 d-flex aling-items-center"}
                                                  type={"submit"}
                                                  disabled={isSubmitting}
                                                  onClick={() => {
                                                       setFieldValue("heures", "0")
                                                  }}
                                             >
                                                  {!isSubmitting && <span className={"fas fa-trash fs-3"} />}
                                                  {isSubmitting && isValid && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            <span className="spinner-border spinner-border-sm align-middle"></span>
                                                       </span>
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyModal>
          )
     }, [modifyHeuresAbsenceCellInModal])

     // Une row avec les jours du mois
     const TableHeadRow = () => {
          return (
               <tr className={"px-2"}>
                    <th className="col fw-bold sticky-column"></th>
                    {data.consultantCraDetails.map((item, key) => {
                         return (
                              <th key={item.id} className={`text-center text-primary fw-bold ${key == data.consultantCraDetails.length - 1 && "pe-4"}`}>
                                   {item.date.format("DD")}
                              </th>
                         )
                    })}
               </tr>
          )
     }

     // Heures de travail pour chaque ligne de mission
     // Popover onClick cell

     const TableBodyHeuresTravailParRefMissionRows = () => {
          return (
               <>
                    <tr>
                         <td scope="row" className={"border-end sticky-column"}>
                              <span
                                   className={"d-flex align-items-center text-primary fw-bold ps-sm-2"}
                                   onClick={() => {
                                        editionModeEnabled && setModalAddMissionRowIsShown(true)
                                   }}
                              >
                                   ACTIVITÉS
                                   {editionModeEnabled && (
                                        <MyTooltip title={"Ajouter une ligne d'activité"}>
                                             <i className={"fas fa-plus-circle btn btn-color-success ps-2 fs-2 p-0"}></i>
                                        </MyTooltip>
                                   )}
                              </span>
                         </td>
                    </tr>

                    {heuresParRefMissionParJour.map((item, key) => (
                         <tr key={key}>
                              <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                                   {editionModeEnabled && (
                                        <>
                                             <MyTooltip title={"Supprimer cette ligne"}>
                                                  <i
                                                       className={"fas fa-minus-circle btn btn-color-danger fs-2 me-sm-2 me-4 p-0"}
                                                       onClick={() => handleRemoveMissionRow(item.idMission, item.refMission)}
                                                  ></i>
                                             </MyTooltip>
                                             <MyTooltip title={"Saisie automatique"}>
                                                  <i
                                                       className={"fas fa-wand-magic-sparkles btn btn-color-dark fs-2 me-sm-2 me-4 p-0"}
                                                       onClick={() =>
                                                            setModalFillAllRowRefMissionWithHeures({ isShown: true, mission_id: item.idMission, mission_ref: item.refMission })
                                                       }
                                                  ></i>
                                             </MyTooltip>
                                        </>
                                   )}
                                   {item.refMission}
                              </td>
                              {data.consultantCraDetails.map((craDetails, key_) => {
                                   // Chaque réf de mission doit avoir toute la ligne d'initialisé (chaque jour doit avoir une heure)
                                   const rowReferenceMission = heuresParRefMissionParJour.find(item_ => item_.refMission == item.refMission)!.items
                                   const cellHeures = rowReferenceMission.find(item__ => item__.jour.format("DD/MM/YYYY") == craDetails.date.format("DD/MM/YYYY"))
                                   return editionModeEnabled ? (
                                        <td
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             onClick={() =>
                                                  setModifyHeuresTravailCellInModal({
                                                       cra_id: data.id,
                                                       mission_id: item.idMission,
                                                       mission_ref: item.refMission,
                                                       heuresParDefaut: cellHeures!.heures,
                                                       date: craDetails.date,
                                                  })
                                             }
                                             key={key_}
                                        >
                                             <span>{cellHeures!.heures > 0 ? cellHeures!.heures : ""} </span>
                                        </td>
                                   ) : (
                                        <td
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date.weekday()) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") == craDetails.date.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             key={key_}
                                        >
                                             <span>{cellHeures!.heures > 0 ? cellHeures!.heures : ""} </span>
                                        </td>
                                   )
                              })}
                         </tr>
                    ))}
               </>
          )
     }

     return (
          <div className="table-responsive text-nowrap" style={{ overflowY: "hidden" }}>
               <table id={"cra-table"} className={`table m-0 ${editionModeEnabled && "cra-table-edition-mode"}`}>
                    {/* Begin::thead */}
                    <thead className="thead-dark border-bottom border-top">
                         <TableHeadRow />
                    </thead>
                    {/* End::thead */}

                    {/* Begin::tbody */}
                    <tbody className={"border-top border-bottom"}>
                         {/* Begin::Heures de travail */}
                         <TableBodyHeuresTravailParRefMissionRows />
                         {/* End::Heures de travail */}

                         {/* Begin::Absences */}
                         <TableBodyHeuresAbsenceParTypeDeAbsenceRows />

                         {/* End::Absences */}
                    </tbody>
                    {/* End::tbody */}
               </table>

               {modalAddMissionRowIsShown && ModalAddMissionRow}
               {modalFillAllRowRefMissionWithHeures.isShown && ModalFillAllCasesOfMissionRow}
               {modifyHeuresTravailCellInModal && ModifyHeuresTravailCellInModalMemo}
               {modifyHeuresAbsenceCellInModal && ModifyHeuresAbsenceCellInModalMemo}
          </div>
     )
}

type CraUpdateGrilleTempsWrapperProps = {
     data: ConsultantCraModel
     setData: (v: ConsultantCraModel) => void
     setStepperStep: Dispatch<SetStateAction<"one" | "two" | "three">>
     editionMode?: boolean
}

export const CraUpdateGrilleTempsWrapper: React.FC<CraUpdateGrilleTempsWrapperProps> = ({ data, setData, setStepperStep, editionMode = true }) => {
     const navigate = useNavigate()

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1500px" }}>
               <div className={"mb-5"}>
                    <CraUpdateGrilleTemps data={data} setData={setData} editionModeEnabled={editionMode} />
               </div>

               {/* FOOTER */}
               <div className="d-none d-sm-flex justify-content-between">
                    <div>
                         <Link to="/activites/cra " className={`btn btn-sm btn-light-primary me-2`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des CRA
                         </Link>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => navigate(`/activites/cra/new`)}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Période
                         </button>
                    </div>

                    <button type="button" className="btn btn-sm btn-primary" onClick={() => setStepperStep("three")}>
                         Tickets restaurant <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                    </button>
               </div>

               <div className="d-flex d-sm-none flex-column">
                    <div className={"mb-3"}>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => navigate(`/activites/cra/new`)}>
                              <span className={"fas fa-arrow-left fs-3 me-1 align-middle"} /> Période
                         </button>
                         <button type="button" className="btn btn-sm btn-primary float-end" onClick={() => setStepperStep("three")}>
                              Tickets restaurant <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"} />
                         </button>
                    </div>

                    <Link to="/activites/cra " className={`btn btn-sm btn-light-primary`}>
                         <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des CRA
                    </Link>
               </div>
          </div>
     )
}

type heuresParRefMissionParJourType = { idMission: number; refMission: string; items: { heures: number; jour: moment.Moment }[] }
type CraUpdateGrilleTempsProps = {
     data: ConsultantCraModel
     setData: (v: ConsultantCraModel) => void
     editionModeEnabled?: boolean
}
