import React, { CSSProperties } from "react"
import CreatableSelect from "react-select/creatable"
import { StylesConfig } from "react-select"
import { useThemeMode } from "../../../_metronic/partials"

type Props = {
     name: string
     options: Array<{ label: string; value: string | boolean } | any>
     value: any
     onChange: (value: any) => void
     isInvalid: boolean
     isClearable?: boolean
     placeholder?: string
     classNames?: string
     isValidNewOption?: (val: string) => boolean
}

const MySelectCreatableField: React.FC<Props> = ({ options, name, value, onChange, isClearable = true, placeholder = "", classNames = "", isInvalid, isValidNewOption }) => {
     const { formatGroupLabel, styles } = useSelectReact()

     return (
          <CreatableSelect
               menuPortalTarget={document.body}
               className={classNames}
               options={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
               name={name}
               formatGroupLabel={formatGroupLabel}
               value={value ? value.map(item => ({ value: item, label: item, __isNew__: true })) : []}
               onChange={values => {
                    onChange(values.map((item: any) => item.value))
               }}
               noOptionsMessage={() => placeholder}
               isValidNewOption={isValidNewOption}
               formatCreateLabel={inputValue => `Ajouter "${inputValue}"`}
               isMulti={true}
               isClearable={isClearable}
               placeholder={placeholder}
               styles={styles(isInvalid)}
          />
     )
}

interface ISelectReactHookReturn {
     styles: (isInvalid: boolean) => StylesConfig
     formatGroupLabel: (data: any) => JSX.Element
}

const useSelectReact = (): ISelectReactHookReturn => {
     const { mode } = useThemeMode()

     const styles = (isInvalid: boolean): StylesConfig => ({
          control: (baseStyles, state) => ({
               ...baseStyles,
               border: `${
                    mode == "light"
                         ? `1px solid ${isInvalid ? "var(--kt-danger)" : "var(--kt-gray-600)"}`
                         : `1px solid ${isInvalid ? "var(--kt-danger)" : "var(--kt-input-border-color)"}`
               }`,
               boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               borderRadius: "0.625rem!important",
               padding: "2px 0px",
               backgroundColor: mode == "light" ? "white" : "var(--kt-input-solid-bg)",
               color: "var(--kt-input-solid-bg)",
               ":hover": {
                    borderColor: "var(--kt-input-border-color)",
                    boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               },
               ":active": {
                    borderColor: "var(--kt-input-border-color)",
                    boxShadow: "0 0 0 1px var(--kt-input-border-color)",
               },
          }),
          placeholder: (baseStyles, state) => ({
               ...baseStyles,
               fontSize: "1.1rem",
               fontWeight: 500,
               color: "var(--kt-input-placeholder-color)",
          }),
          indicatorSeparator: (baseStyles, state) => ({
               ...baseStyles,
               backgroundColor: "var(--kt-input-placeholder-color)",
          }),
          dropdownIndicator: (baseStyles, state) => ({
               ...baseStyles,
               color: "var(--kt-input-placeholder-color)",
          }),
          clearIndicator: (baseStyles, state) => ({
               ...baseStyles,
               color: "var(--kt-input-placeholder-color)",
               ":hover": {
                    color: "var(--kt-danger)",
                    cursor: "pointer",
               },
          }),
          input: (baseStyles, state) => ({
               ...baseStyles,
               fontSize: "1.15rem",
               fontWeight: 500,
               color: "var(--kt-input-color)",
          }),
          option: (baseStyles, state) => ({
               ...baseStyles,
               color: state.isSelected ? "white" : "var(--kt-input-color)",
               ":hover": {
                    backgroundColor: "var(--kt-primary)",
                    color: "white",
               },
               ":active": {
                    backgroundColor: "var(--kt-primary)",
                    color: "white",
               },
          }),
          menu: (baseStyles, state) => ({
               ...baseStyles,
               backgroundColor: "var(--kt-input-bg)",
          }),
          singleValue: (baseStyles, state) => ({
               ...baseStyles,
               marginLeft: "5px!important",
               fontSize: "1.15rem",
               fontWeight: "500",
               color: "var(--kt-input-color)",
          }),
          multiValueRemove: baseStyles => ({
               ...baseStyles,
               color: "var(--kt-input-color)",
          }),
          menuPortal: base => ({ ...base, zIndex: 9999 }),
     })

     /* Used for custom group label */
     const formatGroupLabel = data => {
          const groupStyles: CSSProperties = {
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
          }
          const groupBadgeStyles: CSSProperties = {
               backgroundColor: "#EBECF0",
               borderRadius: "2em",
               color: "#172B4D",
               display: "inline-block",
               fontSize: 12,
               fontWeight: "normal",
               lineHeight: "1",
               minWidth: 1,
               padding: "0.16666666666667em 0.5em",
               textAlign: "center",
          }
          return (
               <div>
                    <div style={groupStyles}>
                         <span>{data.label}</span>
                         <span style={groupBadgeStyles}>{data.options.length}</span>
                    </div>
               </div>
          )
     }

     return {
          styles,
          formatGroupLabel,
     }
}

export default MySelectCreatableField
