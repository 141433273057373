import React from "react"

type Props = {
     title?: React.ReactNode | string
     children: React.ReactNode | string
     type: "warning" | "danger" | "info" | "primary" | "success"
     classNames?: string
     borderDashed?: boolean
     customIcon?: string
}

const MyAlert: React.FC<Props> = ({ title, children, type, classNames, borderDashed, customIcon }) => {
     const faIcon = (() => {
          if (type == "success") return "fas fa-check-circle"
          if (type == "primary") return "fas fa-info-circle"
          if (type == "warning") return "fas fa-exclamation-triangle"
          if (type == "danger") return "fas fa-exclamation-triangle"

          return "fas fa-exclamation-triangle"
     })()

     return (
          <div className={`notice bg-light-${type} rounded border-${type} ${borderDashed && "border border-dashed "} p-4 ${classNames} d-flex align-items-center`}>
               <span className={`svg-icon svg-icon-${type} me-2`}>
                    <i className={`${customIcon ? customIcon : faIcon} text-${type}`} style={{ fontSize: "2.5rem" }} />
               </span>
               <div className={`d-flex flex-stack flex-grow-1 border-start border-secondary ps-3`}>
                    <div className="fw-bold">
                         {title && <h4 className="text-gray-900 fw-bolder">{title}</h4>}

                         <div className="fs-6 text-gray-700">{children}</div>
                    </div>
               </div>
          </div>
     )
}

export default MyAlert
