/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Outlet} from 'react-router-dom'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'

const AuthLayout = () => {
    return (
        <div className='d-flex flex-column align-items-center justify-content-center'
             style={{backgroundSize: 'cover', backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.jpg')})`, height: '100vh', color: 'black'}}>

            {/*<div className='w-75 w-sm-75 w-md-50 w-lg-50 w-xxl-25 px-5 py-5 mb-10'*/}
            <div className='w-85 w-sm-75 w-md-50 w-xxl-30 px-5 py-5 mb-15'
                 style={{opacity: '0.95', backgroundColor: 'white', borderRadius: '20px', boxShadow: '0px 0px 10px -2px rgba(0, 0, 0, 0.42)', overflow: 'scroll', maxHeight: '95%'}}>
                <div className={"mb-10 text-center"}>
                    <KTSVG path={'/media/misc/talent.svg'} svgClassName={'w-75 h-100'}/>
                </div>
                <Outlet/>
                <div className='d-flex flex-column fw-bold text-primary flex-center mt-10'>
                    <a href='#' className={"mb-5"} target='_blank'>
                        Politique de confidentialité
                    </a>
                    <img style={{height: '40px', width: '55px'}} src={toAbsoluteUrl('/media/logos/logos-cts-1.png')}/>
                </div>
            </div>
        </div>
    )
}

export {AuthLayout}
