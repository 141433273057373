import { Link, useNavigate } from "react-router-dom"
import React from "react"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import { useConsultantDeclarationActions } from "../../core/_hooks"
import { ConsultantDeclarationMensuelleSuiviModel } from "../../../../../../../models"
import {
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "../../../../../../../constants"
import swal from "sweetalert2"
import MySimpleSpinner from "../../../../../../../utils/MySimpleSpinner"

const Footer = () => {
     const context = useDeclarationUpdate()
     const navigate = useNavigate()

     const { mutations, actions } = useConsultantDeclarationActions()

     function handleEnvoyerCodeSignature() {
          swal.fire({
               icon: "warning",
               title: "Merci de saisir le code que vous avez reçu par email puis signez votre déclaration.",
               showCancelButton: true,
               confirmButtonText: "Signer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               html: `
                       <div style="display: flex; justify-content: center; gap: 10px;">
                           <input class="form-control" autofocus id="swal-input1" maxlength="1" style="width: 42px; height: 42px; text-align: center; font-size: 22px;" />
                           <input class="form-control" id="swal-input2" maxlength="1" style="width: 42px; height: 42px; text-align: center; font-size: 22px;" />
                           <input class="form-control" id="swal-input3" maxlength="1" style="width: 42px; height: 42px; text-align: center; font-size: 22px;" />
                           <input class="form-control" id="swal-input4" maxlength="1" style="width: 42px; height: 42px; text-align: center; font-size: 22px;" />
                       </div>
               `,
               didOpen: () => {
                    const inputs = [
                         document.getElementById("swal-input1") as HTMLInputElement,
                         document.getElementById("swal-input2") as HTMLInputElement,
                         document.getElementById("swal-input3") as HTMLInputElement,
                         document.getElementById("swal-input4") as HTMLInputElement,
                    ]

                    // Set focus on the first input
                    const firstInput = swal.getHtmlContainer()?.querySelector("#swal-input1") as HTMLInputElement
                    firstInput.focus()

                    inputs.forEach((input, index) => {
                         input.addEventListener("input", () => {
                              if (input.value.length === 1 && index < inputs.length - 1) {
                                   inputs[index + 1].focus()
                              } else {
                                   const allFilled = inputs.every(input => input.value.length === 1)
                                   if (allFilled) {
                                        // Trigger form submission
                                        swal.clickConfirm()
                                   }
                              }
                         })

                         input.addEventListener("keydown", event => {
                              if (event.key === "Backspace" && input.value.length === 0 && index > 0) {
                                   inputs[index - 1].focus()
                              }
                         })

                         input.addEventListener("paste", event => {
                              const paste = event.clipboardData?.getData("text")
                              if (paste) {
                                   if (/^\d{4}$/.test(paste)) {
                                        inputs.forEach((input, i) => {
                                             input.value = paste[i] || ""
                                        })
                                        event.preventDefault()
                                        // Unfocus all inputs
                                        inputs.forEach(input => input.blur())
                                        // Trigger form submission
                                        swal.clickConfirm()
                                   }
                              }
                         })
                    })
               },
               preConfirm: async () => {
                    const input1 = (document.getElementById("swal-input1") as HTMLInputElement).value
                    const input2 = (document.getElementById("swal-input2") as HTMLInputElement).value
                    const input3 = (document.getElementById("swal-input3") as HTMLInputElement).value
                    const input4 = (document.getElementById("swal-input4") as HTMLInputElement).value
                    const code = input1 + input2 + input3 + input4

                    if (!/^\d{4}$/.test(code)) {
                         swal.showValidationMessage("Veuillez saisir un code valide de 4 chiffres.")
                         return
                    }

                    return actions.signer(context.data.declaration?.id as number, code, () => navigate("/activites/declarations"))
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function entamerRectificationSansDemandeValidation(val: ConsultantDeclarationMensuelleSuiviModel) {
          context.setData(prev => ({
               ...prev,
               declaration: prev.declaration ? { ...prev.declaration, etat: val, modifiable: true } : null,
          }))
     }

     function demandeRegulCallBack() {
          navigate("/activites/declarations")
     }

     return (
          <>
               <div className="d-flex justify-content-between">
                    {/* BEGIN: LEFT SIDE */}
                    <div className={"d-flex"}>
                         <Link to="/personnel/mes-activites/declarations " className={`btn btn-sm btn-light-primary d-flex me-2`}>
                              <i className={"fas fa-list-dots"} /> <span className={"d-none d-md-flex ms-3"}>Retour aux déclarations</span>
                         </Link>
                         <button type="button" className="btn btn-sm btn-light-primary me-3" onClick={() => context.setDeclarationStepHeader("ticket_resto")}>
                              <i className={"fas fa-arrow-left"} /> TR
                         </button>
                    </div>

                    {/* END: LEFT SIDE */}

                    {/* BEGIN: RIGHT SIDE */}
                    {[
                         undefined,
                         CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                         CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
                    ].includes(context.data.declaration?.etat?.type as string) && (
                         <button
                              type="button"
                              className="btn btn-sm btn-success d-flex align-items-center"
                              onClick={() => actions.envoyerCodeSignature(context.data.declaration?.id as number, handleEnvoyerCodeSignature)}
                              disabled={mutations.envoyerCodeSignature.isLoading || mutations.signer.isLoading}
                         >
                              {mutations.envoyerCodeSignature.isLoading || mutations.signer.isLoading ? (
                                   <MySimpleSpinner size={"sm"} />
                              ) : (
                                   <>
                                        SIGNER <span className="fad fa-signature fs-3 ms-1 align-middle" />
                                   </>
                              )}
                         </button>
                    )}
                    {[CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(context.data.declaration?.etat?.type as string) && (
                         <button
                              type="button"
                              className="btn btn-sm btn-warning"
                              onClick={() => actions.entamerRectificationSansDemandeValidation(context.data.declaration?.id as number, entamerRectificationSansDemandeValidation)}
                              disabled={mutations.entamerRectificationSansDemandeValidation.isLoading}
                         >
                              Apporter une modification <span className="fas fa-edit fs-4 align-middle" />
                         </button>
                    )}

                    <>
                         {[CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(
                              context.data.declaration?.etat?.type as string
                         ) && (
                              <div className={"d-flex"}>
                                   <button
                                        type="button"
                                        className="btn btn-sm btn-danger d-flex me-2"
                                        onClick={() => actions.demandeRegul(context.data.declaration?.id as number, demandeRegulCallBack)}
                                        disabled={mutations.demandeRegul.isLoading}
                                   >
                                        <span className={"d-none d-md-flex"}>Demander une régularisation</span>
                                        <i className="fas fa-hand fs-4 ms-0 ms-md-1" />
                                   </button>
                              </div>
                         )}
                    </>

                    {/* END: RIGHT SIDE */}
               </div>
          </>
     )
}

export default Footer
