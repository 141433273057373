import { toast } from "react-toastify"
import { useMutation } from "react-query"
import useSwal from "../../../../../../hooks/useSwal"
import {
     declarationSuiviConsultantDemandeRegulRequest,
     declarationSuiviConsultantEnvoyerCodeSignature,
     declarationSuiviConsultantSigneDeclaration,
     declarationSuiviConsultantVeutEtPeutApporterModifSansValidationBmOuSiege,
} from "../../../core/_request"
import { ConsultantDeclarationMensuelleSuiviModel, IHttpErrorResponseModel } from "../../../../../../models"
import { AxiosError } from "axios"

export const useConsultantDeclarationActions = () => {
     const { swal } = useSwal()

     const envoyerCodeSignatureMutation = useMutation(({ declaration_ID }: { declaration_ID: number }) => declarationSuiviConsultantEnvoyerCodeSignature(declaration_ID))
     const entamerRectificationSansDemandeValidationMutation = useMutation(({ declaration_ID }: { declaration_ID: number }) =>
          declarationSuiviConsultantVeutEtPeutApporterModifSansValidationBmOuSiege(declaration_ID)
     )
     const signerMutation = useMutation(({ declaration_ID, code, commentaire }: { declaration_ID: number; code: string; commentaire?: string }) =>
          declarationSuiviConsultantSigneDeclaration(declaration_ID, code, commentaire)
     )

     const demandeRegulMutation = useMutation(({ declaration_ID, commentaire }: { declaration_ID: number; commentaire: string }) =>
          declarationSuiviConsultantDemandeRegulRequest(declaration_ID, commentaire)
     )

     function envoyerCodeSignature(declaration_ID: number, callback: () => void = () => {}) {
          swal.fire({
               icon: "info",
               html: "<b>Génération du code en cours</b> ...<br/><br> Veuillez patienter, cela peut prendre quelques instants.",
               showConfirmButton: false,
               allowOutsideClick: false,
               willOpen: () => {
                    swal.showLoading()
               },
          }).then()

          setTimeout(() => {
               envoyerCodeSignatureMutation
                    .mutateAsync({ declaration_ID })
                    .then(() => {
                         swal.close()
                         callback()
                    })
                    .catch((e: AxiosError) => {
                         swal.close()
                         const error: IHttpErrorResponseModel = e.response?.data
                         toast.error(error.detail, { autoClose: false })
                    })
          }, 1500)
     }

     function entamerRectificationSansDemandeValidation(declaration_ID: number, callback: (val: ConsultantDeclarationMensuelleSuiviModel) => void = () => {}) {
          swal.fire({
               icon: "info",
               title: `Voulez-vous entamer la rectification de votre déclaration mensuelle?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return entamerRectificationSansDemandeValidationMutation
                         .mutateAsync({ declaration_ID })
                         .then(r => {
                              toast.info("Vous pouvez entamer la rectification de votre déclaration.")
                              callback(r.data)
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     async function signer(declaration_ID: number, code: string, callback: () => void = () => {}) {
          try {
               await signerMutation.mutateAsync({ declaration_ID, code })
               toast.success("Votre déclaration a bien été signée.")
               callback()
          } catch (e: any) {
               toast.error(e.response.data?.detail, { autoClose: false })
          }
     }

     function demandeRegul(declaration_ID: number, callback: (declaration_ID: number, val: ConsultantDeclarationMensuelleSuiviModel) => void = () => {}) {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point d'envoyer une demande de régularisation`,
               text: "Un mail sera envoyé à votre responsable direct ce qui vous permettra de modifier votre déclaration",
               showCancelButton: true,
               confirmButtonText: "Envoyer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               input: "textarea",
               inputPlaceholder: "Merci de préciser la raison de cette demande",
               inputAttributes: {
                    required: "true",
               },
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci de préciser la raison de cette demande") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return demandeRegulMutation
                         .mutateAsync({ declaration_ID, commentaire })
                         .then(r => {
                              toast.info("Un mail a été envoyé à votre responsable direct")
                              callback(declaration_ID, r.data)
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return {
          mutations: {
               envoyerCodeSignature: envoyerCodeSignatureMutation,
               entamerRectificationSansDemandeValidation: entamerRectificationSansDemandeValidationMutation,
               signer: signerMutation,
               demandeRegul: demandeRegulMutation,
          },
          actions: {
               envoyerCodeSignature,
               entamerRectificationSansDemandeValidation,
               signer,
               demandeRegul,
          },
     }
}
