import axios from "axios"
import { AuthModel, AuthUserModel } from "./_models"
import { API_BASE_URL, API_URL_ESPACE_AVENTURIER } from "../../../constants"

export const LOGIN_URL = `${API_BASE_URL}/email-password-auth`
export const REGISTER_URL = `${API_URL_ESPACE_AVENTURIER}/register`
export const REQUEST_PASSWORD_URL = `${API_URL_ESPACE_AVENTURIER}/forgot-password`

// Server should return AuthModel
export function login(username: string, password: string, code ?: string) {
    return axios.post<AuthModel>(LOGIN_URL, code ? {username, password, code} : {username, password})
}

// Server should return AuthModel
export function register(
    nom: string,
    prenom: string,
    email: string,
    password: string,
    recaptchaToken: string
) {
    return axios.post(REGISTER_URL, {
        nom,
        prenom,
        email,
        password,
        recaptchaToken
    })
}

export function forgotPassword(email: string) {
    return axios.post(REQUEST_PASSWORD_URL, {
        email,
    })
}

export function resetPassword(password: string, token: string) {
    return axios.put<AuthUserModel>(`${API_URL_ESPACE_AVENTURIER}/reset-password`, {
        password,
    }, {
        headers: {
            'Authorization-api': `Bearer ${token}`
        },
    })
}

export function getUserByToken(token: string) {
    return axios.get<AuthUserModel>(`${API_URL_ESPACE_AVENTURIER}/user-info`, {
        headers: {
            'Authorization-api': `Bearer ${token}`
        },
    })
}


export function activate(token: string) {
    return axios.put<AuthUserModel>(`${API_URL_ESPACE_AVENTURIER}/activate-account`, {}, {
        headers: {
            'Authorization-api': `Bearer ${token}`
        },
    })
}

export function getCodePostalOfVille(ville_id: number) {
    return axios.get<{ codePostal: string }>(`${API_URL_ESPACE_AVENTURIER}/utils/get-code-postal-of-ville`, {
        params: {ville_id}
    })
}

