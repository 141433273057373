import { useLocation, useNavigate } from "react-router-dom"
import React, { useEffect, useState } from "react"
import { ndfGetInfo } from "../../../core/_request"
import { ConsultantNdfModel } from "../../../../../../models"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { NdfStepperHeader } from "../NdfCreate/NdfCreate"
import { NdfUpdateDetailsWrapper } from "./NdfUpdateDetailsWrapper/NdfUpdateDetailsWrapper"

const urlParams = new URLSearchParams(window.location.search)

export function NdfUpdate() {
    const navigate = useNavigate()
    const location_state = useLocation().state as { ndf_id: string, default_step: "one" | "two" }

    const ndf_id = location_state?.ndf_id || urlParams.get('ndf_id') as string
    const default_step = location_state?.default_step || urlParams.get('default_step') as string
    const [ndfData, setNdfData] = useState<{ isEditable: boolean, loading: boolean, error: false | string, data: ConsultantNdfModel | null }>({
        isEditable: true,
        loading: true,
        error: false,
        data: null
    });


    useEffect(() => {
        if (!ndf_id) {
            navigate(`/activites/ndf/new`)
        } else {
            ndfGetInfo(parseInt(ndf_id)).then(r => {
                setNdfData(prev => ({...prev, loading: false, isEditable: r.data.declaration.modifiable, data: r.data}))
            }).catch(e => {
                toast.error(e.response?.data?.detail, {autoClose: false})
                setNdfData(prev => ({...prev, loading: false, error: e.response?.data?.detail}))
            })
        }
    }, [])

    const [stepperStep, setStepperStep] = useState<"one" | "two">(default_step || "two");

    return <div className='card mb-5 mb-xl-10'>
        <div className='card-body p-9'>
            {ndfData.loading && <div className={"text-center p-4"}>
                <span className='spinner-border mb-2'></span>
                <p style={{fontWeight: 500}}>Chargement en cours ...</p>
            </div>}

            {!ndfData.loading && ndfData.error && <MyAlert type={"danger"}>{ndfData.error}</MyAlert>}

            {!ndfData.loading && ndfData.data && <>
                <NdfStepperHeader stepperStep={stepperStep} setStepperStep={setStepperStep}/>

                {stepperStep == "two" && <NdfUpdateDetailsWrapper setStepperStep={setStepperStep} ndfId={ndfData.data.id} editionMode={ndfData.isEditable}/>}
            </>
            }

        </div>
    </div>
}
