import React, { useCallback, useState } from "react"
import Select from "react-select"
import axios, { AxiosError, AxiosResponse } from "axios"
import { useIntl } from "react-intl"
import useSelectReact from "../../hooks/useSelectReact"

type Props = {
     url: string
     name: string
     value: string | number | Array<string | number> | undefined | null
     onChange: (value: string | number) => void
     minimumLengthSearch: number
     isClearable?: boolean
     placeholder?: string
     method?: "POST" | "GET" /* TODO: get rid of method after adding common submodule to Talent. Only GET Requests will be sent. */
     payload?: {}
     isMulti?: boolean
     defaultOptions?: Array<{ label: string; value: string | number }>
     className?: string
}

const MySelectSearchDataFromServer: React.FC<Props> = ({
     url,
     name,
     value,
     onChange,
     isClearable = true,
     placeholder = "Tapez puis choisissez",
     payload = {},
     isMulti = false,
     minimumLengthSearch,
     defaultOptions = [],
     method = "POST",
     className = "",
}) => {
     const intl = useIntl()
     const { formatGroupLabel } = useSelectReact()

     const [isLoading, setIsLoading] = useState<boolean>(false)
     // This will be used to display the options obtained from the Response. This options will be replaced for every new Response.
     const [options, setOptions] = useState<any>(defaultOptions)
     // CacheOptions will store "forever" the options obtained from the Response. The outcome of a new Response will append the new options to the cached options.
     const [cacheOptions, setCacheOptions] = useState<any>(defaultOptions) /*  */
     const [noOptionsMessage, setNoOptionsMessage] = useState<string>(`Saisissez au moins ${minimumLengthSearch > 1 ? "caractères" : "caractère"} pour lancer la recherche`)

     function getOptions(q: string) {
          // The search will only be triggered after typing a minimum of minimumLengthSearch characters.
          if (q.length >= minimumLengthSearch) {
               setIsLoading(true)

               let result
               if (method === "GET") {
                    // Convert true to 1 and false to 0 to simplify backend operations.
                    for (const key in payload) {
                         if (payload[key] === true) payload[key] = 1
                         if (payload[key] === false) payload[key] = 0
                    }
                    // "q" is used for simple searches while "payload" is utilized for more complex search operations.
                    result = axios.get<Array<{ label: string; value: string | number }>>(url, { params: { q, ...payload } })
               } else {
                    // TODO: POST Method will be deleted
                    result = axios.post<Array<{ label: string; value: string | number }>>(url, payload)
               }

               result
                    .then((r: AxiosResponse) => {
                         // Set the new options that will be displayed to the user
                         setOptions(r.data)
                         // Append the new options to the cached options
                         setCacheOptions(prev => {
                              // This operation will check whether a new option is not already in the cached options
                              r.data.map(item => {
                                   if ("options" in item) {
                                        item.options.map(item_ => {
                                             if (!prev.some(e => e.value === item_.value)) {
                                                  prev.push(item_)
                                             }
                                        })
                                   } else {
                                        if (!prev.some(e => e.value === item.value)) {
                                             prev.push(item)
                                        }
                                   }
                              })

                              return [...prev]
                         })
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 400) {
                              setNoOptionsMessage(e.response.data.detail)
                         } else {
                              setNoOptionsMessage(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         }
                         setOptions([])
                    })
                    .finally(() => {
                         setIsLoading(false)
                    })
               setNoOptionsMessage("Aucun résultat")
          } else {
               setNoOptionsMessage(`Saisissez au moins ${minimumLengthSearch - q.length} ${q.length > 1 ? "caractères" : "caractère"} pour lancer la recherche`)
          }
     }

     // selectValue is based on "value". It will get all options matching with value
     const selectValue = useCallback(() => {
          if (!value) return []
          if (Array.isArray(value)) {
               if (value.length == 0) return []

               let selectedOptions: any[] = []
               // Returns {label: '', value: ''}[]
               cacheOptions.map(opt => {
                    // if opt has options it means that it's an optgroup
                    if ("options" in opt) {
                         opt.options.map(item => {
                              // @ts-ignore
                              if (value.includes(item.value)) selectedOptions.push(item)
                         })
                    } else {
                         // @ts-ignore
                         if (value.includes(opt.value)) selectedOptions.push(opt)
                    }
               })

               return selectedOptions
          } else {
               return cacheOptions.find(opt => opt.value === value)
          }
     }, [value])

     return (
          <Select
               menuPortalTarget={document.body}
               isClearable={isClearable}
               placeholder={placeholder}
               name={name}
               formatGroupLabel={formatGroupLabel}
               value={selectValue()}
               options={options}
               loadingMessage={() => "Chargement en cours ..."}
               onInputChange={getOptions}
               onChange={(val: any) => {
                    if (Array.isArray(val)) {
                         // @ts-ignore
                         onChange(val.map(i => i.value)) // Form only needs values
                    } else {
                         onChange(val?.value) // Form only needs the value
                    }
               }}
               isLoading={isLoading}
               isMulti={isMulti}
               noOptionsMessage={() => noOptionsMessage}
               className={className}
          />
     )
}

export default MySelectSearchDataFromServer
