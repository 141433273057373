import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { InfoToCompleterAvantDemandeEmbaucheWrapper } from "../pages/infoToCompleterAvantDemandeEmbauche/InfoToCompleterAvantDemandeEmbauche"

const PrivateRoutes = () => {
     const location = useLocation()

     let prevUrl: any
     if (location.pathname.includes("auth") && localStorage.getItem("prevUrl")) {
          prevUrl = localStorage.getItem("prevUrl")
          localStorage.removeItem("prevUrl")
     }

     return (
          <Routes>
               <Route path="auth/*" element={<Navigate to={prevUrl || "/info-a-completer"} />} />

               <Route index element={<InfoToCompleterAvantDemandeEmbaucheWrapper />} />

               <Route path="info-a-completer" element={<InfoToCompleterAvantDemandeEmbaucheWrapper />} />
          </Routes>
     )
}

export {PrivateRoutes}
