import React, { useState } from "react"
import { useFormik } from "formik"
import * as Yup from "yup"
import clsx from "clsx"
import { register } from "../core/_requests"
import { Link } from "react-router-dom"
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers"
import { GOOGLE_RECAPTCHA_CLIENT_KEY } from "../../../constants"
import useGoogleReCaptchaV3 from "../../../hooks/useGoogleRecaptchaV3"
import MyTooltip from "../../../utils/MyTooltip"
import { toast } from "react-toastify"

const initialValues = {
     nom: "",
     prenom: "",
     email: "",
     password: "",
     changepassword: "",
     acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
     nom: Yup.string().min(2, "Minimum 2 caractères").max(50, "Maximum 50 caractères").required("Le nom est requis"),
     prenom: Yup.string().min(2, "Minimum 2 caractères").max(50, "Maximum 50 caractères").required("Le nom est requis"),
     email: Yup.string().email("Mauvais format").min(3, "Minimum 3 caractères").max(50, "Maximum 50 caractères").required("L'email est requis"),
     password: Yup.string()
          .min(6, "Minimum 6 caractères")
          .max(50, "Maximum 50 caractères")
          .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Au mois un caractère spécial")
          .required("Le mot de passe est requis"),
     changepassword: Yup.string()
          .required("La confirmation du mot de passe est requise")
          .when("password", {
               is: (val: string) => (val && val.length > 0 ? true : false),
               then: schema => schema.oneOf([Yup.ref("password")], "Le mot de passe et la confirmation du mot de passe ne correspondent pas"),
          }),
     acceptTerms: Yup.bool().required("Vous devez accpter les termes et conditions."),
})

export function Registration() {
     const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
     const [loading, setLoading] = useState<boolean>(false)
     const formik = useFormik({
          initialValues,
          validationSchema: registrationSchema,
          onSubmit: async (values, { setStatus }) => {
               setLoading(true)

               const token = await executeReCaptcha("LoadReCaptchaOnClick")

               register(values.nom, values.prenom, values.email, values.password, token)
                    .then(() => {
                         setIsSubmitted(true)
                    })
                    .catch(e => {
                         if (!e.response || e.response.status !== 409) {
                              setStatus("Echec lors de la création du compte. Veuillez réessayer.")
                         } else {
                              setStatus(
                                   <>
                                        L'adresse mail <span className={"fw-bold text-info"}>"{values.email}"</span> a déjà été utilisée. Si vous en êtes propriétaire, veuillez
                                        procéder à sa{" "}
                                        <Link to="/auth/forgot-password" state={{ email: values.email }}>
                                             <span className={"fw-bold"}>récupération</span>{" "}
                                        </Link>
                                        sinon veuillez saisir une autre adresse mail.
                                   </>
                              )
                         }
                         setLoading(false)
                    })
          },
     })
     const { executeReCaptcha } = useGoogleReCaptchaV3({ siteKey: GOOGLE_RECAPTCHA_CLIENT_KEY })

     if (!isSubmitted) {
          return (
               <form className="form fv-plugins-bootstrap5 fv-plugins-framework" noValidate id="kt_login_signup_form" onSubmit={formik.handleSubmit}>
                    {formik.status && (
                         <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">{formik.status}</div>
                         </div>
                    )}

                    <MyTooltip title={"Option temporairement désactivée. Veuillez utiliser le formulaire."}>
                         <button
                              type="button"
                              className="btn btn-light-primary fw-bolder w-100 mb-10"
                              onClick={() => toast.info("Cette option est temporairement désactivée. Veuillez utiliser le formulaire.")}
                         >
                              <img alt="Logo" src={toAbsoluteUrl(`/media/svg/brand-logos/google-icon.svg`)} className="h-20px me-3" />
                              Créer mon compte avec Google
                         </button>
                    </MyTooltip>

                    <div className="d-flex align-items-center mb-5">
                         <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                         <span className="fw-bold text-gray-400 fs-7 mx-2">OU</span>
                         <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>

                    <div className="row">
                         <div className="col-6">
                              <div className="fv-row mb-5">
                                   <label className="form-label fw-bolder text-black fs-6">Nom</label>
                                   <input
                                        placeholder="Nom"
                                        type="text"
                                        autoComplete="off"
                                        {...formik.getFieldProps("nom")}
                                        className={clsx(
                                             "form-control bg-transparent",
                                             {
                                                  "is-invalid": formik.touched.nom && formik.errors.nom,
                                             },
                                             {
                                                  "is-valid": formik.touched.nom && !formik.errors.nom,
                                             }
                                        )}
                                   />
                                   {formik.touched.nom && formik.errors.nom && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">
                                                  <span role="alert">{formik.errors.nom}</span>
                                             </div>
                                        </div>
                                   )}
                              </div>
                         </div>
                         <div className="col-6">
                              <div className="fv-row mb-5">
                                   {/* begin::Form group prenom */}
                                   <label className="form-label fw-bolder text-black fs-6">Prénom</label>
                                   <input
                                        placeholder="Prénom"
                                        type="text"
                                        autoComplete="off"
                                        {...formik.getFieldProps("prenom")}
                                        className={clsx(
                                             "form-control bg-transparent",
                                             {
                                                  "is-invalid": formik.touched.prenom && formik.errors.prenom,
                                             },
                                             {
                                                  "is-valid": formik.touched.prenom && !formik.errors.prenom,
                                             }
                                        )}
                                   />
                                   {formik.touched.prenom && formik.errors.prenom && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">
                                                  <span role="alert">{formik.errors.prenom}</span>
                                             </div>
                                        </div>
                                   )}
                                   {/* end::Form group */}
                              </div>
                         </div>
                    </div>

                    {/* begin::Form group Email */}
                    <div className="fv-row mb-5">
                         <label className="form-label fw-bolder text-black fs-6">Email</label>
                         <input
                              placeholder="Email"
                              type="email"
                              autoComplete="off"
                              {...formik.getFieldProps("email")}
                              className={clsx(
                                   "form-control bg-transparent",
                                   { "is-invalid": formik.touched.email && formik.errors.email },
                                   {
                                        "is-valid": formik.touched.email && !formik.errors.email,
                                   }
                              )}
                         />
                         {formik.touched.email && formik.errors.email && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">
                                        <span role="alert">{formik.errors.email}</span>
                                   </div>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Password */}
                    <div className="fv-row mb-5">
                         <div className="mb-1">
                              <label className="form-label fw-bolder text-black fs-6">Mot de passe</label>
                              <div className="position-relative mb-3">
                                   <input
                                        type="password"
                                        placeholder="Mot de passe"
                                        autoComplete="off"
                                        {...formik.getFieldProps("password")}
                                        className={clsx(
                                             "form-control bg-transparent",
                                             {
                                                  "is-invalid": formik.touched.password && formik.errors.password,
                                             },
                                             {
                                                  "is-valid": formik.touched.password && !formik.errors.password,
                                             }
                                        )}
                                   />
                                   {formik.touched.password && formik.errors.password && (
                                        <div className="fv-plugins-message-container">
                                             <div className="fv-help-block">
                                                  <span role="alert">{formik.errors.password}</span>
                                             </div>
                                        </div>
                                   )}
                                   <div className="text-muted">Saisissez au moins 6 caractères avec un caractère spécial</div>
                              </div>
                         </div>
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group Confirm password */}
                    <div className="fv-row mb-5">
                         <label className="form-label fw-bolder text-black fs-6">Confirmez le mot de passe</label>
                         <input
                              type="password"
                              placeholder="Confirmez le mot de passe"
                              autoComplete="off"
                              {...formik.getFieldProps("changepassword")}
                              className={clsx(
                                   "form-control bg-transparent",
                                   {
                                        "is-invalid": formik.touched.changepassword && formik.errors.changepassword,
                                   },
                                   {
                                        "is-valid": formik.touched.changepassword && !formik.errors.changepassword,
                                   }
                              )}
                         />
                         {formik.touched.changepassword && formik.errors.changepassword && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">
                                        <span role="alert">{formik.errors.changepassword}</span>
                                   </div>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="fv-row mb-5">
                         <label className="form-check form-check-inline" htmlFor="kt_login_toc_agree">
                              <input className="form-check-input cursor-pointer" type="checkbox" id="kt_login_toc_agree" {...formik.getFieldProps("acceptTerms")} />
                              <span className={"fw-bold"} style={{ color: "black" }}>
                                   J'accepte la
                                   <a href="https://keenthemes.com/metronic/?page=faq" target="_blank" className="ms-1 link-primary">
                                        politique de confidentialité
                                   </a>
                                   .
                              </span>
                         </label>
                         {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">
                                        <span role="alert">{formik.errors.acceptTerms}</span>
                                   </div>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="text-center row">
                         <div className="col-6">
                              <Link to="/auth/login">
                                   <button type="button" id="kt_login_signup_form_cancel_button" className="btn btn-lg btn-light-primary w-100 mb-5">
                                        Annuler
                                   </button>
                              </Link>
                         </div>
                         <div className="col-6">
                              <button
                                   type="submit"
                                   id="kt_sign_up_submit"
                                   className="btn btn-lg btn-primary w-100 mb-5"
                                   disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms || loading}
                              >
                                   {!loading && <span className="indicator-label">Valider</span>}
                                   {loading && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                             Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                   )}
                              </button>
                         </div>
                    </div>
                    {/* end::Form group */}
               </form>
          )
     } else {
          return (
               <div className="alert alert-success d-flex align-items-center p-5 mb-10">
                    <span className={"svg-icon svg-icon-2hx svg-icon-success me-3"}>
                         <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon svg-icon-2hx svg-icon-success me-4" />
                    </span>
                    <div className="d-flex flex-column">
                         <h5 className="mb-1">Parfait ! Votre compte a bien été créé.</h5>
                         <span>Pour terminer, n'oubliez pas de l'activer grâce au mail que nous venons de vous envoyer.</span>
                    </div>
               </div>
          )
     }
}
