import React, { useState } from "react"
import { ConsultantNdfDetailModel, IHttpErrorResponseModel } from "../../../../../../../models"
import MyAlert from "../../../../../../../utils/MyAlert"
import * as Yup from "yup"
import { FieldArray, Form, Formik, FormikHelpers } from "formik"
import { useQueryClient } from "react-query"
import { ndfAddDetailSimple } from "../../../../core/_request"
import MySelectField from "../../../../../../../utils/fields/MySelectField"
import {
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "../../../../../../../constants"
import { INdfAddNdfDetailRequestModel } from "../../../../core/_models"
import { toast } from "react-toastify"
import MyDateDayMonthYearField from "../../../../../../../utils/fields/MyDateDayMonthYearField"
import { useNdfUpdateDetailsContext } from "./NdfUpdateDetailsWrapper"
import { AxiosError } from "axios"
import MySelectCreatableField from "../../../../../../../utils/fields/MySelectCreatableField"
import MyTextareaField from "../../../../../../../utils/fields/MyTextareaField"

export const NdfUpdateDetailsWrapperFormAddDepenseSimple = () => {
     const queryClient = useQueryClient()
     const context = useNdfUpdateDetailsContext()
     const [actionAfterSubmitForm, setActionAfterSubmitForm] = useState<"close" | "keepAddingDetails">("close")

     // Schema
     const schema = Yup.object().shape({
          date: Yup.string().required("Champ requis"),
          categorie: Yup.string().required("Champ requis"),
          montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect."),
          description: Yup.string().required("Champ requis"),
          documents: Yup.array().of(
               Yup.mixed()
                    .required("PJ requise")
                    .test("fileSize", "La taille de la PJ doit être inférieure ou égale à 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "La PJ doit être au format JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    })
          ),
          repasSeulOuEnGroupe: Yup.string()
               .label(`"Seul ou en groupe"`)
               .when("categorie", {
                    is: CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                    then: schema => schema.required(),
               }),
          invites: Yup.array()
               .label(`"Invités"`)
               .when("repasSeulOuEnGroupe", {
                    is: "REPAS_MIDI_EN_GROUPE",
                    then: schema => schema.required(),
               }),
     })

     const initialValues: INdfAddNdfDetailRequestModel = {
          documents: [new File([new Blob()], "")],
     }

     function handleSubmit(values: INdfAddNdfDetailRequestModel, helpers: FormikHelpers<INdfAddNdfDetailRequestModel>) {
          helpers.setStatus(null)
          ndfAddDetailSimple(context.ndfId, values)
               .then(r => {
                    queryClient.setQueryData(["ndfDetails", context.ndfId], (details: ConsultantNdfDetailModel[] | undefined) => {
                         if (details) {
                              const dataWithNewDepense = [r.data, ...details]
                              context.computeTotalExpenses(dataWithNewDepense || [])
                              return dataWithNewDepense
                         }

                         return []
                    })

                    helpers.setSubmitting(false)

                    if (actionAfterSubmitForm == "close") {
                         toast.success("Votre justificatif a bien été ajouté.")
                         context.setIsAddDetailsModalShown(false)
                    } else {
                         toast.success("Votre justificatif a bien été ajouté. Vous pouvez continuer à ajouter un autre si vous le souhaitez.")
                         helpers.resetForm()
                    }
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    if (error?.code === "REGULARATION_IS_REQUIRED") {
                         helpers.setStatus(
                              <>
                                   <p>{error?.detail}</p>
                                   <b className={"mb-2 fs-5"}>Merci de suivre les étapes suivantes:</b>
                                   <ul>
                                        <li>Accédez à la liste des déclarations.</li>
                                        <li>Demandez la régularisation de la déclaration du mois concerné.</li>
                                        <li>Attendez la validation de votre responsable (utilisez ce temps pour finaliser la déclaration du mois en cours).</li>
                                        <li>Une fois la demande validée, ajoutez votre dépense et vérifiez qu'il n'y a plus de ticket restaurant.</li>
                                        <li>Finaliser et signer votre déclaration.</li>
                                   </ul>
                              </>
                         )
                    } else {
                         helpers.setStatus(error?.detail)
                    }
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {({ values, setFieldValue, isSubmitting, status, errors }) => {
                    return (
                         <Form noValidate autoComplete="off">
                              {status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {status}
                                   </MyAlert>
                              )}
                              <div className={"mb-4"}>
                                   <label className="form-label fw-bolder  fs-6">Justificatifs</label>
                                   <FieldArray name={"documents"}>
                                        {({ push, remove }) => (
                                             <div className={"row"}>
                                                  {values.documents!.map((_, index) => {
                                                       return (
                                                            <div className={"col-md-6 col-lg-4 mb-4"} key={index}>
                                                                 <div className="d-flex">
                                                                      <input
                                                                           type={"file"}
                                                                           name={`documents[${index}]`}
                                                                           onChange={e => setFieldValue(`documents[${index}]`, e.target.files![0])}
                                                                           className={"form-control me-2"}
                                                                      />
                                                                      {index > 0 && (
                                                                           <button className={"btn btn-light-danger btn-sm"} type={"button"} onClick={() => remove(index)}>
                                                                                <span className={"fas fa-minus-circle"}></span>
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                                 {errors.documents && errors.documents[index] && <div className={"text-danger"}>{errors.documents[index]}</div>}
                                                                 <div className={"d-flex justify-content-sm-start justify-content-center mt-4"}>
                                                                      {index == values.documents!.length - 1 && (
                                                                           <button
                                                                                className={`btn btn-light-primary btn-sm ${index > 0 && "me-2"}`}
                                                                                type={"button"}
                                                                                onClick={() => push({})}
                                                                           >
                                                                                Ajouter un autre justificatif <i className={"fas fa-plus-circle ps-1 pe-0"} />
                                                                           </button>
                                                                      )}
                                                                 </div>
                                                            </div>
                                                       )
                                                  })}
                                             </div>
                                        )}
                                   </FieldArray>
                              </div>

                              <label className="form-label fw-bolder fs-6">Détails</label>
                              <div className={"row mb-4"}>
                                   <div className={"col-md-4 mb-md-0 mb-2"}>
                                        <MyDateDayMonthYearField onChange={date => setFieldValue(`date`, date)} value={values.date} name={`date`} placeholder={"Date"} />
                                        {errors.date && <div className={"text-danger"}>{errors.date}</div>}
                                   </div>
                                   <div className={"col-md-4 mb-md-0 mb-2"}>
                                        <MySelectField
                                             options={CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                             name={`categorie`}
                                             isSearchable={false}
                                             isClearable={false}
                                             placeholder={"Catégorie"}
                                             value={values.categorie}
                                             onChange={value => setFieldValue(`categorie`, value)}
                                        />
                                        {errors.categorie && <div className={"text-danger"}>{errors.categorie}</div>}
                                   </div>
                                   <div className={"col-md-4 mb-md-0 mb-2"}>
                                        <input
                                             className={"form-control"}
                                             value={values.montantTTC}
                                             name={`montantTTC`}
                                             placeholder={"Montant TTC"}
                                             autoComplete={"off"}
                                             inputMode={"numeric"}
                                             onChange={e => {
                                                  setFieldValue(`montantTTC`, e.target.value.replace(",", "."))
                                             }}
                                        />
                                        {errors.montantTTC && <div className={"text-danger"}>{errors.montantTTC}</div>}
                                   </div>
                              </div>

                              {values.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                   <>
                                        <div className={"col-12 mt-2"}>
                                             <MyAlert type={"primary"} classNames={"mb-2"}>
                                                  Veuillez indiquer s'il s'agit d'un repas seul ou en groupe
                                             </MyAlert>
                                             <MySelectField
                                                  name={"repasSeulOuEnGroupe"}
                                                  options={[
                                                       {
                                                            value: "REPAS_MIDI_SEUL",
                                                            label: "Repas seul",
                                                       },
                                                       {
                                                            value: "REPAS_MIDI_EN_GROUPE",
                                                            label: "Repas en groupe",
                                                       },
                                                  ]}
                                                  value={values.repasSeulOuEnGroupe}
                                                  onChange={val => setFieldValue("repasSeulOuEnGroupe", val)}
                                                  placeholder={"Choisissez si seul ou en groupe"}
                                             />
                                             {errors.repasSeulOuEnGroupe && <div className={"text-danger"}>{errors.repasSeulOuEnGroupe}</div>}
                                        </div>

                                        {values.repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                             <>
                                                  <div className={"col-12 mt-2"}>
                                                       <MyAlert type={"primary"} classNames={"mb-2"}>
                                                            Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un client, ajoutez le nom de la société en plus.
                                                       </MyAlert>
                                                       <MySelectCreatableField
                                                            name={"invites"}
                                                            options={[]}
                                                            value={values.invites}
                                                            onChange={val => setFieldValue("invites", val)}
                                                            placeholder={"Saisissez les invités"}
                                                            isInvalid={!!errors.invites}
                                                       />
                                                       {errors.invites && <div className={"text-danger"}>{errors.invites}</div>}
                                                  </div>
                                             </>
                                        )}
                                   </>
                              )}
                              <div className={"col-12 mt-5"}>
                                   <MyTextareaField
                                        name={"description"}
                                        value={values.description}
                                        isInvalid={!!errors.description}
                                        rows={2}
                                        onChange={val => setFieldValue("description", val)}
                                        placeholder={"Description"}
                                   />
                                   {errors.description && <div className={"text-danger"}>{errors.description}</div>}
                              </div>

                              <div className="separator separator-dashed bg-primary my-4" />
                              <div className="text-center pt-6 d-flex flex-sm-row flex-column justify-content-center">
                                   <button
                                        className={"btn btn-sm btn-primary me-2 mb-sm-0 mb-2"}
                                        type={"submit"}
                                        disabled={isSubmitting}
                                        onClick={() => setActionAfterSubmitForm("close")}
                                   >
                                        {!isSubmitting && (
                                             <>
                                                  <i className={"fas fa-check-circle"} />
                                                  Finaliser l'ajout
                                             </>
                                        )}
                                        {isSubmitting && (
                                             <span className="indicator-progress" style={{ display: "block" }}>
                                                  Ajout en cours
                                                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                             </span>
                                        )}
                                   </button>
                                   {/*{!isSubmitting && (
                                        <button
                                             className={"btn btn-sm btn-info"}
                                             type={"submit"}
                                             disabled={isSubmitting}
                                             onClick={() => setActionAfterSubmitForm("keepAddingDetails")}
                                        >
                                             <i className={"fas fa-plus-circle"} />
                                             Finaliser et continuer à ajouter
                                        </button>
                                   )}*/}
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}
