import { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react"
import { LayoutSplashScreen } from "../../../../_metronic/layout/core"
import { AuthModel, AuthUserModel } from "./_models"
import * as authHelper from "./AuthHelpers"
import { getUserByToken } from "./_requests"
import { WithChildren } from "../../../../_metronic/helpers"
import {
     CONST_CANDIDAT_DOCUMENT_TYPE_CV,
     CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2,
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
     CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
     CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
     CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
     CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
     CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_PAYS_CODE_FRANCE,
     CONST_USER_ROLE_CANDIDAT,
     CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE,
     CONST_USER_ROLE_CONSULTANT,
} from "../../../constants"

type AuthContextProps = {
     auth: AuthModel | undefined
     saveAuth: (auth: AuthModel | undefined) => void
     currentUser: AuthUserModel | undefined
     setCurrentUser: Dispatch<SetStateAction<AuthUserModel | undefined>>
     completionInfoProfilEnPourcentage: number
     infoPersonellesNecessairesToEmbaucheToBeFilled: string[]
     documentsNecessairesToEmbaucheToBeFilled: string[]
     logout: () => void
}

const initAuthContextPropsState = {
     auth: authHelper.getAuth(),
     saveAuth: () => {},
     currentUser: undefined,
     setCurrentUser: () => {},
     completionInfoProfilEnPourcentage: 0,
     infoPersonellesNecessairesToEmbaucheToBeFilled: [],
     documentsNecessairesToEmbaucheToBeFilled: [],
     logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
     return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
     const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
     const [currentUser, setCurrentUser] = useState<AuthUserModel | undefined>()

     // % complétion info profil
     const completionInfoProfilEnPourcentage: number = useMemo(() => {
          if (!currentUser) return 0
          let totalInfoToFill: number = 100
          let totalFilled: number = 0

          if (currentUser.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && currentUser?.candidat?.candidat) {
               totalInfoToFill = 12
               totalFilled = 0

               if (currentUser.candidat.candidat.civilite != null || currentUser.candidat.candidat.civilite != undefined) totalFilled++
               if (currentUser.candidat.candidat.nom != null || currentUser.candidat.candidat.nom != undefined) totalFilled++
               if (currentUser.candidat.candidat.prenom != null || currentUser.candidat.candidat.prenom != undefined) totalFilled++
               if (currentUser.candidat.candidat.email != null || currentUser.candidat.candidat.email != undefined) totalFilled++
               if (currentUser.candidat.candidat.tel != null || currentUser.candidat.candidat.tel != undefined) totalFilled++
               if (currentUser.candidat.candidat.dateNaissance != null || currentUser.candidat.candidat.dateNaissance != undefined) totalFilled++
               if (currentUser.candidat.candidat.pays != null || currentUser.candidat.candidat.pays != undefined) totalFilled++
               if (currentUser.candidat.candidat.niveauEtudes != null || currentUser.candidat.candidat.niveauEtudes != undefined) totalFilled++
               if (currentUser.candidat.candidat.disponibilite != null || currentUser.candidat.candidat.disponibilite != undefined) totalFilled++
               if (currentUser.candidat.candidat.mobilite != null || currentUser.candidat.candidat.mobilite != undefined) totalFilled++
               if (currentUser.candidat.candidat.salaireNetSouhaite != null || currentUser.candidat.candidat.salaireNetSouhaite != undefined) totalFilled++
               if (currentUser.candidat.candidat.isHabilitableDefense != null || currentUser.candidat.candidat.isHabilitableDefense != undefined) totalFilled++

               const docs = currentUser.candidat.candidat?.undeletedCandidatDocuments
               totalInfoToFill += 1 // Que le CV

               if (docs && docs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++
          } else if (currentUser.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.consultant?.consultant) {
               const consultant = currentUser.consultant.consultant
               totalInfoToFill = consultant.contrat != CONST_CONSULTANT_CONTRAT_ST ? 13 : 9
               totalFilled = 0

               if (consultant.civilite != null || consultant.civilite != undefined) totalFilled++
               if (consultant.nom != null || consultant.nom != undefined) totalFilled++
               if (consultant.prenom != null || consultant.prenom != undefined) totalFilled++
               if (consultant.naissanceDate != null || consultant.naissanceDate != undefined) totalFilled++
               if (consultant.naissancePays != null || consultant.naissancePays != undefined) totalFilled++
               if (consultant.nationalite != null || consultant.nationalite != undefined) totalFilled++
               if (consultant.domicileAdresse != null || consultant.domicileAdresse != undefined) totalFilled++
               if (consultant.domicilePays != null || consultant.domicilePays != undefined) totalFilled++
               if (consultant.domicileCodePostal != null || consultant.domicileCodePostal != undefined) totalFilled++

               if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                    if (consultant.numeroSecuriteSociale != null || consultant.numeroSecuriteSociale != undefined) totalFilled++
                    if (consultant.contactUrgenceNom != null || consultant.contactUrgenceNom != undefined) totalFilled++
                    if (consultant.contactUrgencePrenom != null || consultant.contactUrgencePrenom != undefined) totalFilled++
                    if (consultant.contactUrgenceTel != null || consultant.contactUrgenceTel != undefined) totalFilled++
               }

               const consultantDocs = currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments
               const candidatDocs = currentUser?.candidat?.candidat?.undeletedCandidatDocuments || []
               totalInfoToFill += (() => {
                    if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                         return consultant.nationalite?.code == CONST_PAYS_CODE_FRANCE ? 5 : 6
                    } else {
                         return 1 // Que le CV pour les ST
                    }
               })()

               if (candidatDocs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++

               if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                    if (consultant.nationalite?.code != CONST_PAYS_CODE_FRANCE && consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR))
                         totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) totalFilled++
               }
          } else if (currentUser.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant) {
               // Espace consultant
               const consultant = currentUser.consultant.consultant

               totalInfoToFill = consultant.contrat != CONST_CONSULTANT_CONTRAT_ST ? 7 : 3
               totalFilled = 0

               if (consultant.domicileAdresse != null || consultant.domicileAdresse != undefined) totalFilled++
               if (consultant.domicilePays != null || consultant.domicilePays != undefined) totalFilled++
               if (consultant.domicileCodePostal != null || consultant.domicileCodePostal != undefined) totalFilled++

               if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                    if (consultant.numeroSecuriteSociale != null || consultant.numeroSecuriteSociale != undefined) totalFilled++
                    if (consultant.contactUrgenceNom != null || consultant.contactUrgenceNom != undefined) totalFilled++
                    if (consultant.contactUrgencePrenom != null || consultant.contactUrgencePrenom != undefined) totalFilled++
                    if (consultant.contactUrgenceTel != null || consultant.contactUrgenceTel != undefined) totalFilled++
               }

               const consultantDocs = currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments
               const candidatDocs = currentUser.candidat?.candidat?.undeletedCandidatDocuments || []
               totalInfoToFill += (() => {
                    if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                         return consultant.nationalite?.code == CONST_PAYS_CODE_FRANCE ? 5 : 6
                    } else {
                         return 1 // Que le CV pour les ST
                    }
               })()

               if (candidatDocs.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) totalFilled++

               if (consultant.contrat != CONST_CONSULTANT_CONTRAT_ST) {
                    if (consultant.nationalite?.code != CONST_PAYS_CODE_FRANCE && consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR))
                         totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) totalFilled++
                    if (consultantDocs.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) totalFilled++
               }
          }

          return Math.round((totalFilled / totalInfoToFill) * 100)
     }, [currentUser])

     const infoPersonellesNecessairesToEmbaucheToBeFilled: string[] = useMemo(() => {
          if (!currentUser) return []

          let infoMissing: string[] = []
          if (
               (currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT)) &&
               currentUser?.consultant?.consultant
          ) {
               const consultant = currentUser?.consultant?.consultant

               console.log(consultant)
               // All
               if (!consultant?.telProPays) infoMissing.push("Indicatif téléphonique")
               if (!consultant?.telPro) infoMissing.push("N° de téléphone")

               // Except ST portage & ST SS
               if (![CONST_CONSULTANT_STATUT_ST_PORTAGE, CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE].includes(consultant.statut as string)) {
                    if (!consultant?.naissanceDate) infoMissing.push("Date de naissance")
                    if (!consultant?.naissancePays) infoMissing.push("Pays de naissance")
                    if (consultant?.naissancePays?.code === CONST_PAYS_CODE_FRANCE && !consultant?.naissanceVilleFrance) infoMissing.push("Ville de naissance")
                    if (consultant?.naissancePays?.code !== CONST_PAYS_CODE_FRANCE && !consultant?.naissanceVille) infoMissing.push("Ville de naissance")
                    if (!consultant?.nationalite) infoMissing.push("Nationalité")
                    if (!consultant?.domicileAdresse) infoMissing.push("Adresse de résidence")
                    if (!consultant?.domicilePays) {
                         infoMissing.push("Pays de résidence")
                    } else {
                         if (consultant?.domicilePays.code === CONST_PAYS_CODE_FRANCE && !consultant?.domocileVilleFrance) infoMissing.push("Ville de résidence")
                         if (consultant?.domicilePays.code !== CONST_PAYS_CODE_FRANCE && !consultant?.domicileVille) infoMissing.push("Ville de résidence")
                    }
               }

               // Only salarié
               if (consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST) {
                    if (!consultant?.numeroSecuriteSociale) infoMissing.push("N° de Sécurité Sociale")
               }
          }

          return infoMissing
     }, [currentUser])

     const documentsNecessairesToEmbaucheToBeFilled: string[] = useMemo(() => {
          if (!currentUser) return []

          let infoMissing: string[] = []

          if (!currentUser.candidat?.candidat?.undeletedCandidatDocuments?.find(item => item.type === CONST_CANDIDAT_DOCUMENT_TYPE_CV)) {
               const item = CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CANDIDAT_DOCUMENT_TYPE_CV)
               infoMissing.push(item!.label)
          }

          if (currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT)) {
               if (currentUser.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST) {
                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI)
                         infoMissing.push(item!.label)
                    }

                    if (
                         currentUser.consultant?.consultant?.nationalite?.code !== CONST_PAYS_CODE_FRANCE &&
                         !currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                    ) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR)
                         infoMissing.push(item!.label)
                    }

                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS)
                         infoMissing.push(item!.label)
                    }

                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_RIB)
                         infoMissing.push(item!.label)
                    }

                    if (!currentUser?.consultant?.consultant?.undeletedAndSigneConsultantDocuments.find(item => item.type === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)) {
                         const item = CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2.find(e => e.value === CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE)
                         infoMissing.push(item!.label)
                    }
               }
          }

          return infoMissing
     }, [currentUser])

     const saveAuth = (auth: AuthModel | undefined) => {
          setAuth(auth)
          if (auth) {
               authHelper.setAuth(auth)
          } else {
               authHelper.removeAuth()
          }
     }

     const logout = () => {
          saveAuth(undefined)
          setCurrentUser(undefined)
     }

     return (
          <AuthContext.Provider
               value={{
                    auth,
                    saveAuth,
                    currentUser,
                    setCurrentUser,
                    logout,
                    completionInfoProfilEnPourcentage,
                    infoPersonellesNecessairesToEmbaucheToBeFilled,
                    documentsNecessairesToEmbaucheToBeFilled,
               }}
          >
               {children}
          </AuthContext.Provider>
     )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
     const { auth, logout, setCurrentUser } = useAuth()
     const didRequest = useRef(false)
     const [showSplashScreen, setShowSplashScreen] = useState(true)
     // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
     useEffect(() => {
          const requestUser = async (apiToken: string) => {
               try {
                    if (!didRequest.current) {
                         const { data } = await getUserByToken(apiToken)
                         if (data) {
                              setCurrentUser(data)
                         }
                    }
               } catch (error) {
                    if (!didRequest.current) {
                         logout()
                    }
               } finally {
                    setShowSplashScreen(false)
               }

               return () => (didRequest.current = true)
          }

          if (auth && auth.token) {
               requestUser(auth.token)
          } else {
               logout()
               setShowSplashScreen(false)
          }
          // eslint-disable-next-line
     }, [])

     return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
