/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {PageTitle} from '../../../../_metronic/layout/core'
import {InfoPersoEdit} from "../../../modules/compte/components/infoPerso/infoPersoEdit/InfoPersoEdit";
import {DocumentsPerso} from "../../../modules/compte/components/Documents";
import {KTSVG} from "../../../../_metronic/helpers";
import {useAuth} from "../../../modules/auth";

function InfoToCompleterAvantDemandeEmbauche() {
    const {infoPersonellesNecessairesToEmbaucheToBeFilled, documentsNecessairesToEmbaucheToBeFilled} = useAuth()
    const [infoPersoContainerShown, setInfoPersoContainerShown] = useState<boolean>(true)
    const [docsContainerShown, setDocsContainerShown] = useState<boolean>(true)

    return <>
        <div className={"d-flex justify-content-center"}>
            <div className={"w-100 w-lg-75 w-xxl-65"}>
        <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer' onClick={() => setInfoPersoContainerShown(prev => !prev)}>
                <div className='card-title m-0'>
                    <h4 className='fw-bolder m-0 d-none d-sm-block'>Informations personnelles</h4>
                    {infoPersonellesNecessairesToEmbaucheToBeFilled.length > 0 ?
                        <div className={"ms-sm-4"}>
                            <span className="badge badge-light-warning fs-6 badge-sm p-2">A compléter <i className={"fas fa-exclamation-circle text-warning ms-2"}/> </span>
                        </div> :
                        <div className={"ms-sm-4"}>
                            <span className="badge badge-light-success fs-6 badge-sm p-2">Rien à ajouter <i className={"fas fa-check-circle text-success ms-2"}/> </span>
                        </div>
                    }
                </div>

                <div className={"align-self-center"}>
                    <KTSVG path={infoPersoContainerShown ? '/media/icons/duotune/arrows/arr073.svg' : '/media/icons/duotune/arrows/arr072.svg'}
                           className='svg-icon-1 svg-icon-3x'/>
                </div>
            </div>

            <div className={`card-body p-9 ${!infoPersoContainerShown && 'd-none'}`}>
                <h4 className='fw-bolder m-0 d-sm-none d-block'>Informations personnelles</h4>
                <InfoPersoEdit/>
            </div>
        </div>

        <div className='card mb-5 mb-xl-10'>
            <div className='card-header cursor-pointer' onClick={() => setDocsContainerShown(prev => !prev)}>
                <div className='card-title m-0'>
                    <h4 className='fw-bolder m-0 d-none d-sm-block'>Documents</h4>
                    {documentsNecessairesToEmbaucheToBeFilled.length > 0 ?
                        <div className={"ms-sm-4"}>
                            <span className="badge badge-light-warning badge-sm fs-6 p-2">A compléter <i className={"fas fa-exclamation-circle text-warning ms-2"}/> </span>
                        </div> :
                        <div className={"ms-sm-4"}>
                            <span className="badge badge-light-success badge-sm fs-6 p-2">Rien à ajouter <i className={"fas fa-check-circle text-success ms-2"}/> </span>
                        </div>
                    }
                </div>
                <div className={"align-self-center"}>
                    <KTSVG path={docsContainerShown ? '/media/icons/duotune/arrows/arr073.svg' : '/media/icons/duotune/arrows/arr072.svg'}
                           className='svg-icon-1 svg-icon-3x'/>
                </div>
            </div>

            <div className={`card-body p-9 ${!docsContainerShown && 'd-none'}`}>
                <h4 className='fw-bolder m-0 d-sm-none d-block'>Documents</h4>
                <DocumentsPerso/>
            </div>
        </div>
            </div>
        </div>
    </>

}

const InfoToCompleterAvantDemandeEmbaucheWrapper: FC = () => {
    return (
        <>
            <PageTitle breadcrumbs={[]}>Dossier de recrutement</PageTitle>
            <InfoToCompleterAvantDemandeEmbauche/>
        </>
    )
}

export {InfoToCompleterAvantDemandeEmbaucheWrapper}
