import { AuthModel } from "./_models"
import moment from "moment"
import Cookies from "js-cookie"

const AUTH_LOCAL_STORAGE_KEY = "kt-auth-react-v"

const getAuth = (): AuthModel | undefined => {
     if (!localStorage) {
          return
     }

     const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
     if (!lsValue) {
          return
     }

     try {
          const auth: AuthModel = JSON.parse(lsValue) as AuthModel
          if (auth) {
               // You can easily check auth_token expiration also
               return auth
          }
     } catch (error) {
          console.error("AUTH LOCAL STORAGE PARSE ERROR", error)
     }
}

const setAuth = (auth: AuthModel) => {
     if (!localStorage) {
          return
     }

     try {
          const lsValue = JSON.stringify(auth)
          localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
     } catch (error) {
          console.error("AUTH LOCAL STORAGE SAVE ERROR", error)
     }
}

const removeAuth = () => {
     if (!localStorage) {
          return
     }

     try {
          localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
     } catch (error) {
          console.error("AUTH LOCAL STORAGE REMOVE ERROR", error)
     }
}

export function setupAxios(axios: any) {
     const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/

     function isIsoDateString(value: any): boolean {
          return value && typeof value === "string" && isoDateFormat.test(value)
     }

     function handleDates(body: any) {
          if (body === null || body === undefined || typeof body !== "object") return body

          for (const key of Object.keys(body)) {
               const value = body[key]
               if (isIsoDateString(value)) {
                    body[key] = moment(value)
               } else if (typeof value === "object") handleDates(value)
          }
     }

     axios.defaults.headers.Accept = "application/json"
     axios.interceptors.request.use(
          (config: { method; data; url; params; headers: { "Authorization-api": string } }) => {
               // Si cookie XDEBUG_SESSION is enabled, it means that we are ready to debug php. Il faut donc soit set un cookie soit envoyer un parameter XDEBUG_SESSION dans l'url
               if (Cookies.get("XDEBUG_SESSION")) {
                    config.params = {
                         ...config.params,
                         XDEBUG_SESSION: Cookies.get("XDEBUG_SESSION"),
                    }
                    config.url = `${config.url}`
               }

               // Append "Authorization-api" to all requests
               const auth = getAuth()
               if (auth && auth.token) config.headers["Authorization-api"] = `Bearer ${auth.token}`

               // Remove all empty values from POST/PUT payload
               if (config.method === "post" || config.method === "put") {
                    // Check if data is an object
                    if (config.data && typeof config.data === "object") {
                         // Remove empty properties
                         Object.keys(config.data).forEach(key => {
                              if (config.data[key] === "" || config.data[key] === null || config.data[key] === undefined) {
                                   delete config.data[key]
                              }
                         })
                    }
               }

               return config
          },
          (err: any) => Promise.reject(err)
     )

     axios.interceptors.response.use(
          (response: any) => {
               handleDates(response.data)
               return response
          },
          (error: any) => {
               if (error.response && error.response.status === 401 && !window.location.href.includes("/auth")) {
                    window.location.href = "/auth?session_expired"
               }
               return Promise.reject(error)
          }
     )
}

export { getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY }
