import axios from "axios"
import { API_URL_ESPACE_AVENTURIER } from "./constants"


// BEGIN:: CRA-details requests
export function downloadFile(reference: string, responseType: 'arraybuffer' | 'blob') {
    return axios.post(`${API_URL_ESPACE_AVENTURIER}/download-document`, {reference}, {
        responseType
    })
}
