import axios from "axios"
import { CandidatDocumentModel } from "../../models"
import { API_URL_ESPACE_AVENTURIER } from "../../constants"

export function setDocoAsCompleted(doco_id: number) {
     return axios.post<{ message: string }>(`${API_URL_ESPACE_AVENTURIER}/set-doco-as-completed`, { doco_id })
}

export function setDocoAsEnCours(doco_id: number) {
    return axios.post<{ message: string }>(`${API_URL_ESPACE_AVENTURIER}/set-doco-as-en-cours`, {doco_id})
}

export function getDocos() {
    return axios.get<CandidatDocumentModel[]>(`${API_URL_ESPACE_AVENTURIER}/docos`)
}
