import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { resetPasswordAfterLogin } from "../../../../core/_requests"
import { Slide, toast } from "react-toastify"
import { AxiosError } from "axios"

const formSchemaValidation = Yup.object().shape({
     currentPassword: Yup.string().required("Ce champ est requis"),
     newPassword: Yup.string()
          .min(3, "Minimum 6 caractères")
          .max(50, "Maximum 50 caractères")
          .matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, "Au mois un caractère spécial")
          .required("Ce champ est requis"),
     passwordConfirmation: Yup.string()
          .required("Ce champ est requis")
          .oneOf([Yup.ref("newPassword")], "Le mot de passe et la confirmation du mot de passe ne correspondent pas"),
})

const ChangePassword: React.FC = () => {
    const [showPasswordForm, setShowPasswordForm] = useState<boolean>(false)

    const formik = useFormik<{currentPassword: string, newPassword: string, passwordConfirmation: string}>({
        initialValues: {
            currentPassword: '',
            newPassword: '',
            passwordConfirmation: ''
        },
        validationSchema: formSchemaValidation,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)

            resetPasswordAfterLogin(values.currentPassword, values.newPassword).then(r => {
                setSubmitting(false)
                formik.resetForm()
                toast.success('Le mot de passe a bien été modifié!', {
                    position: "top-right",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    transition: Slide,
                    draggable: true,
                    theme: "light",
                });
                setShowPasswordForm(false)
            }).catch((e: AxiosError) => {
                if (e.response?.status === 400) {
                    setStatus(e.response?.data?.message)
                } else {
                    setStatus("Un problème est survenu. Le mot de passe n'a pas pu être modifié.")
                }
                setSubmitting(false)
            })
        },
    })

    return (
        <div className='d-flex flex-wrap align-items-center'>
            <div className={' ' + (showPasswordForm && 'd-none')}>
                <div className='fs-6 fw-bolder mb-1'>Mot de passe</div>
                <div className='fw-bold text-gray-600'>************</div>
            </div>

            <div className={'flex-row-fluid ' + (!showPasswordForm && 'd-none')}>
                <form
                    onSubmit={formik.handleSubmit}
                    className='form'
                    noValidate
                >
                    {formik.status &&
                        <div className='alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{formik.status}</div>
                        </div>
                    }

                    <div className='row mb-2'>
                        <div className='col-lg-4'>
                            <div className='fv-row mb-2'>
                                <label htmlFor='currentpassword' className='form-label fs-6 fw-bolder mb-3'>
                                    Mot de passe actuel
                                </label>
                                <input
                                    type='password'
                                    className='form-control'
                                    placeholder={"mot de passe actuel"}
                                    id='currentpassword'
                                    {...formik.getFieldProps('currentPassword')}
                                />
                                {formik.touched.currentPassword && formik.errors.currentPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div
                                            className='fv-help-block'>{formik.errors.currentPassword}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='fv-row mb-2'>
                                <label htmlFor='newpassword' className='form-label fs-6 fw-bolder mb-3'>
                                    Nouveau mot de passe
                                </label>
                                <input
                                    type='password'
                                    className='form-control form-control-lg  '
                                    id='newpassword'
                                    placeholder={"Nouveau mot de passe"}
                                    {...formik.getFieldProps('newPassword')}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div
                                            className='fv-help-block'>{formik.errors.newPassword}</div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className='col-lg-4'>
                            <div className='fv-row'>
                                <label htmlFor='confirmpassword' className='form-label fs-6 fw-bolder mb-3'>
                                    Confirmer le nouveau mot de passe
                                </label>
                                <input
                                    type='password'
                                    className='form-control form-control-lg  '
                                    id='confirmpassword'
                                    placeholder={"Confirmer le nouveau mot de passe"}
                                    {...formik.getFieldProps('passwordConfirmation')}
                                />
                                {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
                                    <div className='fv-plugins-message-container'>
                                        <div
                                            className='fv-help-block'>{formik.errors.passwordConfirmation}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='form-text mb-5'>
                        Le mot de passe doit contenir au mois 6 caractères et 1 caractère spécial (exemple
                        & ou @)
                    </div>

                    <div className='d-flex justify-content-end'>
                        <button
                            type='submit'
                            className='btn btn-primary me-2 px-6'
                            disabled={formik.isSubmitting}
                        >
                            {!formik.isSubmitting && 'Mettre à jour'}
                            {formik.isSubmitting && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Veuillez patienter...{' '}
                                    <span
                                        className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                        <button onClick={() => setShowPasswordForm(false)} type='button' className='btn btn-light-danger px-6'>
                            Annuler
                        </button>
                    </div>
                </form>
            </div>

            <div className={'ms-auto ' + (showPasswordForm && 'd-none')}>
                <button
                    onClick={() => {
                        setShowPasswordForm(true)
                    }}
                    className='btn btn-light btn-active-light-primary'>
                    Modifier
                </button>
            </div>
        </div>

    )
}


export {ChangePassword}
