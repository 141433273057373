import {MenuItem} from './MenuItem'
import {useAuth} from "../../../../../app/modules/auth";
import {useMemo} from "react";
import MyTooltip from "../../../../../app/utils/MyTooltip";

export function MenuInner() {
    return (
        <>
            {/*<MenuItem title={"Offres"} to='/offres'/>*/}
        </>
    )
}
