import React, {useMemo} from "react";
import {TableColumn} from 'react-data-table-component';
import {Link} from "react-router-dom";
import {useQuery} from "react-query";
import {AxiosError} from "axios";
import {FirstLetterUppercase} from "../../../../../helpers/helper-global";
import MyTooltip from "../../../../../utils/MyTooltip";
import moment from "moment";
import MyAlert from "../../../../../utils/MyAlert";
import DataTableGetAllDataAtOnceFromServer from "../../../../../utils/datatable/DataTableGetAllDataAtOnceFromServer";
import {craListing} from "../../core/_request";
import {CraListingDatatable, CraRowForListinDesCraModel} from "../../core/_models";

export function CraList() {
    const getCraListingQuery = useQuery<CraRowForListinDesCraModel[], AxiosError>('craListe', () => craListing().then(r => r.data))

    const DTColumns: TableColumn<any>[] = [
        {name: "Mois", selector: row => row.mois},
        {name: "Date de création", selector: row => row.createdAt},
        {name: "Jours travaillés", selector: row => row.joursTravailles},
        {name: "", selector: row => row.actions}
    ]

    const DTRows: CraListingDatatable[] = useMemo(() => {
        if (getCraListingQuery.data) {
            return getCraListingQuery.data?.map(item => ({
                id: item.cra.id,
                mois: <Link to="update"
                            state={{
                                cra_id: item.cra.id,
                                default_step: "two",
                                breadCrumbPageTitle: FirstLetterUppercase(moment(item.cra.declaration.mois).format('MMMM YYYY'))
                            }}>
                    <span className={"fw-bold"}>{FirstLetterUppercase(item.cra.declaration.mois.format('MMMM YYYY'))}</span>
                </Link>,
                createdAt: FirstLetterUppercase(item.cra.createdAt.format('[Le] DD MMMM YYYY')),
                joursTravailles: item.nbJoursTravailles,
                actions: <div>
                    <MyTooltip title={"CRA"}>
                        <Link to="update"
                              state={{
                                  cra_id: item.cra.id,
                                  default_step: "two",
                                  breadCrumbPageTitle: FirstLetterUppercase(moment(item.cra.declaration.mois).format('MMMM YYYY'))
                              }}
                              className={`btn btn-light-success btn-sm me-2`}>
                            <span className={"fas fa-calendar-days fs-2"}/>
                        </Link>
                    </MyTooltip>

                    <MyTooltip title={"Tickets restaurant"}>
                        <Link to="update"
                              state={{
                                  cra_id: item.cra.id,
                                  default_step: "three",
                                  breadCrumbPageTitle: FirstLetterUppercase(moment(item.cra.declaration.mois).format('MMMM YYYY'))
                              }}
                              className={`btn btn-light-dark btn-sm me-2`}>
                            <span className={"fas fa-utensils fs-2"}/>
                        </Link>
                    </MyTooltip>
                </div>
            }))
        }

        return []
    }, [getCraListingQuery.dataUpdatedAt]);


    return <div className='card'>
        <div className='card-body p-5 w-xxl-1200px'>
            {/* error */}
            {!getCraListingQuery.isFetching && getCraListingQuery.isError && <MyAlert type={"danger"} classNames={"mb-4"}>
                <>
                    <span className={"me-2"}>{getCraListingQuery.error?.response?.data?.detail}</span>
                    <button className={"btn btn-sm btn-danger"} onClick={() => getCraListingQuery.refetch()}>Recharger</button>
                </>
            </MyAlert>}

            {/* New CRA */}
            <div className={"d-flex justify-content-md-end justify-content-center mb-md-6 mb-8"}>
                <Link className={`btn btn-primary btn-sm btn-primary`} to={"new"}>
                    Nouveau compte rendu d'activité <i className={"fas fa-plus-circle p-0 ms-1"}/>
                </Link>
            </div>


            {/* VIEW >= MD */}
            <div className={"d-none d-md-block"}>
                {!getCraListingQuery.isError && <DataTableGetAllDataAtOnceFromServer columns={DTColumns}
                                                                                     data={DTRows}
                                                                                     loading={getCraListingQuery.isFetching}
                                                                                     fixedHeaderScrollHeightInVh={52}
                                                                                     pagination={false}
                                                                                     noDataComponent={<div className={"p-4"}>Aucun CRA de disponible ...</div>}
                                                                                     responsive
                />}
            </div>

            {/* View XS & mobile */}
            <div className={"d-md-none d-block"}>
                {getCraListingQuery.isFetching && <div className={"text-center p-4"}>
                    <span className='spinner-border mb-2'></span>
                    <p style={{fontWeight: 500}}>Chargement des CRA ...</p>
                </div>}

                {!getCraListingQuery.isFetching && !getCraListingQuery.isError && getCraListingQuery.data && getCraListingQuery.data.length == 0 && <MyAlert type={"primary"} classNames={"mb-4"}>
                    <div>Aucun CRA de trouvé</div>
                </MyAlert>}

                {!getCraListingQuery.isFetching && !getCraListingQuery.isError && getCraListingQuery.data && getCraListingQuery.data.length > 0 && <>
                    {getCraListingQuery.data.map((item, key) => (
                        <div key={key} className={"border-start border-primary ps-4 d-flex justify-content-between align-items-center mb-6"}>
                            <div className={"fs-5 fw-bold"}>
                                <Link to="update"
                                      state={{
                                          cra_id: item.cra.id,
                                          default_step: "two",
                                          breadCrumbPageTitle: FirstLetterUppercase(moment(item.cra.declaration.mois).format('MMMM YYYY'))
                                      }}>
                                    {FirstLetterUppercase(item.cra.declaration.mois.format('MMMM YYYY'))}
                                </Link>
                            </div>

                            <div>
                                <MyTooltip title={"CRA"}>
                                    <Link to="update"
                                          state={{
                                              cra_id: item.cra.id,
                                              default_step: "two",
                                              breadCrumbPageTitle: FirstLetterUppercase(moment(item.cra.declaration.mois).format('MMMM YYYY'))
                                          }}
                                          className={`btn btn-light-success btn-sm me-2`}>
                                        <span className={"fas fa-calendar-days fs-2"}/>
                                    </Link>
                                </MyTooltip>

                                <MyTooltip title={"Tickets restaurant"}>
                                    <Link to="update"
                                          state={{
                                              cra_id: item.cra.id,
                                              default_step: "three",
                                              breadCrumbPageTitle: FirstLetterUppercase(moment(item.cra.declaration.mois).format('MMMM YYYY'))
                                          }}
                                          className={`btn btn-light-dark btn-sm me-2`}>
                                        <span className={"fas fa-utensils fs-2"}/>
                                    </Link>
                                </MyTooltip>
                            </div>
                        </div>
                    ))}
                </>}
            </div>
        </div>

    </div>
}