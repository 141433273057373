import { toAbsoluteUrl } from "../../../_metronic/helpers"
import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import MyAlert from "../../utils/MyAlert"
import MySimpleSpinner from "../../utils/MySimpleSpinner"
import { useQuery, UseQueryResult } from "react-query"
import { OffreModel } from "../../models"
import axios, { AxiosError } from "axios"
import { API_PUBLIC_GET_ANNONCE_DETAILS_URL } from "../../constants"
import CandidatureWithoutAuthStepOne from "./_CandidatureWithoutAuth_StepOne"
import CandidatureWithoutAuthStepTwo from "./_CandidatureWithoutAuth_StepTwo"
import CandidatureWithoutAuthAutoSubmitedByJobboard from "./_CandidatureWithoutAuthAutoSubmitedByJobboard"

const CandidatureWithoutAuthContext = createContext<ICandidatureWithoutAuthWrapperContextPropsModel>({} as ICandidatureWithoutAuthWrapperContextPropsModel)
export const useCandidatureWithoutAuthContext = () => useContext(CandidatureWithoutAuthContext)
const CandidatureWithoutAuth = () => {
     const urlParams = new URLSearchParams(window.location.search)
     const referenceAnnonce = (urlParams.get("reference") as string) || (urlParams.get("idJobOffer") as string)
     const jobBoard = new URLSearchParams(window.location.search).get("jobBoard") || ("" as string)

     const [isCandidatureFinished, setIsCandidatureFinished] = useState<boolean>(false)
     const [candidatureStep, setCandidatureStep] = useState<{
          step: "one" | "two"
          candidat_id: number | null
          candidat_email: string | null
     }>({ step: "one", candidat_id: null, candidat_email: null })

     const offreDetailsQuery = useQuery<OffreModel, AxiosError>("REACT_QUERY_KEY_GET_OFFRE_DETAILS", () =>
          axios.get<OffreModel>(API_PUBLIC_GET_ANNONCE_DETAILS_URL(referenceAnnonce), { params: { without_talentPlug_details: "without_talentPlug_details" } }).then(r => r.data)
     )

     return (
          <CandidatureWithoutAuthContext.Provider
               value={{
                    isCandidatureFinished,
                    setIsCandidatureFinished,
                    setCandidatureStep,
                    candidatureStep,
                    referenceAnnonce,
                    offreDetailsQuery,
                    jobBoard,
               }}
          >
               <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{ backgroundSize: "cover", backgroundImage: `url(${toAbsoluteUrl("/media/misc/auth-bg.jpg")})`, height: "100vh", color: "black" }}
               >
                    <div
                         className="w-85 w-sm-75 w-md-50 w-xxl-30 px-5 py-5 mb-15"
                         style={{
                              opacity: "0.95",
                              backgroundColor: "white",
                              borderRadius: "20px",
                              boxShadow: "0px 0px 10px -2px rgba(0, 0, 0, 0.42)",
                              overflow: "scroll",
                              maxHeight: "95%",
                         }}
                    >
                         {!referenceAnnonce && (
                              <MyAlert type={"danger"} classNames={"mb-4"}>
                                   Could not get the job offer reference. Param `idJobOffer` or Param `reference` are missing in the URL.
                              </MyAlert>
                         )}
                         {referenceAnnonce && (
                              <>
                                   {/* loader */}
                                   {offreDetailsQuery.isFetching && (
                                        <div className={"text-center p-5"}>
                                             <MySimpleSpinner size={"xl"} />{" "}
                                        </div>
                                   )}

                                   {/* error */}
                                   {!offreDetailsQuery.isFetching && offreDetailsQuery.isError && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             <>
                                                  <span className={"me-2"}>{offreDetailsQuery.error?.response?.data?.detail}</span>
                                                  <button className={"btn btn-sm btn-danger"} onClick={() => offreDetailsQuery.refetch()}>
                                                       Recharger
                                                  </button>
                                             </>
                                        </MyAlert>
                                   )}

                                   {/* no data found */}
                                   {!offreDetailsQuery.isFetching && !offreDetailsQuery.isError && !offreDetailsQuery.data && (
                                        <div className={"text-center p-4"}>
                                             <p style={{ fontWeight: 500 }}>Cette annonce n'est plus disponible</p>
                                        </div>
                                   )}

                                   {!offreDetailsQuery.isFetching && !offreDetailsQuery.isError && offreDetailsQuery.data && (
                                        <>
                                             <h3 className={"text-center text-primary"}>{offreDetailsQuery.data.intitule}</h3>
                                             <h6 className={"text-muted text-center mb-4"}>candidature</h6>
                                             <div className="separator separator-dashed mb-6" />

                                             {!isCandidatureFinished ? (
                                                  <>
                                                       {candidatureStep.step === "one" ? (
                                                            jobBoard.toLowerCase().includes("hello") ? (
                                                                 <CandidatureWithoutAuthAutoSubmitedByJobboard />
                                                            ) : (
                                                                 <CandidatureWithoutAuthStepOne />
                                                            )
                                                       ) : (
                                                            <CandidatureWithoutAuthStepTwo />
                                                       )}
                                                  </>
                                             ) : (
                                                  <div className={"text-center"}>
                                                       <MyAlert type={"success"} classNames={"mb-4"}>
                                                            Votre candidature a bien été prise en compte
                                                       </MyAlert>
                                                       <a
                                                            href={"https://cts-corporate.com/offres"}
                                                            className={"btn btn-sm btn-primary d-inline-flex align-items-center"}
                                                            target={"_parent"}
                                                       >
                                                            <span className={"fas fa-list me-1"} />
                                                            Voir nos offres d'emploi
                                                       </a>
                                                  </div>
                                             )}
                                        </>
                                   )}
                              </>
                         )}
                    </div>
               </div>
          </CandidatureWithoutAuthContext.Provider>
     )
}

export interface ICandidatureWithoutAuthWrapperContextPropsModel {
     isCandidatureFinished: boolean
     setIsCandidatureFinished: Dispatch<SetStateAction<boolean>>
     candidatureStep: {
          step: "one" | "two"
          candidat_id: number | null
          candidat_email: string | null
     }
     setCandidatureStep: Dispatch<
          SetStateAction<{
               step: "one" | "two"
               candidat_id: number | null
               candidat_email: string | null
          }>
     >
     referenceAnnonce: string
     offreDetailsQuery: UseQueryResult<OffreModel, AxiosError>
     jobBoard?: string
}

export default CandidatureWithoutAuth
