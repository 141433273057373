import React from "react"
import "../styles.scss"
import MyAlert from "../../../../../../../utils/MyAlert"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import { CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION } from "../../../../../../../constants"

const urlParams = new URLSearchParams(window.location.search)

const DeclarationUpdateRecapitulatifMessageHeader = () => {
     const codeSignature = urlParams.get("code_signature") || null
     const context = useDeclarationUpdate()

     return (
          <div className={"mb-4"}>
               {!codeSignature &&
                    [
                         CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION,
                    ].includes(context.data.declaration!.etat?.type) && (
                         <MyAlert type={"primary"} classNames={"mb-2"}>
                              <b className={"text-dark"}>Un mail de finalisation</b> vous a été envoyé pour signer votre déclaration. Si vous ne l'avez pas reçu, veuillez cliquer
                              sur le bouton "re-envoyer le mail de finalisation" situé au pied de la page.
                         </MyAlert>
                    )}
          </div>
     )
}

export default DeclarationUpdateRecapitulatifMessageHeader
