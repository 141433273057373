import React, {FC, useEffect, useMemo, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../_metronic/helpers'
import {toast} from "react-toastify";
import {CONST_DEFAULT_TOAST_OPTIONS} from "../../../constants";
import {retirerCandidature} from "../../../modules/offres/core/_requests";
import {AxiosError} from "axios";
import MyTooltip from "../../../utils/MyTooltip";
import {getCandidatures} from "./core/_requests";
import {CandidatureRowDatatable} from "./core/_models";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {Link} from "react-router-dom";
import {CandidatureModel} from "../../../models";

function Candidatures() {
    const intl = useIntl()
    const [loading, setLoading] = useState<boolean>(false)
    const [initialCandidatures, setInitialCandidatures] = useState<CandidatureModel[]>([]);
    const [candidatures, setCandidatures] = useState<CandidatureModel[]>([]);
    const [filterText, setFilterText] = useState("");
    const MySwal = withReactContent(Swal)

    useEffect(() => {
        setLoading(true)
        loadCandidatures()
    }, [])

    function loadCandidatures() {
        getCandidatures()
            .then(r => {
                setCandidatures(r.data)
                setInitialCandidatures(r.data)
                toast.dismiss()
            })
            .catch((e: AxiosError) => {
                toast.dismiss()
                toast.error(e.response?.data?.detail || intl.formatMessage({id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE"}), CONST_DEFAULT_TOAST_OPTIONS)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    const rows: CandidatureRowDatatable[] = useMemo(() => {
        return candidatures.map(candidature => ({
            id: candidature.annonce.id,
            intituleStringOnly: candidature.annonce.intitule,
            intitule: <Link to={`/offres/${candidature.annonce.id}/details/?titre=${candidature.annonce.intitule}`}>
                {candidature.annonce.intitule}
            </Link>,
            secteur: candidature.annonce.businessUnitMetier?.businessUnit?.nom,
            ville: candidature.annonce.ville?.nom,
            date: <span><span
                className={"d-none"}>{candidature.date?.format("YYYY-MM-DD")}</span>{candidature.date?.format("DD/MM/YYYY")}
            </span>,
            actions: <div>
                <MyTooltip
                    title={"Retirer cette candidature"}>
                    <button type={"button"} className="btn btn-icon btn-danger btn-sm"
                            onClick={() => handleRetraitCandidature(candidature.annonce.id)}>
                        <KTSVG path='/media/icons/duotune/arrows/arr088.svg'
                               className='svg-icon-1 svg-icon-3x'/>
                    </button>
                </MyTooltip>
            </div>
        }))
    }, [candidatures]);

    function handleRetraitCandidature(id: number) {
        MySwal.fire({
            icon: 'warning',
            text: `Êtes-vous sûr de vouloir retirer votre candidature?`,
            showCancelButton: true,
            confirmButtonText: 'Oui',
            cancelButtonText: 'Annuler',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                return retirerCandidature(id)
                    .then(r => {
                        setCandidatures(r.data)
                        toast.success("Votre candidature a bien été retirée. Nous la prendrons plus en compte.", CONST_DEFAULT_TOAST_OPTIONS)
                    })
                    .catch((e: AxiosError) => {
                        toast.error(e.response?.data?.detail || intl.formatMessage({id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE"}), CONST_DEFAULT_TOAST_OPTIONS)
                    })
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then()
    }

    const handleFilterChangeSearch = (value: string) => {
        setFilterText(value);

        const filteredItems = value == '' ? initialCandidatures : initialCandidatures.filter(item =>
            JSON.stringify(item).toLowerCase().includes(value)
        );

        setCandidatures(filteredItems);
    };

    const handleKeyUpSearch = (event) => {
        if (event.key === 'Enter') event.target.blur();
    }

    return <div className='card'>
        <div className='card-header'>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Mes candidatures</h3>
            </div>
        </div>
        <div className='card-body p-5 w-xxl-1200px'>
            {loading && <div className={"text-center p-4"}>
                <span className='spinner-border mb-2'></span>
                <p style={{fontWeight: 500}}>Chargement des candidatures en cours ...</p>
            </div>}

            {!loading && <>
                {candidatures.length > 0 && <>
                    {/* Recherche + recharger */}
                    <div className="d-flex justify-content-between mb-7">
                        <div className="w-xxl-15 w-xl-20 w-lg-25 w-md-30 w-sm-35 w-75">
                            <input type={"text"} className={"form-control form-control-sm"}
                                   onChange={e => handleFilterChangeSearch(e.target.value.toLowerCase())}
                                   placeholder={"Rechercher ..."}
                                   onKeyUp={handleKeyUpSearch}
                                   value={filterText}/>
                        </div>
                        <div className="">
                            <button className={`btn btn-primary btn-sm float-end ${loading && 'disabled'}`}
                                    onClick={() => {
                                        setFilterText('')
                                        setLoading(true)
                                        setTimeout(() => {
                                            loadCandidatures()
                                        }, 2000)
                                    }}><i className={"fas fa-sync pe-md-2 p-0"}/>
                                <span className={`d-md-inline d-none`}>Recharger la liste</span>
                            </button>
                        </div>
                    </div>

                    <div className="row mb-4" style={{maxHeight: "50vh", overflow: "scroll"}}>
                        {candidatures.map((candidature, key) => (
                            <div className="col-md-6 mb-9" key={key}>
                                <div className="d-flex align-items-center">
                                    <Link to={`/offres/${candidature.annonce.id}/details?titre=${candidature.annonce.intitule}`} state={{prevPage: 'pageCandidatures'}}
                                          className={"btn btn-active-light-dark btn-sm"}>
                                        <span className={"d-none d-sm-block text-dark"}>Voir offre</span>
                                        <i className={"d-sm-none d-block fas fa-eye p-0 text-dark fs-3"}></i>
                                    </Link>
                                    <div className={"border-start ps-3"}>
                                        <Link to={`/offres/${candidature.annonce.id}/details?titre=${candidature.annonce.intitule}`} state={{prevPage: 'pageCandidatures'}}
                                              className={"text-dark fw-bolder text-hover-primary text-active-primary fs-6 d-block py-2"}>
                                            {candidature.annonce.intitule}
                                        </Link>
                                        <span className="text-muted d-block fw-bold mb-2">
                                            <span className="badge badge-light-success">Postulé le {candidature.date?.format('DD/MM/YYYY')}</span>
                                        </span>
                                        <div className="text-dark fw-bold">
                                            <span className={"cursor-pointer text-danger text-hover-primary d-flex align-items-center"}
                                                  onClick={() => handleRetraitCandidature(candidature.annonce.id)}>
                                                <span className={"fas fa-minus-circle me-1 fs-4"}/> Retirer ma candidature
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <ul className="pagination d-flex justify-content-end align-items-center">
                        <div className={"text-dark"}>1-{candidatures.length} sur {candidatures.length} candidatures</div>
                        <li className="page-item disabled">
                            <a href="#" className="page-link ">
                                <i className="previous"></i>
                            </a>
                        </li>
                        <li className="page-item disabled">
                            <a href="#" className="page-link text-primary">1</a>
                        </li>
                        <li className="page-item disabled next">
                            <a href="#" className="page-link">
                                <i className="next"></i>
                            </a>
                        </li>
                    </ul>
                </>}
                {candidatures.length == 0 && <div className={"text-center p-4"}>
                    Vous n'avez pas de candidatures pour le moment ... <br/>
                    Veuillez accéder à nos offres d'emploi en utilsant le bouton ci-dessous: <br/><br/>
                   <div className="d-flex justify-content-center">
                       <Link to={"/offres"} className={"btn btn-sm btn-primary me-2"}>
                           <i className={"fas fa-suitcase"}/>Offres disponibles
                       </Link>
                       <button className={`btn btn-light-primary btn-sm ${loading && 'disabled'}`}
                               onClick={() => {
                                   setFilterText('')
                                   setLoading(true)
                                   setTimeout(() => {
                                       loadCandidatures()
                                   }, 2000)
                               }}><i className={"fas fa-sync pe-md-2 p-0"}/>
                           <span className={`d-md-inline d-none`}>Recharger mes candidatures</span>
                       </button>
                   </div>
                </div>}
            </>
            }
        </div>
    </div>

}

const CandidaturesWrapper: FC = () => {
    return <Candidatures/>
}

export {CandidaturesWrapper}
