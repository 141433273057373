import { useAuth } from "../../../../auth"
import { useIntl } from "react-intl"
import * as Yup from "yup"
import {
     API_GET_DEPARTEMENTS_FRANCE_URL,
     API_GET_PAYS_INDICATIFS_TEL_URL,
     API_GET_PAYS_URL,
     API_GET_REGIONS_FRANCE_URL,
     API_GET_VILLES_FRANCE_URL,
     CONST_CIVILITE_NON_RENSEIGNEE,
     CONST_CIVILITES_OPTIONS_FOR_SELECT2,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_DISPONIBILITE_DATE_EXACTE,
     CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_DEPARTEMENTAL,
     CONST_MOBILITE_OPTIONS_FOR_SELECT2,
     CONST_MOBILITE_PAR_VILLE,
     CONST_MOBILITE_REGIONAL,
     CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2,
     CONST_OUI_NON_FOR_SELECT2,
} from "../../../../../constants"

import React, { useState } from "react"
import { useFormik } from "formik"
import { ProfilDetailsBeforeDemandeEmbaucheModel } from "../../../core/_models"
import { removePhotoProfil, setInfoPerso, setPhotoProfil } from "../../../core/_requests"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { toAbsoluteUrl } from "../../../../../../_metronic/helpers"
import { getThumbnailDriveUrl, isNumeric } from "../../../../../helpers/helper-global"
import MySelectField from "../../../../../utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "../../../../../utils/fields/MySelectGetAllDataFromServer"
import MyInptuMaskField from "../../../../../utils/fields/MyInputMaskField"
import MyDateDayMonthYearField from "../../../../../utils/fields/MyDateDayMonthYearField"
import MySelectSearchDataFromServer from "../../../../../utils/fields/MySelectSearchDataFromServer"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import { ICandidatModel } from "../../../../../models"

const InfoPersoEditFormEspaceCandidat = () => {
     const { currentUser, setCurrentUser } = useAuth()
     const intl = useIntl()
     const MySwal = withReactContent(Swal)

     const profileDetailsSchema = Yup.object().shape(
          {
               civilite: Yup.string().nullable(),
               nom: Yup.string().required("Ce champ est requis."),
               prenom: Yup.string().required("Ce champ est requis."),
               tel: Yup.string()

                    .nullable()
                    .when("telIndicatifId", {
                         is: telIndicatifId => {
                              return !!telIndicatifId
                         },
                         then: schema => schema.nullable().required("Ce champ est requis"),
                    }),
               paysResidenceId: Yup.number().nullable(),
               telIndicatifId: Yup.number()
                    .nullable()
                    .when("tel", {
                         is: tel => {
                              return !!tel
                         },
                         then: schema => schema.required("Ce champ est requis"),
                    }),
               salaireNetMensuelSouhaite: Yup.number().typeError("Un nombre est requis").nullable(),
               disponibilite: Yup.string().nullable(),
               disponibiliteDateExacte: Yup.string()
                    .when("disponibilite", {
                         is: CONST_DISPONIBILITE_DATE_EXACTE,
                         then: schema => schema.required("Ce champ est obligatoire").typeError("Ce champ est requis"),
                    })
                    .nullable(),
               mobilite: Yup.string().nullable(),
               mobiliteRegions: Yup.array()
                    .when("mobilite", {
                         is: CONST_MOBILITE_REGIONAL,
                         then: schema => schema.required("Ce champ est obligatoire").typeError("Ce champ est requis"),
                    })
                    .nullable(),
               mobiliteDepartements: Yup.array()
                    .when("mobilite", {
                         is: CONST_MOBILITE_DEPARTEMENTAL,
                         then: schema => schema.required("Ce champ est obligatoire").typeError("Ce champ est requis"),
                    })
                    .nullable(),
               mobiliteVilles: Yup.array()
                    .when("mobilite", {
                         is: CONST_MOBILITE_PAR_VILLE,
                         then: schema => schema.required("Ce champ est obligatoire").typeError("Ce champ est requis"),
                    })
                    .nullable(),
               habilitableDefense: Yup.boolean().nullable(),
               dejaHabiliteDefense: Yup.boolean()
                    .when("habilitableDefense", {
                         is: true,
                         then: schema => schema.required("Ce champ est requis").typeError("Ce champ est requis"),
                    })
                    .nullable(),
               dateFinHabilitation: Yup.string()
                    .when("dejaHabiliteDefense", {
                         is: true,
                         then: schema => schema.required("Ce champ est requis").typeError("Ce champ est requis"),
                    })
                    .nullable(),
          },
          [["tel", "telIndicatifId"]]
     )

     const [loading, setLoading] = useState(false)
     const formik = useFormik<ProfilDetailsBeforeDemandeEmbaucheModel>({
          initialValues: {
               civilite: currentUser?.candidat?.candidat!.civilite || null,
               prenom: currentUser?.candidat?.candidat!.prenom || "",
               nom: currentUser?.candidat?.candidat!.nom || "",
               telIndicatifId: currentUser?.candidat?.candidat!.telIndicatif?.id || null,
               tel: currentUser?.candidat?.candidat!.tel || null,
               dateNaissance: currentUser?.candidat?.candidat?.dateNaissance?.format("DD/MM/YYYY") || null,
               paysResidenceId: currentUser?.candidat?.candidat?.pays?.id || null,
               niveauEtudes: currentUser?.candidat?.candidat!.niveauEtudes || null,
               disponibilite: currentUser?.candidat?.candidat!.disponibilite || null,
               disponibiliteDateExacte: currentUser?.candidat?.candidat?.disponibiliteDate?.format("DD/MM/YYYY") || null,
               mobilite: currentUser?.candidat?.candidat!.mobilite || null,
               mobiliteRegions: currentUser?.candidat?.candidat?.regions?.map(item => item.id) || null,
               mobiliteDepartements: currentUser?.candidat?.candidat?.departements?.map(item => item.id) || null,
               mobiliteVilles: currentUser?.candidat?.candidat?.villes?.map(item => item.id) || null,
               salaireNetMensuelSouhaite: currentUser?.candidat?.candidat!.salaireNetSouhaite || null,
               habilitableDefense: currentUser?.candidat?.candidat!.isHabilitableDefense || null,
               dejaHabiliteDefense: currentUser?.candidat?.candidat!.isDejaHabiliteDefense || null,
               dateFinHabilitation: currentUser?.candidat?.candidat?.dateFinHabilitationDefense?.format("DD/MM/YYYY") || null,
          },
          validationSchema: profileDetailsSchema,
          onSubmit: (values, { setStatus }) => {
               setLoading(true)
               setStatus(null)
               // @ts-ignore
               const salaire = values.salaireNetMensuelSouhaite === "" ? null : values.salaireNetMensuelSouhaite
               const payload: ProfilDetailsBeforeDemandeEmbaucheModel = {
                    ...values,
                    tel: values.tel === "" ? null : values.tel,
                    dateNaissance: values.dateNaissance === "" ? null : values.dateNaissance,
                    salaireNetMensuelSouhaite: salaire,
                    disponibiliteDateExacte: values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE ? values.disponibiliteDateExacte : null,
                    mobiliteRegions: values.mobilite === CONST_MOBILITE_REGIONAL ? values.mobiliteRegions : null,
                    mobiliteDepartements: values.mobilite === CONST_MOBILITE_DEPARTEMENTAL ? values.mobiliteDepartements : null,
                    mobiliteVilles: values.mobilite === CONST_MOBILITE_PAR_VILLE ? values.mobiliteVilles : null,
                    dejaHabiliteDefense: values.habilitableDefense ? values.dejaHabiliteDefense : null,
                    dateFinHabilitation: values.habilitableDefense && values.dejaHabiliteDefense ? values.dateFinHabilitation : null,
               }

               setInfoPerso(payload)
                    .then(r => {
                         const candidat = r.data as ICandidatModel
                         setCurrentUser(prev => {
                              if (prev?.candidat?.candidat) {
                                   prev = {
                                        ...prev,
                                        candidat: { ...prev.candidat, candidat: { ...prev.candidat.candidat, ...candidat } },
                                   }
                              }
                              return prev
                         })
                         toast.success("Vos informations ont bien été mises à jour.", CONST_DEFAULT_TOAST_OPTIONS)
                    })
                    .catch((e: AxiosError) => {
                         const message = e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                         setStatus(message)
                         toast.error(message, { ...CONST_DEFAULT_TOAST_OPTIONS, autoClose: false })
                         setLoading(false)
                    })
                    .finally(() => {
                         setLoading(false)
                    })
          },
     })

     function handleSetPhotoProfil(e) {
          const fileBinary = e.target.files[0]

          if (fileBinary) {
               toast.info(currentUser?.user?.avatarGoogleDriveId ? "Changement de votre avatar en cours ..." : "Ajout de votre avatar en cours ...", { autoClose: false })
               setPhotoProfil(fileBinary)
                    .then(r => {
                         setCurrentUser(prev => (prev ? { ...prev, user: { ...prev.user, avatarGoogleDriveId: r.data.id } } : prev))
                         toast.dismiss()
                         toast.success("Votre nouveau avatar a bien été pris en compte.")
                    })
                    .catch(e => {
                         toast.dismiss()
                         toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                    })
          }
     }

     function handleRemovePhotoProfil() {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer votre avatar?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return removePhotoProfil()
                         .then(() => {
                              setCurrentUser(prev => (prev ? { ...prev, user: { ...prev.user, avatarGoogleDriveId: null } } : prev))
                              toast.dismiss()
                              toast.success("Votre avatar a bien été supprimé ...")
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     return (
          <form onSubmit={formik.handleSubmit} noValidate className="form">
               {formik.status && (
                    <div className="alert alert-danger">
                         <div className="alert-text font-weight-bold">{formik.status}</div>
                    </div>
               )}

               <div className="card-body px-0">
                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
                         <div className="col-lg-8">
                              <div
                                   className="image-input image-input-outline"
                                   data-kt-image-input="true"
                                   style={{ backgroundImage: `url(${toAbsoluteUrl("/media/avatars/blank.png")})` }}
                              >
                                   <div
                                        className="image-input-wrapper w-125px h-125px"
                                        style={{
                                             backgroundImage: `url(${
                                                  currentUser?.user?.avatarGoogleDriveId
                                                       ? getThumbnailDriveUrl(currentUser.user.avatarGoogleDriveId)
                                                       : toAbsoluteUrl("/media/avatars/blank.png")
                                             })`,
                                        }}
                                   ></div>

                                   <label
                                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                        data-kt-image-input-action="change"
                                        data-bs-original-title="Change avatar"
                                   >
                                        <i className="bi bi-pencil-fill fs-7"></i>
                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" onChange={e => handleSetPhotoProfil(e)} />
                                   </label>

                                   {currentUser?.user?.avatarGoogleDriveId && (
                                        <span
                                             className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                                             data-kt-image-input-action="remove"
                                             data-bs-original-title="Remove avatar"
                                             onClick={() => handleRemovePhotoProfil()}
                                        >
                                             <i className="bi bi-x fs-2"></i>
                                        </span>
                                   )}
                              </div>
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Civilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_CIVILITES_OPTIONS_FOR_SELECT2.filter(item => item.value !== CONST_CIVILITE_NON_RENSEIGNEE)}
                                   name={"civilite"}
                                   value={formik.values?.civilite}
                                   isSearchable={false}
                                   onChange={value => formik.setFieldValue("civilite", value)}
                                   isClearable={true}
                              />
                              {formik.touched.civilite && formik.errors.civilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.civilite}</div>
                                   </div>
                              )}
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label required fw-bold fs-6">Prénom NOM</label>

                         <div className="col-lg-8">
                              <div className="row">
                                   <div className="col-lg-6 fv-row">
                                        <input type="text" className="form-control form-control-lg mb-3 mb-lg-0" placeholder="Prénom" {...formik.getFieldProps("prenom")} />
                                        {formik.touched.prenom && formik.errors.prenom && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.prenom}</div>
                                             </div>
                                        )}
                                   </div>

                                   <div className="col-lg-6 fv-row">
                                        <input type="text" className="form-control form-control-lg " placeholder="NOM" {...formik.getFieldProps("nom")} />
                                        {formik.touched.nom && formik.errors.nom && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.nom}</div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Téléphone</label>

                         <div className="col-lg-8">
                              <div className="row">
                                   <div className="col-lg-6 mb-lg-0 mb-3 fv-row">
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_PAYS_INDICATIFS_TEL_URL}
                                             name={"telIndicatifId"}
                                             value={formik.values.telIndicatifId}
                                             defaultOptions={
                                                  currentUser?.candidat?.candidat?.telIndicatif
                                                       ? [
                                                              {
                                                                   label: currentUser.candidat.candidat.telIndicatif?.phoneCodeWithPlusPrefixAndCountry,
                                                                   value: currentUser.candidat.candidat.telIndicatif.id,
                                                              },
                                                         ]
                                                       : []
                                             }
                                             onChange={val => formik.setFieldValue("telIndicatifId", val)}
                                        />
                                        {formik.errors.telIndicatifId && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.telIndicatifId}</div>
                                             </div>
                                        )}
                                   </div>

                                   <div className="col-lg-6 fv-row">
                                        <MyInptuMaskField
                                             name={"tel"}
                                             mask={"99 999999999999999999"}
                                             value={formik.values.tel}
                                             placeholder={"Saisissez votre numéro"}
                                             inputMode={"numeric"}
                                             onChange={e => formik.setFieldValue("tel", e.target.value)}
                                             isInvalid={!!formik.errors.tel}
                                        />
                                        {formik.touched.tel && formik.errors.tel && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.tel}</div>
                                             </div>
                                        )}
                                   </div>
                              </div>
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">
                              <span>Date de naissance</span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <MyDateDayMonthYearField
                                   onChange={date => formik.setFieldValue("dateNaissance", date)}
                                   value={formik.values.dateNaissance}
                                   name={"dateNaissance"}
                                   placeholder={"Date de naissance"}
                              />
                              {formik.touched.dateNaissance && formik.errors.dateNaissance && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.dateNaissance}</div>
                                   </div>
                              )}
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">
                              <span>Pays de résidence</span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <MySelectGetAllDataFromServer
                                   url={API_GET_PAYS_URL}
                                   name={"pays"}
                                   value={formik.values.paysResidenceId}
                                   defaultOptions={
                                        currentUser?.candidat?.candidat?.pays
                                             ? [
                                                    {
                                                         label: currentUser?.candidat?.candidat!.pays!.nomFr,
                                                         value: currentUser?.candidat?.candidat!.pays!.id,
                                                    },
                                               ]
                                             : []
                                   }
                                   onChange={val => formik.setFieldValue("paysResidenceId", val)}
                              />
                              {formik.touched.paysResidenceId && formik.errors.paysResidenceId && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.paysResidenceId}</div>
                                   </div>
                              )}
                         </div>
                    </div>

                    <div className="separator my-10" />

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Niveau d'études</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2}
                                   name={"niveauEtudes"}
                                   value={formik.values.niveauEtudes}
                                   isSearchable={false}
                                   onChange={value => formik.setFieldValue("niveauEtudes", value)}
                              />
                              {formik.touched.niveauEtudes && formik.errors.niveauEtudes && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.niveauEtudes}</div>
                                   </div>
                              )}
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Disponibilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2}
                                   name={"disponibilite"}
                                   value={formik.values.disponibilite}
                                   isSearchable={false}
                                   onChange={value => formik.setFieldValue("disponibilite", value)}
                              />
                              {formik.touched.disponibilite && formik.errors.disponibilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.disponibilite}</div>
                                   </div>
                              )}
                              {formik.values.disponibilite === CONST_DISPONIBILITE_DATE_EXACTE && (
                                   <>
                                        <br />
                                        <MyDateDayMonthYearField
                                             onChange={date => formik.setFieldValue("disponibiliteDateExacte", date)}
                                             value={formik.values.disponibiliteDateExacte}
                                             name={"disponibiliteDateExacte"}
                                             placeholder={"Date de disponibilité"}
                                        />
                                        {formik.errors.disponibiliteDateExacte && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.disponibiliteDateExacte}</div>
                                             </div>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">Mobilité</label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_MOBILITE_OPTIONS_FOR_SELECT2}
                                   name={"mobilite"}
                                   value={formik.values.mobilite}
                                   isSearchable={false}
                                   onChange={value => formik.setFieldValue("mobilite", value)}
                              />
                              {formik.touched.mobilite && formik.errors.mobilite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.mobilite}</div>
                                   </div>
                              )}

                              {formik.values.mobilite === CONST_MOBILITE_REGIONAL && (
                                   <>
                                        <br />
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_REGIONS_FRANCE_URL}
                                             name={"mobiliteRegions"}
                                             defaultOptions={
                                                  currentUser?.candidat?.candidat?.regions
                                                       ? currentUser.candidat.candidat.regions.map(r => ({
                                                              label: r.nom,
                                                              value: r.id,
                                                         }))
                                                       : []
                                             }
                                             value={formik.values.mobiliteRegions}
                                             placeholder={"Choisissez les régions"}
                                             isMulti={true}
                                             onChange={val => formik.setFieldValue("mobiliteRegions", val)}
                                        />
                                        {formik.errors.mobiliteRegions && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.mobiliteRegions}</div>
                                             </div>
                                        )}
                                   </>
                              )}

                              {formik.values.mobilite === CONST_MOBILITE_DEPARTEMENTAL && (
                                   <>
                                        <br />
                                        <MySelectGetAllDataFromServer
                                             url={API_GET_DEPARTEMENTS_FRANCE_URL}
                                             name={"mobiliteDepartements"}
                                             placeholder={"Choisissez les départements"}
                                             defaultOptions={
                                                  currentUser?.candidat?.candidat?.departements
                                                       ? currentUser.candidat.candidat.departements.map(r => ({
                                                              label: r.nom,
                                                              value: r.id,
                                                         }))
                                                       : []
                                             }
                                             value={formik.values.mobiliteDepartements}
                                             isMulti={true}
                                             onChange={val => formik.setFieldValue("mobiliteDepartements", val)}
                                        />
                                        {formik.errors.mobiliteDepartements && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.mobiliteDepartements}</div>
                                             </div>
                                        )}
                                   </>
                              )}

                              {formik.values.mobilite === CONST_MOBILITE_PAR_VILLE && (
                                   <>
                                        <br />
                                        <MySelectSearchDataFromServer
                                             url={API_GET_VILLES_FRANCE_URL}
                                             name={"mobiliteVilles"}
                                             defaultOptions={
                                                  currentUser?.candidat?.candidat?.villes
                                                       ? currentUser.candidat.candidat.villes.map(r => ({
                                                              label: r.nom,
                                                              value: r.id,
                                                         }))
                                                       : []
                                             }
                                             value={formik.values.mobiliteVilles}
                                             placeholder={"Choisissez les villes"}
                                             isMulti={true}
                                             method={"GET"}
                                             onChange={val => formik.setFieldValue("mobiliteVilles", val)}
                                             minimumLengthSearch={1}
                                        />
                                        {formik.errors.mobiliteVilles && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.mobiliteVilles}</div>
                                             </div>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>

                    <div className="row mb-6">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">
                              <span>
                                   Salaire <b>NET MENSUEL</b> souhaité
                              </span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <input
                                   type="text"
                                   className="form-control form-control-lg "
                                   placeholder="Salaire net mensuel souhaité"
                                   inputMode={"numeric"}
                                   {...formik.getFieldProps("salaireNetMensuelSouhaite")}
                                   onChange={e => {
                                        formik.setFieldValue("salaireNetMensuelSouhaite", !isNumeric(e.target.value) ? "" : parseInt(e.target.value))
                                   }}
                              />
                              {formik.touched.salaireNetMensuelSouhaite && formik.errors.salaireNetMensuelSouhaite && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.salaireNetMensuelSouhaite}</div>
                                   </div>
                              )}
                         </div>
                    </div>

                    <div className="row">
                         <label className="col-lg-4 col-form-label fw-bold fs-6">
                              <span>Êtes-vous habilitable secret défense?</span>
                         </label>

                         <div className="col-lg-8 fv-row">
                              <MySelectField
                                   options={CONST_OUI_NON_FOR_SELECT2}
                                   name={"habilitableDefense"}
                                   value={formik.values.habilitableDefense}
                                   placeholder={"Habilitation Secret Défense"}
                                   isSearchable={false}
                                   onChange={val => formik.setFieldValue("habilitableDefense", val)}
                              />
                              {formik.touched.habilitableDefense && formik.errors.habilitableDefense && (
                                   <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">{formik.errors.habilitableDefense}</div>
                                   </div>
                              )}

                              {formik.values.habilitableDefense && (
                                   <>
                                        <br />
                                        <MySelectField
                                             options={CONST_OUI_NON_FOR_SELECT2}
                                             name={"dejaHabiliteDefense"}
                                             value={formik.values.dejaHabiliteDefense}
                                             isSearchable={false}
                                             onChange={val => formik.setFieldValue("dejaHabiliteDefense", val)}
                                             placeholder={"Avez-vous été habilité Secret Défense?"}
                                        />
                                        {formik.errors.dejaHabiliteDefense && (
                                             <div className="fv-plugins-message-container">
                                                  <div className="fv-help-block">{formik.errors.dejaHabiliteDefense}</div>
                                             </div>
                                        )}

                                        {formik.values.dejaHabiliteDefense && (
                                             <>
                                                  <br />
                                                  <MyDateDayMonthYearField
                                                       onChange={date => formik.setFieldValue("dateFinHabilitation", date)}
                                                       value={formik.values.dateFinHabilitation}
                                                       name={"dateFinHabilitation"}
                                                       placeholder={"Saisissez la date de fin d'habilitation"}
                                                  />
                                                  {formik.errors.dateFinHabilitation && (
                                                       <div className="fv-plugins-message-container">
                                                            <div className="fv-help-block">{formik.errors.dateFinHabilitation}</div>
                                                       </div>
                                                  )}
                                             </>
                                        )}
                                   </>
                              )}
                         </div>
                    </div>
               </div>

               <div className="d-flex justify-content-end">
                    <button type="submit" className="btn btn-sm btn-primary" disabled={loading}>
                         {!loading && "Enregister les modifications"}
                         {loading && (
                              <span className="indicator-progress" style={{ display: "block" }}>
                                   Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                         )}
                    </button>
               </div>
          </form>
     )
}

export default InfoPersoEditFormEspaceCandidat
