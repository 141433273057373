import { useAuth } from "../../auth"
import { CONST_DEFAULT_TOAST_OPTIONS } from "../../../constants"
import React, { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { getOffresFavoris, removeOffreFromFavoris } from "../core/_requests"
import { AxiosError } from "axios"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import { Link } from "react-router-dom"
import { OffreModel } from "../../../models"

export function Favoris() {
     const intl = useIntl()
     const MySwal = withReactContent(Swal)

     const { currentUser } = useAuth()
     const [loading, setLoading] = useState<boolean>(false)
     const [initialData, setInitialData] = useState<OffreModel[]>([])
     const [data, setData] = useState<OffreModel[]>([])
     const [filterText, setFilterText] = useState("")

     useEffect(() => {
          setLoading(true)
          loadOffres()
     }, [])

     function loadOffres() {
          getOffresFavoris()
               .then(r => {
                    setData(r.data)
                    setInitialData(r.data)
               })
               .finally(() => {
                    setLoading(false)
                    toast.dismiss()
               })
     }

     function handleRemoveOffreFromFavoris(id: number) {
          // return
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: () => {
                    return removeOffreFromFavoris(id)
                         .then(r => {
                              setData(r.data)
                              toast.dismiss()
                              toast.success("L'offre ne fait plus partie de vos favoris.", CONST_DEFAULT_TOAST_OPTIONS)
                         })
                         .catch((e: AxiosError) => {
                              toast.dismiss()
                              toast.error(e.response?.data?.detail || intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }), CONST_DEFAULT_TOAST_OPTIONS)
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     const handleKeyUpSearch = event => {
          if (event.key === "Enter") event.target.blur()
     }

     const handleFilterChangeSearch = (value: string) => {
          setFilterText(value)

          const filteredItems = value == "" ? initialData : initialData.filter(item => JSON.stringify(item).toLowerCase().includes(value))

          setData(filteredItems)
     }

     return (
          <div className="card mb-5 mb-xl-10">
               <div className="card-body  p-5 w-xxl-1200px">
                    {loading && (
                         <div className={"text-center p-4"}>
                              <span className="spinner-border mb-2"></span>
                              <p style={{ fontWeight: 500 }}>Chargement des favoris en cours ...</p>
                         </div>
                    )}

                    {!loading && data && data.length > 0 && (
                         <>
                              {/* recherche + refresh */}
                              <div className="d-flex justify-content-between mb-7">
                                   <div className="w-xxl-15 w-xl-20 w-lg-25 w-md-30 w-sm-35 w-75">
                                        <input
                                             type={"text"}
                                             className={"form-control form-control-sm"}
                                             onChange={e => handleFilterChangeSearch(e.target.value.toLowerCase())}
                                             placeholder={"Rechercher ..."}
                                             onKeyUp={handleKeyUpSearch}
                                             value={filterText}
                                        />
                                   </div>
                                   <div className="">
                                        <button
                                             className={`btn btn-primary btn-sm float-end ${loading && "disabled"}`}
                                             onClick={() => {
                                                  setFilterText("")
                                                  setLoading(true)
                                                  setTimeout(() => {
                                                       loadOffres()
                                                  }, 2000)
                                             }}
                                        >
                                             <i className={"fas fa-sync pe-md-2 p-0"} />
                                             <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                        </button>
                                   </div>
                              </div>

                              {/* Listing */}
                              <div className="row mb-4" style={{ maxHeight: "50vh", overflow: "scroll" }}>
                                   {data.map((offre, key) => (
                                        <div className="col-md-6 mb-9" key={key}>
                                             <div className="d-flex align-items-center">
                                                  <Link to={`/offres/${offre.id}/details?titre=${offre.intitule}`} className={"btn btn-active-light-dark btn-sm"}>
                                                       <span className={"d-none d-sm-block text-dark"}>Voir offre</span>
                                                       <i className={"d-sm-none d-block fas fa-eye p-0 text-dark fs-3"}></i>
                                                  </Link>
                                                  <div className={"border-start ps-3"}>
                                                       <Link
                                                            to={`/offres/${offre.id}/details?titre=${offre.intitule}`}
                                                            className={"text-dark fw-bolder text-hover-primary text-active-primary fs-6 d-block py-2"}
                                                       >
                                                            {offre.intitule}
                                                       </Link>
                                                       <span className="text-muted d-block fw-bold mb-1">
                                                            <span className="badge badge-light-primary me-1 mb-1">{offre.businessUnitMetier?.businessUnit?.nom}</span>
                                                            <span className="badge badge-light-warning text-dark me-1">{offre.ville?.nom}</span>
                                                            <span className="badge badge-light-danger">Publié le {offre.publishedAt?.format("DD/MM/YYYY")}</span>
                                                       </span>
                                                       <div className="text-dark d-block fw-bold">
                                                            <span
                                                                 className={"cursor-pointer text-hover-dark d-flex align-items-center"}
                                                                 onClick={() => handleRemoveOffreFromFavoris(offre.id)}
                                                                 style={{ color: "#c4151c" }}
                                                            >
                                                                 <i className={"fas fa-minus-circle me-2 fs-4"} style={{ color: "#c4151c" }} /> Supprimer des favoris
                                                            </span>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>
                                   ))}
                              </div>

                              {/* Pagination*/}
                              <ul className="pagination d-flex justify-content-end align-items-center">
                                   <div className={"text-dark"}>
                                        1-{data.length} sur {data.length} favoris
                                   </div>
                                   <li className="page-item disabled">
                                        <a href="#" className="page-link ">
                                             <i className="previous"></i>
                                        </a>
                                   </li>
                                   <li className="page-item disabled">
                                        <a href="#" className="page-link text-primary">
                                             1
                                        </a>
                                   </li>
                                   <li className="page-item disabled next">
                                        <a href="#" className="page-link">
                                             <i className="next"></i>
                                        </a>
                                   </li>
                              </ul>
                         </>
                    )}

                    {!loading && data.length == 0 && (
                         <div className={"text-center p-4"}>
                              Vous n'avez pas eu de coup de coeur pour le moment ... <br />
                              Veuillez accéder à nos offres d'emploi en utilsant le bouton ci-dessous: <br />
                              <br />
                              <div className="d-flex justify-content-center">
                                   <Link to={"/offres"} className={"btn btn-sm btn-primary me-2"}>
                                        <i className={"fas fa-suitcase"} />
                                        Offres disponibles
                                   </Link>
                                   <button
                                        className={`btn btn-light-primary btn-sm ${loading && "disabled"}`}
                                        onClick={() => {
                                             setFilterText("")
                                             setLoading(true)
                                             setTimeout(() => {
                                                  loadOffres()
                                             }, 2000)
                                        }}
                                   >
                                        <i className={"fas fa-sync pe-md-2 p-0"} />
                                        <span className={`d-md-inline d-none`}>Recharger mes favoris</span>
                                   </button>
                              </div>
                         </div>
                    )}
               </div>
          </div>
     )
}

