import { ConsultantNdfDetailModel, ConsultantNdfDocumentModel, IHttpErrorResponseModel } from "../../../../../../../../models"
import { toast } from "react-toastify"
import {
     API_URL_ESPACE_AVENTURIER,
     CONST_DEFAULT_TOAST_OPTIONS,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
     CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2,
} from "../../../../../../../../constants"
import { ndfDetailAddDocument, ndfDetailDocumentDelete, ndfDetailDocumentReplace, ndfDetailEditDepenseGrouped } from "../../../../../core/_request"
import axios, { AxiosError } from "axios"
import React, { useRef, useState } from "react"
import withReactContent from "sweetalert2-react-content"
import Swal from "sweetalert2"
import { useQueryClient } from "react-query"
import { useNdfUpdateDetailsContext } from "../NdfUpdateDetailsWrapper"
import MyTooltip from "../../../../../../../../utils/MyTooltip"
import * as Yup from "yup"
import { INdfDetailEditDepenseGroupedRequestModel } from "../../../../../core/_models"
import { ErrorMessage, FieldArray, Form, Formik, FormikHelpers } from "formik"
import MyAlert from "../../../../../../../../utils/MyAlert"
import MyDateDayMonthYearField from "../../../../../../../../utils/fields/MyDateDayMonthYearField"
import MySelectField from "../../../../../../../../utils/fields/MySelectField"
import MyInputField from "../../../../../../../../utils/fields/MyInputField"
import MySimpleSpinner from "../../../../../../../../utils/MySimpleSpinner"
import MySelectCreatableField from "../../../../../../../../utils/fields/MySelectCreatableField"
import MyTextareaField from "../../../../../../../../utils/fields/MyTextareaField"

const NdfUpdateDetailsEditDepenseGrouped = ({ detail }: INdfUpdateDetailsEditDepenseGroupedPropsModel) => {
     const queryClient = useQueryClient()
     const context = useNdfUpdateDetailsContext()
     const [docToBeReplaced, setDocToBeReplaced] = useState<ConsultantNdfDocumentModel>()
     const refReplaceDocInput = useRef<any>(null)
     const refAddDocInput = useRef<any>(null)
     const MySwal = withReactContent(Swal)

     const schema = Yup.object().shape({
          depenses: Yup.array().of(
               Yup.object().shape({
                    date: Yup.string().required("Champ requis").nullable(),
                    numeroTicket: Yup.number().required("Champ requis").typeError("Format incorrect.").nullable(),
                    categorie: Yup.string().required("Champ requis").nullable(),
                    montantTTC: Yup.number().required("Champ requis").typeError("Format incorrect.").nullable(),
                    description: Yup.string().nullable().required(),
                    repasSeulOuEnGroupe: Yup.string()
                         .label(`"Seul ou en groupe"`)
                         .when("categorie", {
                              is: CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
                              then: schema => schema.required(),
                         })
                         .nullable(),
                    invites: Yup.array()
                         .label(`"Invités"`)
                         .when("repasSeulOuEnGroupe", {
                              is: "REPAS_MIDI_EN_GROUPE",
                              then: schema => schema.required(),
                         })
                         .nullable(),
               })
          ),
     })

     const initialValues: INdfDetailEditDepenseGroupedRequestModel = {
          depenses: detail.enfants!.map(item => ({
               id: item.id as number,
               date: item.date!.format("DD/MM/YYYY") as string,
               categorie: item.categorie as string,
               montantTTC: item.montantTtc as number,
               description: item.description,
               numeroTicket: item.numeroTicket as string,
               repasSeulOuEnGroupe: item.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI ? (item.invites ? "REPAS_MIDI_EN_GROUPE" : "REPAS_MIDI_SEUL") : undefined,
               invites: item.invites || undefined,
          })),
     }

     function handleViewJustificatif(e, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Chargement du justificatif en cours ...", { autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "arraybuffer",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data], { type: mimeType }))
                    window.open(url)

                    toast.dismiss()
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     function handleAddDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Ajout en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               ndfDetailAddDocument(detail.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                              if (prev) {
                                   prev.map(item => {
                                        if (item.id === detail.id) {
                                             item.consultantNdfDocuments = [...(item.consultantNdfDocuments || []), r.data]
                                             return item
                                        }
                                   })
                              }

                              return prev
                         })

                         toast.dismiss()
                         toast.success("Le justificatif a bien été ajouté")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleReplaceDoc(event: any) {
          const file_binary = event.target.files[0]

          if (file_binary) {
               toast.info("Remplacement en cours ...", {
                    ...CONST_DEFAULT_TOAST_OPTIONS,
                    autoClose: false,
               })
               ndfDetailDocumentReplace(docToBeReplaced?.id as number, file_binary)
                    .then(r => {
                         queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                              if (prev) {
                                   prev.map(item => {
                                        if (item.id === detail.id) {
                                             item.consultantNdfDocuments?.map(item_ => {
                                                  if (item_.id === docToBeReplaced?.id) {
                                                       item_.googleDriveID = r.data.googleDriveID
                                                       item_.googleDriveMimeType = r.data.googleDriveMimeType
                                                       return item_
                                                  }
                                             })
                                        }
                                   })
                              }

                              return prev
                         })

                         toast.dismiss()
                         toast.success("Le justificatif a bien été remplacé")
                    })
                    .catch((e: AxiosError) => {
                         toast.dismiss()
                         toast.error(e.response?.data.detail, { autoClose: false })
                    })
          }
     }

     function handleRemoveDoc(id: number, index: number) {
          MySwal.fire({
               icon: "warning",
               title: `Confirmez-vous la suppression du justificatif n°${index}?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return ndfDetailDocumentDelete(id)
                         .then(() => {
                              queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                                   if (prev) {
                                        prev.map(item => {
                                             if (item.id === detail.id) {
                                                  item.consultantNdfDocuments = item.consultantNdfDocuments?.filter(item_ => item_.id !== id)
                                             }
                                        })
                                   }

                                   return prev
                              })
                              toast.success("Le justificatif a bien été supprimé.")
                         })
                         .catch(e => {
                              toast.error(e.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !MySwal.isLoading(),
          }).then()
     }

     function handleSubmit(values: INdfDetailEditDepenseGroupedRequestModel, helpers: FormikHelpers<INdfDetailEditDepenseGroupedRequestModel>) {
          helpers.setStatus(null)
          ndfDetailEditDepenseGrouped(detail.id as number, values)
               .then(r => {
                    queryClient.setQueryData<ConsultantNdfDetailModel[] | undefined>(["ndfDetails", context.ndfId], (prev: ConsultantNdfDetailModel[] | undefined) => {
                         if (prev) {
                              const index = prev.findIndex(item => item.id === detail.id)
                              if (index >= 0) {
                                   prev[index] = {
                                        ...prev[index],
                                        ...r.data,
                                   }
                              }
                         }

                         context.computeTotalExpenses(prev || [])

                         return prev
                    })

                    helpers.setSubmitting(false)
                    toast.success("Vos modifications ont bien été prises en compte.")
                    context.setCurrentDepenseInEdition(undefined)
               })
               .catch(e => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    if (error?.code === "REGULARATION_IS_REQUIRED") {
                         helpers.setStatus(
                              <>
                                   <p>{error?.detail}</p>
                                   <b className={"mb-2 fs-5"}>Merci de suivre les étapes suivantes:</b>
                                   <ul>
                                        <li>Accédez à la liste des déclarations.</li>
                                        <li>Demandez la régularisation de la déclaration du mois concerné.</li>
                                        <li>Attendez la validation de votre responsable (utilisez ce temps pour finaliser la déclaration du mois en cours).</li>
                                        <li>Une fois la demande validée, ajoutez votre dépense et vérifiez qu'il n'y a plus de ticket restaurant.</li>
                                        <li>Finaliser et signer votre déclaration.</li>
                                   </ul>
                              </>
                         )
                    } else {
                         helpers.setStatus(error?.detail)
                    }
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <>
               <div className={"p-4 border border-dashed mb-10"}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                         <h4>Justificatifs</h4>
                         <MyTooltip title={"Ajouter un justificatif"}>
                              <div className="btn btn-icon btn-sm btn-active-light-success" onClick={() => refAddDocInput.current.click()}>
                                   <i className={"fas fa-plus-circle fs-2 text-success"} />
                                   <input type="file" className={"d-none"} ref={refAddDocInput} onChange={handleAddDoc} />
                              </div>
                         </MyTooltip>
                    </div>
                    <div className="separator separator-dashed mb-6" />

                    {detail.consultantNdfDocuments?.map((item, key) => (
                         <div className={`d-flex align-items-center ${key > 0 && "mt-4"}`} key={key}>
                              <span className={"me-2"}>Justificatif n° {key + 1}: </span>
                              <button
                                   className={"btn btn-sm btn-primary p-0 px-2 py-1 d-flex align-items-center me-2"}
                                   onClick={e => handleViewJustificatif(e, item.googleDriveID as string, item.googleDriveMimeType as string)}
                              >
                                   Consulter <i className={"fas fa-eye ms-1"} />
                              </button>
                              <button
                                   className={"btn btn-sm btn-warning p-0 px-2 py-1 d-flex align-items-center me-2"}
                                   onClick={() => {
                                        setDocToBeReplaced(item)
                                        refReplaceDocInput.current.click()
                                   }}
                              >
                                   Remplacer <i className={"fas fa-sync ms-1"} />
                              </button>
                              <input type="file" className={"d-none"} ref={refReplaceDocInput} onChange={handleReplaceDoc} />
                              <button className={"btn btn-sm btn-danger p-0 px-2 py-1 d-flex align-items-center me-2"} onClick={() => handleRemoveDoc(item.id as number, key + 1)}>
                                   Supprimer <i className={"fas fa-trash ms-1"} />
                              </button>
                         </div>
                    ))}
               </div>

               <div className={"p-4 border border-dashed mb-10"}>
                    <div className="d-flex justify-content-between align-items-center mb-2">
                         <h4>Détails des dépenses</h4>
                    </div>
                    <div className="separator separator-dashed mb-6" />

                    <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                         {({ values, setFieldValue, isSubmitting, status, errors }) => (
                              <Form noValidate autoComplete="off">
                                   {status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {status}
                                        </MyAlert>
                                   )}
                                   <FieldArray name={"depenses"}>
                                        {({ push, remove }) => (
                                             <>
                                                  <div className={"row"}>
                                                       {values.depenses
                                                            .slice(0)
                                                            .reverse()
                                                            .map((item, index) => {
                                                                 return (
                                                                      <div className={"col-md-6 col-lg-4 mb-4"} key={index}>
                                                                           <div className={"mb-2"}>
                                                                                <MyDateDayMonthYearField
                                                                                     onChange={date => setFieldValue(`depenses[${index}].date`, date)}
                                                                                     value={values.depenses[index].date}
                                                                                     name={`depenses[${index}].date`}
                                                                                     placeholder={"Date"}
                                                                                />
                                                                                <ErrorMessage name={`depenses[${index}].date`}>
                                                                                     {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                </ErrorMessage>
                                                                           </div>
                                                                           <div className={"mb-2"}>
                                                                                <input
                                                                                     className={"form-control"}
                                                                                     name={`depenses[${index}].numeroTicket`}
                                                                                     placeholder={"Numéro que vous avez inscrit"}
                                                                                     autoComplete={"off"}
                                                                                     inputMode={"numeric"}
                                                                                     onChange={e => setFieldValue(`depenses[${index}].numeroTicket`, e.target.value)}
                                                                                     value={values.depenses[index].numeroTicket}
                                                                                />
                                                                                <ErrorMessage name={`depenses[${index}].numeroTicket`}>
                                                                                     {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                </ErrorMessage>
                                                                           </div>
                                                                           <div className={"mb-2"}>
                                                                                <MySelectField
                                                                                     options={CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2}
                                                                                     name={`depenses[${index}].categorie`}
                                                                                     isSearchable={false}
                                                                                     isClearable={false}
                                                                                     placeholder={"Catégorie"}
                                                                                     value={values.depenses[index].categorie}
                                                                                     onChange={value => setFieldValue(`depenses[${index}].categorie`, value)}
                                                                                />
                                                                                <ErrorMessage name={`depenses[${index}].categorie`}>
                                                                                     {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                </ErrorMessage>
                                                                           </div>
                                                                           <div className={"mb-2"}>
                                                                                <MyInputField
                                                                                     name={`depenses[${index}].montantTTC`}
                                                                                     value={values.depenses[index].montantTTC}
                                                                                     type={"number"}
                                                                                     inputMode={"numeric"}
                                                                                     placeholder={"Montant TTC"}
                                                                                     isInvalid={false}
                                                                                     onChange={val => setFieldValue(`depenses[${index}].montantTTC`, val)}
                                                                                />
                                                                                <ErrorMessage name={`depenses[${index}].montantTTC`}>
                                                                                     {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                </ErrorMessage>
                                                                           </div>
                                                                           {values.depenses[index].categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                                                <div className={"mb-2"}>
                                                                                     <div className={"col-12 mt-2"}>
                                                                                          <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                                               Veuillez indiquer s'il s'agit d'un repas seul ou en groupe
                                                                                          </MyAlert>
                                                                                          <MySelectField
                                                                                               name={`depenses[${index}].repasSeulOuEnGroupe`}
                                                                                               options={[
                                                                                                    {
                                                                                                         value: "REPAS_MIDI_SEUL",
                                                                                                         label: "Repas seul",
                                                                                                    },
                                                                                                    {
                                                                                                         value: "REPAS_MIDI_EN_GROUPE",
                                                                                                         label: "Repas en groupe",
                                                                                                    },
                                                                                               ]}
                                                                                               value={values.depenses[index].repasSeulOuEnGroupe}
                                                                                               onChange={val => setFieldValue(`depenses[${index}].repasSeulOuEnGroupe`, val)}
                                                                                               placeholder={"Choisissez si seul ou en groupe"}
                                                                                          />
                                                                                          <ErrorMessage name={`depenses[${index}].repasSeulOuEnGroupe`}>
                                                                                               {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                          </ErrorMessage>
                                                                                     </div>

                                                                                     {values.depenses[index].repasSeulOuEnGroupe === "REPAS_MIDI_EN_GROUPE" && (
                                                                                          <>
                                                                                               <div className={"col-12 mt-2"}>
                                                                                                    <MyAlert type={"primary"} classNames={"mb-2"}>
                                                                                                         Veuillez fournir les noms et prénoms de vos invités. S'il s'agit d'un
                                                                                                         client, ajoutez le nom de la société en plus.
                                                                                                    </MyAlert>
                                                                                                    <MySelectCreatableField
                                                                                                         name={`depenses[${index}].invites`}
                                                                                                         options={[]}
                                                                                                         value={values.depenses[index].invites}
                                                                                                         onChange={val => setFieldValue(`depenses[${index}].invites`, val)}
                                                                                                         isInvalid={false}
                                                                                                         placeholder={"Saisissez les invités"}
                                                                                                    />
                                                                                                    <ErrorMessage name={`depenses[${index}].invites`}>
                                                                                                         {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                                    </ErrorMessage>
                                                                                               </div>
                                                                                          </>
                                                                                     )}
                                                                                </div>
                                                                           )}

                                                                           <div className={"col-12 mt-5 mb-2"}>
                                                                                <MyTextareaField
                                                                                     name={`depenses[${index}].description`}
                                                                                     value={values.depenses[index].description}
                                                                                     isInvalid={false}
                                                                                     rows={2}
                                                                                     onChange={val => setFieldValue(`depenses[${index}].description`, val)}
                                                                                     placeholder={"Description"}
                                                                                />
                                                                                <ErrorMessage name={`depenses[${index}].description`}>
                                                                                     {msg => <div className={"text-danger"}>{msg}</div>}
                                                                                </ErrorMessage>
                                                                           </div>

                                                                           <div className={"d-flex justify-md-content-between flex-column flex-md-row"}>
                                                                                {index > 0 && (
                                                                                     <button
                                                                                          className={`btn btn-light-danger btn-sm ${index > 0 && "me-2"}`}
                                                                                          type={"button"}
                                                                                          onClick={() => remove(index)}
                                                                                     >
                                                                                          <i className={"fas fa-minus-circle"} />
                                                                                          Supprimer
                                                                                     </button>
                                                                                )}

                                                                                {index == values.depenses.length - 1 && (
                                                                                     <button
                                                                                          className={`btn btn-light-primary btn-sm mt-2 mt-md-0`}
                                                                                          type={"button"}
                                                                                          onClick={() =>
                                                                                               push({
                                                                                                    date: undefined,
                                                                                                    description: undefined,
                                                                                                    numeroTicket: "",
                                                                                                    categorie: "",
                                                                                                    montantTTC: "",
                                                                                               })
                                                                                          }
                                                                                     >
                                                                                          <i className={"fas fa-plus-circle"} />
                                                                                          Ajouter un autre détail
                                                                                     </button>
                                                                                )}
                                                                           </div>
                                                                      </div>
                                                                 )
                                                            })}
                                                  </div>
                                             </>
                                        )}
                                   </FieldArray>

                                   <div className="text-center pt-6 align-items-center">
                                        <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={isSubmitting}>
                                             {!isSubmitting ? <>Appliquer le changement</> : <MySimpleSpinner size={"sm"} />}
                                        </button>
                                   </div>
                              </Form>
                         )}
                    </Formik>
               </div>
          </>
     )
}

interface INdfUpdateDetailsEditDepenseGroupedPropsModel {
     detail: ConsultantNdfDetailModel
}

export default NdfUpdateDetailsEditDepenseGrouped
