import React from "react"
import "../styles.scss"
import { ConsultantNdfModel } from "../../../../../../../models"
import MyAlert from "../../../../../../../utils/MyAlert"
import { NdfUpdateDetails } from "../../../ndf/NdfUpdate/NdfUpdateDetailsWrapper/NdfUpdateDetailsWrapper"
import { useDeclarationUpdate } from "../DeclarationUpdate"
import { toast } from "react-toastify"
import { useMutation, useQuery } from "react-query"
import MySimpleSpinner from "../../../../../../../utils/MySimpleSpinner"
import { AxiosError } from "axios"
import Footer from "./_Footer"
import { declarationActiveNdf, ndfCreate } from "../../../../core/_request"

const REACT_QUERY_KEY_GET_ACTIVE_NDF = "REACT_QUERY_KEY_GET_ACTIVE_NDF"
export const DeclarationUpdateNotesDeFrais = () => {
     const context = useDeclarationUpdate()

     const initNdfMutation = useMutation(({ mois, annee }: { mois: string; annee: string }) => ndfCreate(mois, annee))

     const activeNdfQuery = useQuery<ConsultantNdfModel, AxiosError>(REACT_QUERY_KEY_GET_ACTIVE_NDF, () =>
          declarationActiveNdf(context.data.declaration?.id as number).then(r => r.data)
     )

     // Init NDF if not created
     function handleInitNdf() {
          initNdfMutation
               .mutateAsync({ mois: context.data.declaration?.mois?.format("MM") as string, annee: context.data.declaration?.mois?.format("YYYY") as string })
               .then(() => {
                    activeNdfQuery.refetch().then()
               })
               .catch(e => {
                    toast.error(e.response?.data?.detail, {autoClose: false})
               })
     }

     return (
          <>
               {/* loader */}
               {activeNdfQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />{" "}
                    </div>
               )}

               {/* error */}
               {!activeNdfQuery.isFetching && activeNdfQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{activeNdfQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => activeNdfQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {!activeNdfQuery.isFetching && !activeNdfQuery.isError && (
                    <>
                         {!activeNdfQuery.data && (
                              <>
                                   {context.data.declaration?.modifiable && (
                                        <div className={"text-center"}>
                                             <h5 className={"mb-2"}>Avez-vous une note de frais à déclarer?</h5>
                                             <button type={"button"} className={"btn btn-sm btn-primary me-4"} onClick={() => handleInitNdf()} disabled={initNdfMutation.isLoading}>
                                                  {!initNdfMutation.isLoading ? "OUI" : <MySimpleSpinner size={"sm"} />}
                                             </button>
                                             <button
                                                  type={"button"}
                                                  className={"btn btn-sm btn-outline btn-outline-primary"}
                                                  onClick={() =>
                                                       context.setDeclarationStepHeader(context.enCoursDeRectificationEnabledDepuisRecapitulatif ? "recap" : "ticket_resto")
                                                  }
                                                  disabled={initNdfMutation.isLoading}
                                             >
                                                  NON
                                             </button>
                                        </div>
                                   )}

                                   {!context.data.declaration?.modifiable && <MyAlert type={"primary"}>Aucune NDF n'a été déclarée</MyAlert>}
                              </>
                         )}

                         {activeNdfQuery.data && (
                              <>
                                   <NdfUpdateDetails ndfId={activeNdfQuery.data.id} editionMode={context.data.declaration?.modifiable} />
                              </>
                         )}
                    </>
               )}

               <div className="separator separator-dashed my-6" />

               <Footer />
          </>
     )
}
