import {
    CONST_CIVILITE_MR,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS,
    CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT
} from "../../../../constants";

export function getInfoAccordingToSousCategorieAbsenceExceptionnelle(sousType: string, gender: string | null): string | null{
    switch (sousType){
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS:
            return '4 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT:
            return '1 jour maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT:
            if(gender == CONST_CIVILITE_MR){
                return '3 jours maximum + 4 jours obligatoire du congés paternité'
            }else{
                return '3 jours maximum'
            }
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS:
            return '22 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS:
            return '5 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS:
            return '3 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT:
            return '3 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE:
            return '3 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR:
            return '3 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS:
            return '3 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT:
            return '5 jours maximum'
        case CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT:
            return '2 jours maximum'
        default:
            return null
    }
}