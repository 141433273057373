import {createContext, FC, useContext,} from 'react'
import {WithChildren} from "../../../_metronic/helpers";

type AppPreemabaucheContextProps = {
}

const AppPreemabaucheContextPropsState = {
}

const AppPreemabaucheContext = createContext<AppPreemabaucheContextProps>(AppPreemabaucheContextPropsState)

const useAppPreemabauche = () => {
    return useContext(AppPreemabaucheContext)
}

const AppPreemabaucheProvider: FC<WithChildren> = ({children}) => {

    return (
        <AppPreemabaucheContext.Provider value={{}}>
            {children}
        </AppPreemabaucheContext.Provider>
    )
}


export {useAppPreemabauche, AppPreemabaucheProvider}
