import React from "react"
import { SidebarMenuItem } from "./SidebarMenuItem"
import { useAuth } from "../../../../../app/modules/auth"
import MyTooltip from "../../../../../app/utils/MyTooltip"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_USER_ROLE_CANDIDAT, CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE, CONST_USER_ROLE_CONSULTANT } from "../../../../../app/constants"
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub"

const SidebarMenuMain = () => {
     const { currentUser, infoPersonellesNecessairesToEmbaucheToBeFilled, documentsNecessairesToEmbaucheToBeFilled } = useAuth()

     return (
          <>
               <SidebarMenuItem to="/mon-compte/profil" icon={"/media/icons/duotune/communication/com013.svg"} title={"Mon profil"} />

               {/* Uniquement pour l'espace candidat & préembauche*/}
               {(currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE)) && (
                    <>
                         <SidebarMenuItem to="/offres" icon={"/media/icons/duotune/finance/fin006.svg"} title={"Offres"} />
                         <SidebarMenuItem to="/mes-candidatures" icon={"/media/icons/duotune/general/gen016.svg"} title={"Mes candidatures"} />
                    </>
               )}

               <SidebarMenuItem to="/mes-docos" icon={"/media/icons/duotune/abstract/abs027.svg"} title={"Mes Dossiers de Compétences"} />

               {(currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) || currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE)) && (
                    <SidebarMenuItem to="mon-compte/mes-documents" icon={"/media/icons/duotune/files/fil012.svg"} title={"Mes documents"} />
               )}

               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && (
                    <>
                         <span>
                              <SidebarMenuItem
                                   to="/info-a-completer"
                                   icon={
                                        infoPersonellesNecessairesToEmbaucheToBeFilled.length > 0 || documentsNecessairesToEmbaucheToBeFilled.length > 0
                                             ? "/media/icons/duotune/general/gen044.svg"
                                             : "/media/icons/duotune/general/gen043.svg"
                                   }
                                   title={"Dossier de recrutement"}
                                   iconColor={
                                        infoPersonellesNecessairesToEmbaucheToBeFilled.length > 0 || documentsNecessairesToEmbaucheToBeFilled.length > 0
                                             ? "text-warning"
                                             : "text-success"
                                   }
                              />
                         </span>
                    </>
               )}

               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant?.contrat != CONST_CONSULTANT_CONTRAT_ST && (
                    <>
                         <SidebarMenuItemWithSub title={"Mes activités"} to={"#"} icon={"/media/icons/duotune/files/fil002.svg"}>
                              <SidebarMenuItem to="activites/declarations" title={"Déclarations mensuelles"} fontIcon={"fas fa-list-check"} />

                              {/*<MyTooltip title={"Mes Comptes Rendu d'Activité"} placement={"right"}>
                                <span>
                                    <SidebarMenuItem
                                        to='activites/cra'
                                        fontIcon={'fas fa-calendar-days'}
                                        title={"Mes CRA"}
                                    />
                                </span>
                                </MyTooltip>*/}

                              <MyTooltip title={"Mes Demandes De Congés"} placement={"right"}>
                                   <span>
                                        <SidebarMenuItem to="activites/ddc" fontIcon={"fas fa-umbrella-beach"} title={"Mes DDC"} />
                                   </span>
                              </MyTooltip>

                              <MyTooltip title={"Maladie"} placement={"right"}>
                                   <span>
                                        <SidebarMenuItem to="activites/maladie" fontIcon={"fas fa-file-medical"} title={"Mes arrêts maladie"} />
                                   </span>
                              </MyTooltip>

                              <MyTooltip title={"Mes Notes de Frais"} placement={"right"}>
                                   <span>
                                        <SidebarMenuItem to="activites/ndf" fontIcon={"fas fa-file-invoice-dollar"} title={"Mes NDF"} />
                                   </span>
                              </MyTooltip>
                         </SidebarMenuItemWithSub>
                    </>
               )}
          </>
     )
}

export { SidebarMenuMain }
