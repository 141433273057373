/* eslint-disable jsx-a11y/anchor-is-valid  */
import React, {useState} from "react"
import * as Yup from "yup"
import clsx from "clsx"
import {Link} from "react-router-dom"
import {useFormik} from "formik"
import {getUserByToken, login} from "../core/_requests"
import {KTSVG, toAbsoluteUrl} from "../../../../_metronic/helpers"
import {useAuth} from "../core/Auth"
import {AxiosError, AxiosResponse} from "axios"
import {useIntl} from "react-intl"
import MyTooltip from "../../../utils/MyTooltip"
import {toast} from "react-toastify"
import MyAlert from "../../../utils/MyAlert"

const loginSchema = Yup.object().shape({
     email: Yup.string().email("Mauvais format").min(3, "Minimum 3 caractères").max(50, "Maximum 50 caractères").required("Ce champ est requis"),
     password: Yup.string().min(3, "Minimum 3 caractères").max(50, "Maximum 50 caractères").required("Ce champ est requis"),
})

const auth2Schema = Yup.object().shape({
     code: Yup.string().required("Ce champ est requis"),
})

export function Login() {
     const urlParams = new URLSearchParams(window.location.search)
     const urlParamsSessionExpired = urlParams.get("session_expired")

     const intl = useIntl()
     const [loading2FA, setLoading2FA] = useState(false)
     const { saveAuth, setCurrentUser } = useAuth()
     const [is2FArequired, setIs2FArequired] = useState<boolean>(false)
     const [loadingLogin, setLoadingLogin] = useState<boolean>(false)
     const formik_login = useFormik({
         initialValues: {
             email: process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOGIN_EMAIL || "" : "",
             password: process.env.NODE_ENV === "development" ? process.env.REACT_APP_LOGIN_PWD || "" : "",
         },
          validationSchema: loginSchema,
          onSubmit: (values, { setStatus, setSubmitting }) => {
               setStatus(null)
               setLoadingLogin(true)
               login(values.email, values.password)
                    .then((r: AxiosResponse) => {
                         saveAuth(r.data)
                         getUserByToken(r.data.token)
                              .then((r: AxiosResponse) => {
                                   setCurrentUser(r.data)
                              })
                              .catch((e: AxiosError) => {
                                   if (e.response?.data?.detail) setStatus(e.response?.data?.detail)
                                   saveAuth(undefined)
                                   setLoadingLogin(false)
                              })
                              .finally(() => setSubmitting(false))
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 403) {
                              // It means that user activated 2FA
                              setIs2FArequired(true)
                         } else if (e.response?.status === 400) {
                              setStatus(e.response?.data?.detail)
                         } else if (e.response?.status === 401) {
                              setStatus("Identifiant ou mot de passe incorrect.")
                         } else {
                              setStatus(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
                         }
                         setLoadingLogin(false)
                         setSubmitting(false)
                    })
          },
     })

     const formik_2fa = useFormik<{ code: string }>({
          initialValues: {
               code: "",
          },
          validationSchema: auth2Schema,
          onSubmit: (values, { setStatus }) => {
               setLoading2FA(true)
               login(formik_login.values.email, formik_login.values.password, values.code)
                    .then((r: AxiosResponse) => {
                         saveAuth(r.data)
                         getUserByToken(r.data.token)
                              .then((r: AxiosResponse) => {
                                   setCurrentUser(r.data)
                              })
                              .catch((e: AxiosError) => {
                                   if (e.response?.data?.detail) setStatus(e.response?.data?.detail)
                                   saveAuth(undefined)
                                   setLoading2FA(false)
                              })
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.data?.detail) setStatus(e.response?.data?.detail)
                         saveAuth(undefined)
                         setLoading2FA(false)
                    })
          },
     })

     if (!is2FArequired) {
          return (
               <form className="form" onSubmit={formik_login.handleSubmit} noValidate>
                    {formik_login.status && (
                         <div className="alert alert-danger">
                              <div className="alert-text font-weight-bold">{formik_login.status}</div>
                         </div>
                    )}

                    {urlParamsSessionExpired !== null && (
                         <MyAlert type={"danger"} classNames={"mb-4"}>
                              Votre session a expiré. Veuillez vous reconnecter.
                         </MyAlert>
                    )}

                    {/* begin::Form group */}
                    <div className="fv-row mb-5">
                         <label className="form-label fs-6 fw-bolder text-black">Email</label>
                         <input
                              placeholder="Email"
                              {...formik_login.getFieldProps("email")}
                              className={clsx(
                                   "form-control bg-transparent",
                                   { "is-invalid": formik_login.touched.email && formik_login.errors.email },
                                   {
                                        "is-valid": formik_login.touched.email && !formik_login.errors.email,
                                   }
                              )}
                              type="email"
                              name="email"
                              autoComplete="off"
                         />
                         {formik_login.touched.email && formik_login.errors.email && (
                              <div className="fv-plugins-message-container">
                                   <span role="alert">{formik_login.errors.email}</span>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Form group */}
                    <div className="fv-row mb-3">
                         <label className="form-label fw-bolder text-black fs-6">Mot de passe</label>
                         <input
                              type="password"
                              autoComplete="off"
                              placeholder={"Mot de passe"}
                              {...formik_login.getFieldProps("password")}
                              className={clsx(
                                   "form-control bg-transparent",
                                   {
                                        "is-invalid": formik_login.touched.password && formik_login.errors.password,
                                   },
                                   {
                                        "is-valid": formik_login.touched.password && !formik_login.errors.password,
                                   }
                              )}
                         />
                         {formik_login.touched.password && formik_login.errors.password && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">
                                        <span role="alert">{formik_login.errors.password}</span>
                                   </div>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-5">
                         <div />

                         <Link to={`/auth/forgot-password`} state={{ email: formik_login.values.email }} className="link-primary">
                              Mot de passe oublié ?
                         </Link>
                    </div>

                    <div className="d-grid mb-5">
                         <button type="submit" id="kt_sign_in_submit" className="btn btn-primary" disabled={formik_login.isSubmitting}>
                              {!loadingLogin && <span className="indicator-label">Continuer</span>}
                              {loadingLogin && (
                                   <span className="indicator-progress" style={{ display: "block" }}>
                                        Connexion en cours <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                   </span>
                              )}
                         </button>
                    </div>

                    <div className="d-flex align-items-center mb-5">
                         <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                         <span className="fw-bold text-gray-400 fs-7 mx-2"> OU </span>
                         <div className="border-bottom border-gray-300 mw-50 w-100"></div>
                    </div>

                    <MyTooltip title={"Option temporairement désactivée. Veuillez utiliser le formulaire."}>
                         <button
                              type={"button"}
                              className="btn btn-flex flex-center btn-secondary btn-lg w-100 mb-5"
                              onClick={() => toast.info("Cette option est temporairement désactivée. Veuillez utiliser le formulaire.")}
                         >
                              <img alt="Logo" src={toAbsoluteUrl("/media/svg/brand-logos/google-icon.svg")} className="h-20px me-3" />
                              Continuer avec Google
                         </button>
                    </MyTooltip>

                    <div className="text-gray-500 text-center fw-semibold fs-6">
                         Êtes-vous un nouveau candidat?{" "}
                         <Link to="/auth/registration" className="link-primary">
                              Créer un compte
                         </Link>
                    </div>
               </form>
          )
     } else {
          return (
               <form className="form w-100" onSubmit={formik_2fa.handleSubmit} noValidate>
                    <div className="alert alert-success d-flex align-items-center p-5 mb-10">
                         <span className={"svg-icon svg-icon-2hx svg-icon-success me-3"}>
                              <KTSVG path="/media/icons/duotune/general/gen037.svg" className="svg-icon svg-icon-2hx svg-icon-success me-4" />
                         </span>
                         <div className="d-flex flex-column">
                              <h5 className="mb-1">Code 2FA requis</h5>
                              <span>Veuillez saisir le code à 6 chiffres que vous avez reçu par mail ...</span>
                         </div>
                    </div>

                    {formik_2fa.status && (
                         <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">{formik_2fa.status}</div>
                         </div>
                    )}

                    {/* begin::Form group */}
                    <div className="fv-row mb-3">
                         <label className="form-label fw-bolder text-black fs-6">Code</label>
                         <input
                              type="password"
                              autoComplete="off"
                              placeholder={"Saisissez le code ..."}
                              {...formik_2fa.getFieldProps("code")}
                              className={clsx(
                                   "form-control bg-transparent",
                                   {
                                        "is-invalid": formik_2fa.touched.code && formik_2fa.errors.code,
                                   },
                                   {
                                        "is-valid": formik_2fa.touched.code && !formik_2fa.errors.code,
                                   }
                              )}
                         />
                         {formik_2fa.touched.code && formik_2fa.errors.code && (
                              <div className="fv-plugins-message-container">
                                   <div className="fv-help-block">
                                        <span role="alert">{formik_2fa.errors.code}</span>
                                   </div>
                              </div>
                         )}
                    </div>
                    {/* end::Form group */}

                    {/* begin::Action */}
                    <div className="row pt-2">
                         <div className="col-6 d-grid">
                              <button className={"btn btn-light-danger"} onClick={() => setIs2FArequired(false)}>
                                   Annuler
                              </button>
                         </div>
                         <div className="col-6 d-grid">
                              <button type="submit" className="btn btn-primary" disabled={formik_2fa.isSubmitting || !formik_2fa.isValid}>
                                   {!loading2FA && <span className="indicator-label">Valider</span>}
                                   {loading2FA && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                             Vérification en cours ...
                                             <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                   )}
                              </button>
                         </div>
                    </div>

                    {/* end::Action */}
               </form>
          )
     }
}
