/* eslint-disable react/jsx-no-target-blank */
import {KTSVG} from '../../../helpers'
import MyTooltip from "../../../../app/utils/MyTooltip";

const SidebarFooter = () => {
    return (
        <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
           {/* <MyTooltip title={"Mes dossiers de compétences"}>
                <a
                    href={"https://google.com"}
                    target='_blank'
                    className='btn btn-flex flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
                    data-bs-toggle='tooltip'
                    data-bs-trigger='hover'
                    data-bs-dismiss-='click'
                    title='Metronic Docs & Components'
                >
                    <span className='btn-label'>Mes DoCos</span>
                    <KTSVG path='/media/icons/duotune/general/gen005.svg' className='btn-icon svg-icon-2 m-0'/>
                </a>
            </MyTooltip>*/}
        </div>
    )
}

export {SidebarFooter}
