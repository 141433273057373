import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import { CandidaturesWrapper } from "../pages/candidatures/CandidaturesWrapper"

const PrivateRoutes = () => {
     const location = useLocation()

     let prevUrl: any
     if (location.pathname.includes("auth") && localStorage.getItem("prevUrl")) {
          prevUrl = localStorage.getItem("prevUrl")
          localStorage.removeItem("prevUrl")
     }

     return (
          <Routes>
               <Route path="auth/*" element={<Navigate to={prevUrl || "/mes-candidatures"} />} />
               <Route index element={<CandidaturesWrapper />} />
          </Routes>
     )
}

export {PrivateRoutes}
