import React from 'react'
import {useAuth} from "../auth";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";

const OffresHeader: React.FC = () => {
    const location = useLocation()

    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-3 pb-2'>
                <div className='d-flex overflow-auto'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    ((location.pathname === '/offres/liste' || location.pathname.includes('details')) && 'active' )
                                }
                                to='/offres/liste'
                            >
                                Liste
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === '/offres/mes-favoris' && 'active')
                                }
                                to='/offres/mes-favoris'
                            >
                                Mes favoris
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export {OffresHeader}
