import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react"
import { KTSVG } from "../../../../../../../../_metronic/helpers"
import { ConsultantNdfDetailModel } from "../../../../../../../models"
import MyTooltip from "../../../../../../../utils/MyTooltip"
import { useQuery, UseQueryResult } from "react-query"
import { AxiosError } from "axios"
import { ndfGetDetails } from "../../../../core/_request"
import MyModal from "../../../../../../../utils/MyModal"
import { toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"
import { BrowserView, MobileView } from "react-device-detect"
import { NdfUpdateDetailsWrapperFormAddDetailGrouped } from "./NdfUpdateDetailsWrapperFormAddDetailGrouped"
import MyCard from "../../../../../../../utils/MyCard"
import { NdfUpdateDetailsWrapperListing } from "./NdfUpdateDetailsWrapperListing"
import { NdfUpdateDetailsWrapperFormAddDepenseSimple } from "./NdfUpdateDetailsWrapperFormAddDetailSimple"
import NdfUpdateDetailsEditDepenseSimple from "./edit/_NdfUpdateDetailsEditDepenseSimple"
import NdfUpdateDetailsEditDepenseGrouped from "./edit/_NdfUpdateDetailsEditDepenseGrouped"
import "./styles.scss"
import NdfUpdateDetailsWrapperNoticeUtilisation from "./NdfUpdateDetailsWrapperNoticeUtilisation"

const NdfUpdateDetailsContext = createContext<INdfUpdateDetailsContextPropsModel>({} as INdfUpdateDetailsContextPropsModel)
export const useNdfUpdateDetailsContext = () => useContext(NdfUpdateDetailsContext)
export const NdfUpdateDetails = ({ ndfId, editionMode = true }: { ndfId: number; editionMode?: boolean }) => {
     const [totalDepenses, setTotalDepenses] = useState<number>(0)

     const getNdfDetailsQuery = useQuery<ConsultantNdfDetailModel[], AxiosError>(["ndfDetails", ndfId], () => ndfGetDetails(ndfId).then(r => r.data))


     useEffect(() => {
          computeTotalExpenses(getNdfDetailsQuery.data || [])
     }, [getNdfDetailsQuery.data])

     function computeTotalExpenses(items: ConsultantNdfDetailModel[]) {
          let total = 0
          items.map(item => {
               if (item.isParent && item.enfants) {
                    item.enfants.map(enfant => {
                         total += enfant.montantTtc as number
                    })
               } else {
                    total += item.montantTtc as number
               }
          })
          setTotalDepenses(total)
     }


     // States
     const [isAddDetailsModalShown, setIsAddDetailsModalShown] = useState<boolean>(false)
     const [currentDepenseInEdition, setCurrentDepenseInEdition] = useState<ConsultantNdfDetailModel>()
     const [typeAddDetail, setTypeAddDetail] = useState<"simple" | "group" | null>(null)
     const [isCardDetailSimpleCollapsed, setIsCardDetailSimpleCollapsed] = useState<boolean>(false)
     const [isNoticeUtilisationShownInModal, setIsNoticeUtilisationShownInModal] = useState<boolean>(false)

     // Modal add depense
     const FormAddDetailsInModal = useMemo(() => {
          return (
               <MyModal
                    title={<span>Ajouter une dépense</span>}
                    show={isAddDetailsModalShown}
                    handleClose={() => {
                         setIsAddDetailsModalShown(false)
                         setTypeAddDetail(null)
                         setIsCardDetailSimpleCollapsed(false)
                    }}
                    fullscreen
               >
                    <>
                         <div className={`card`}>
                              <div className={`card-header cursor-pointer`} style={{ minHeight: "4rem" }} onClick={() => setIsCardDetailSimpleCollapsed(prev => !prev)}>
                                   <div className="card-title m-0">
                                        <h4 className="fw-bolder m-0">Choisissez le type de dépense</h4>
                                   </div>

                                   <MyTooltip title={!isCardDetailSimpleCollapsed ? "Cacher contenu" : "Afficher contenu"}>
                                        <div className={"align-self-center"}>
                                             <KTSVG
                                                  path={!isCardDetailSimpleCollapsed ? "/media/icons/duotune/arrows/arr073.svg" : "/media/icons/duotune/arrows/arr072.svg"}
                                                  className="svg-icon-1 svg-icon-3x"
                                             />
                                        </div>
                                   </MyTooltip>
                              </div>
                              <div className={`card-body ${isCardDetailSimpleCollapsed && "d-none"}`}>
                                   <div className="row">
                                        <div
                                             className="col-lg-6"
                                             onClick={() => {
                                                  setTypeAddDetail("simple")
                                                  setIsCardDetailSimpleCollapsed(true)
                                             }}
                                        >
                                             <label
                                                  className={`btn btn-outline btn-outline-dashed p-7 d-flex align-items-center mb-5 ${
                                                       typeAddDetail == "simple" ? "btn-outline-primary" : "btn-outline-default"
                                                  } btn-active-light-primary`}
                                             >
                                                  <span className="me-5">
                                                       <i className={"fas fa-file-invoice-dollar"} style={{ fontSize: "2rem" }} />
                                                  </span>
                                                  <span className="d-block fw-bold text-start">
                                                       <span className="text-dark fw-bolder d-block fs-4 mb-2">Dépense simple</span>
                                                       <span className="text-muted fw-bold fs-6">Permet d'ajouter une seule dépense</span>
                                                  </span>
                                             </label>
                                        </div>
                                        <div
                                             className="col-lg-6"
                                             onClick={() => {
                                                  setTypeAddDetail("group")
                                                  setIsCardDetailSimpleCollapsed(true)
                                             }}
                                        >
                                             <label
                                                  className={`btn btn-outline btn-outline-dashed p-7 d-flex align-items-center ${
                                                       typeAddDetail == "group" ? "btn-outline-primary" : "btn-outline-default"
                                                  } btn-active-light-primary`}
                                             >
                                                  <span className=" me-5">
                                                       <i className={"fas fa-layer-group"} style={{ fontSize: "2rem" }} />
                                                  </span>
                                                  <span className="d-block fw-bold text-start">
                                                       <span className="text-dark fw-bolder d-block fs-4 mb-2">Dépenses groupées</span>
                                                       <span className="text-muted fw-bold fs-6">Permet d'agréger plusieurs dépenses</span>
                                                  </span>
                                             </label>
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {typeAddDetail != null && (
                              <MyCard>
                                   <MyCard.Header>
                                        {typeAddDetail == "simple" && `Dépense simple`}
                                        {typeAddDetail == "group" && `Dépenses groupées`}
                                   </MyCard.Header>
                                   <MyCard.Body>
                                        {typeAddDetail == "group" && <NdfUpdateDetailsWrapperFormAddDetailGrouped />}
                                        {typeAddDetail == "simple" && <NdfUpdateDetailsWrapperFormAddDepenseSimple />}
                                   </MyCard.Body>
                              </MyCard>
                         )}
                    </>
               </MyModal>
          )
     }, [isAddDetailsModalShown, typeAddDetail, isCardDetailSimpleCollapsed])

     function closeAndReopenAddDetailModal() {
          setIsAddDetailsModalShown(false)
          setIsAddDetailsModalShown(true)
     }

     return (
          <>
               <NdfUpdateDetailsContext.Provider
                    value={{
                         setIsAddDetailsModalShown: setIsAddDetailsModalShown,
                         ndfId,
                         editionMode,
                         getNdfDetailsQuery,
                         closeAndReopenAddDetailModal,
                         setCurrentDepenseInEdition,
                         totalDepenses,
                         computeTotalExpenses
                    }}
               >
                    {/* EDITION MODE ONLY: didacticiel + recharger + ajouter*/}
                    {editionMode && (
                         <>
                              <div className="d-flex justify-content-between mb-7">
                                   <div className="w-xxl-15 w-xl-20 w-lg-25 w-md-30 w-sm-35 w-60">
                                        <button className={`btn btn-primary btn-sm d-flex `} onClick={() => setIsNoticeUtilisationShownInModal(true)}>
                                             <i className={"fas fa-file-alt fs-3 p-0 me-2"} />
                                             Notice d'utilisation
                                        </button>
                                   </div>
                                   <div>
                                        <button className={`btn btn-light-primary btn-sm me-2`} onClick={() => getNdfDetailsQuery.refetch()}>
                                             <i className={"fas fa-sync me-md-2 pe-0 fs-3"} />
                                             <span className={`d-md-inline d-none`}>Recharger la liste</span>
                                        </button>
                                        <button className={`btn btn-primary btn-sm`} onClick={() => setIsAddDetailsModalShown(true)}>
                                             <i className={"fas fa-plus-circle me-md-2 pe-0 fs-3"} />
                                             <span className={`d-md-inline d-none`}>Ajouter une dépense</span>
                                        </button>
                                   </div>
                              </div>
                              <div className="separator separator-dashed mb-4 mt-4" />
                         </>
                    )}

                    <NdfUpdateDetailsWrapperListing />

                    {isAddDetailsModalShown && FormAddDetailsInModal}
                    {currentDepenseInEdition && (
                         <MyModal title={<span>Edition de la dépense</span>} show={true} handleClose={() => setCurrentDepenseInEdition(undefined)} size={"lg"}>
                              <>
                                   {currentDepenseInEdition.isParent ? (
                                        <NdfUpdateDetailsEditDepenseGrouped detail={currentDepenseInEdition} />
                                   ) : (
                                        <NdfUpdateDetailsEditDepenseSimple detail={currentDepenseInEdition} />
                                   )}
                              </>
                         </MyModal>
                    )}

                    {isNoticeUtilisationShownInModal && (
                         <MyModal title={<>Notice d'utilisation</>} show={true} handleClose={() => setIsNoticeUtilisationShownInModal(false)} size={"lg"} noPadding>
                              <NdfUpdateDetailsWrapperNoticeUtilisation finaliserCallback={() => setIsNoticeUtilisationShownInModal(false)} />
                         </MyModal>
                    )}
               </NdfUpdateDetailsContext.Provider>
          </>
     )
}

export const NdfUpdateDetailsWrapper: React.FC<INdfUdpateDetailsWrapperPropsModel> = ({ setStepperStep, ndfId, editionMode = true }) => {
     const navigate = useNavigate()

     return (
          <div className={"mx-auto"} style={{ maxWidth: "1200px" }}>
               <NdfUpdateDetails ndfId={ndfId} editionMode={editionMode} />

               <div className="separator separator-dashed mb-4 mt-4 bg-primary"></div>

               {/* FOOTER */}
               <BrowserView>
                    <div className="d-flex flex-stack">
                         <div>
                              <Link to="/activites/ndf " className={`btn btn-sm btn-light-primary me-2`}>
                                   <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                              </Link>
                         </div>

                         {editionMode && (
                              <button
                                   type="button"
                                   className="btn btn-sm btn-primary"
                                   onClick={() => {
                                        toast.info("Note de frais finalisée. Vous pouvez reprendre son édition si besoin.")
                                        navigate("/activites/ndf")
                                   }}
                              >
                                   <span className={"me-2"}>Finaliser</span>
                                   <span className="fas fa-check-circle"></span>
                              </button>
                         )}
                    </div>
               </BrowserView>

               <MobileView>
                    <div className="d-flex flex-column">
                         <div className={"mb-sm-0 mb-3"}>
                              <button type="button" className="btn btn-sm btn-light-primary me-3 float-sm-none" onClick={() => setStepperStep("two")}>
                                   <span className="svg-icon me-1">
                                        <KTSVG path="/media/icons/duotune/arrows/arr063.svg" className="svg-icon-2" />
                                   </span>
                                   Dépenses
                              </button>

                              {editionMode && (
                                   <button
                                        type="button"
                                        className="btn btn-sm btn-primary float-end"
                                        onClick={() => {
                                             toast.info("Note de frais finalisée. Vous pouvez reprendre son édition si besoin.")
                                             navigate("/activites/ndf")
                                        }}
                                   >
                                        <span className={"me-2"}>Finaliser</span>
                                        <span className="fas fa-check-circle"></span>
                                   </button>
                              )}
                         </div>

                         <Link to="/activites/ndf " className={`btn btn-sm btn-light-primary`}>
                              <span className={"fas fa-list-dots fs-1 me-3 align-middle"} /> Retour à la liste des NDF
                         </Link>
                    </div>
               </MobileView>
          </div>
     )
}

interface INdfUdpateDetailsWrapperPropsModel {
     ndfId: number
     editionMode?: boolean
     setStepperStep: Dispatch<SetStateAction<"one" | "two">>
}

interface INdfUpdateDetailsContextPropsModel {
     closeAndReopenAddDetailModal: () => void
     setIsAddDetailsModalShown: Dispatch<SetStateAction<boolean>>
     setCurrentDepenseInEdition: Dispatch<SetStateAction<ConsultantNdfDetailModel | undefined>>
     ndfId: number
     editionMode: boolean
     getNdfDetailsQuery: UseQueryResult<ConsultantNdfDetailModel[], AxiosError>
     totalDepenses: number
     computeTotalExpenses: (items: ConsultantNdfDetailModel[]) => void
}
