import React, { useState } from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { useAuth } from "../../../../auth"
import { deleteAccountConfirmCode, deleteAccountGenerateCode } from "../../../core/_requests"
import { useIntl } from "react-intl"
import { Slide, toast } from "react-toastify"
import MyAlert from "../../../../../utils/MyAlert"
import { AxiosError } from "axios"

const deactivateAccountSchema = Yup.object().shape({
     confirm: Yup.boolean().oneOf([true], "Veuillez cocher cette case avant de supprimer votre compte"),
})

const deactivateAccountConfirmationSchema = Yup.object().shape({
    code: Yup.string().required('Ce champ est requis'),
    currentPassword: Yup.string().required('Ce champ est requis'),
})

const DeleteAccount: React.FC = () => {
    const intl = useIntl()
    const {currentUser, saveAuth, setCurrentUser} = useAuth()
    const [formDeactivateSubmitted, setFormDeactivateSubmitted] = useState<boolean>(false);
    const formikDeactivate = useFormik<{ confirm: boolean }>({
        initialValues: {
            confirm: false,
        },
        validationSchema: deactivateAccountSchema,
        onSubmit: (values, {setSubmitting, setStatus}) => {
            setStatus(null)
            deleteAccountGenerateCode()
                .then(() => {
                    setSubmitting(false)
                    setFormDeactivateSubmitted(true)
                })
                .catch(() => {
                    setStatus(intl.formatMessage({id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE"}))
                })
        },
    })
    const formikConfirmation = useFormik<{ code: string, currentPassword: string }>({
        initialValues: {
            currentPassword: '',
            code: '',
        },
        validationSchema: deactivateAccountConfirmationSchema,
        onSubmit: (values, {setSubmitting, setStatus}) => {
            setStatus(null)
            deleteAccountConfirmCode(values.currentPassword, values.code)
                .then(() => {
                    toast.success("Votre compte a bien été supprimé.", {
                        position: "top-right",
                        autoClose: 4000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        transition: Slide,
                        draggable: true,
                        theme: "light"
                    })
                    setSubmitting(false)
                    setCurrentUser(undefined)
                    saveAuth(undefined)
                })
                .catch((e: AxiosError) => {
                    const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" })
                    setStatus(error)
                    toast.error(error, { autoClose: false })
                    setSubmitting(false)
                })
        },
    })

    return (
        <div className='card'>
            <div className='card-header border-0 px-0'>
                <div className='card-title m-0'>
                    <h4 className='fw-bolder m-0'>Suppression du compte</h4>
                </div>
            </div>

            {!formDeactivateSubmitted &&
                <form onSubmit={formikDeactivate.handleSubmit} className='form'>
                    <div className='card-body border-top px-0'>
                        {formikDeactivate.values.confirm &&
                            <MyAlert type={"warning"} title={"Vous êtes sur le point de supprimer votre compte."} classNames={"mb-5"}>
                                <>
                                    Pour des raisons de sécurité, nous vous enverrons un mail avec le code de
                                    suppression.
                                    Une fois que vous aurez saisi le code, votre compte sera définitivement
                                    supprimé.
                                    <br/>
                                    <span className='text-primary cursor-pointer'>
                                        En savoir plus
                                    </span>
                                </>
                            </MyAlert>
                        }

                        <div className='form-check form-check-solid fv-row mb-4'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                {...formikDeactivate.getFieldProps('confirm')}
                            />
                            <label className='form-check-label fw-bold ps-2 fs-6' htmlFor='deactivate'>
                                Je souhaite supprimer mon compte
                            </label>
                        </div>
                        {formikDeactivate.touched.confirm && formikDeactivate.errors.confirm && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formikDeactivate.errors.confirm}</div>
                            </div>
                        )}
                    </div>

                    <div className='d-flex justify-content-end'>
                        <button
                            id='kt_account_deactivate_account_submit'
                            type='submit'
                            className='btn btn-danger btn-sm fw-bold'
                            disabled={formikDeactivate.isSubmitting}
                        >
                            {!formikDeactivate.isSubmitting && 'Supprimer mon compte'}
                            {formikDeactivate.isSubmitting && (
                                <span className='indicator-progress' style={{display: 'block'}}>
                                    Veuillez patienter...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </form>
            }
            {formDeactivateSubmitted &&
                <div className='card-body border-top p-9'>

                    <MyAlert type={"success"} title={"Un code de confirmation a été envoyé sur votre adresse mail."} classNames={"mb-5"}>
                        <span>Veuillez saisir votre mot de passe ainsi que le code envoyé sur l'adresse
                            "{currentUser?.candidat?.candidat?.email}" afin de confirmer
                            la suppression définitive de votre compte.</span>
                    </MyAlert>


                    <form onSubmit={formikConfirmation.handleSubmit} className='form' noValidate>

                        {formikConfirmation.status &&
                            <div className='alert alert-danger'>
                                <div className='alert-text font-weight-bold'>{formikConfirmation.status}</div>
                            </div>
                        }

                        <div className="row">
                            <div className="col-lg-4 mb-lg-0 mb-2">
                                <input
                                    type='password'
                                    className='form-control'
                                    placeholder={"Saisissez votre mot de passe"}
                                    {...formikConfirmation.getFieldProps('currentPassword')}
                                />
                                {formikConfirmation.touched.currentPassword && formikConfirmation.errors.currentPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div
                                            className='fv-help-block'>{formikConfirmation.errors.currentPassword}</div>
                                    </div>
                                )}
                            </div>

                            <div className="col-lg-4 mb-lg-0 mb-4">
                                <input
                                    type='password'
                                    className='form-control'
                                    placeholder={"Saisissez le code reçu par email"}
                                    {...formikConfirmation.getFieldProps('code')}
                                />
                                {formikConfirmation.touched.code && formikConfirmation.errors.code && (
                                    <div className='fv-plugins-message-container'>
                                        <div
                                            className='fv-help-block'>{formikConfirmation.errors.code}</div>
                                    </div>
                                )}
                            </div>

                            <div className="d-flex justify-content-lg-start justify-content-end col-lg-4">
                                <button
                                    type='submit'
                                    className='btn btn-primary me-2'
                                    disabled={formikConfirmation.isSubmitting}
                                >
                                    {!formikConfirmation.isSubmitting && 'Confirmer'}
                                    {formikConfirmation.isSubmitting && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Confirmation en cours...{' '}
                                            <span
                                                className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                                <button onClick={() => {
                                    setFormDeactivateSubmitted(false)
                                    formikDeactivate.setFieldValue('confirm', false)
                                }} type='button' className='btn btn-light-danger px-6'>
                                    Annuler
                                </button>
                            </div>
                        </div>
                    </form>

                </div>
            }

        </div>
    )
}

export {DeleteAccount}
