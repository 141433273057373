import axios from "axios"
import { InfoToFillBeforeApplyModel } from "./_models"
import { API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE } from "../../../constants"
import { CandidatureModel, OffreModel } from "../../../models"
import { AuthUserModel } from "../../auth"

const API_URL_OFFRES = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres`
const API_URL_OFFRES_FAVORIS = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres/favoris`
const API_URL_OFFRES_DETAILS = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres/details`
const API_URL_OFFRES_ADD_AMONG_FAVORIS = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres/add-among-favoris`
const API_URL_OFFRES_REMOVE_FROM_FAVORIS = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres/remove-from-favoris`
const API_URL_OFFRES_POSTULER = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres/postuler`
const API_URL_OFFRES_RETIRER_CANDIDATURE = `${API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE}/offres/retirer-candidature`


export function getOffres() {
    return axios.get<Array<OffreModel>>(API_URL_OFFRES)
}

export function getOffresFavoris() {
    return axios.get<Array<OffreModel>>(API_URL_OFFRES_FAVORIS)
}

export function getOffreDetails(id: number) {
    return axios.post<OffreModel>(API_URL_OFFRES_DETAILS, {
        id
    })
}

export function addOffreAmongFavoris(id: number) {
    return axios.post<OffreModel[]>(API_URL_OFFRES_ADD_AMONG_FAVORIS, {
        id
    })
}

export function removeOffreFromFavoris(id: number) {
    return axios.post<OffreModel[]>(API_URL_OFFRES_REMOVE_FROM_FAVORIS, {
        id
    })
}

export function postuler(id: number, candidatMissingInfo: InfoToFillBeforeApplyModel | null = null, curriculum: File | null = null) {
    let formData = new FormData();
    if (candidatMissingInfo) formData.append('candidatMissingInfo', JSON.stringify(candidatMissingInfo));
    if (curriculum) formData.append('curriculum', curriculum);
    formData.append('id', id.toString())

    return axios.post<{candidatures: CandidatureModel[], user: AuthUserModel|null}>(API_URL_OFFRES_POSTULER, formData)
}

export function retirerCandidature(offreId: number) {
    return axios.post<CandidatureModel[]>(API_URL_OFFRES_RETIRER_CANDIDATURE, {
        offreId
    })
}
