import {createContext, FC, useContext,} from 'react'
import {WithChildren} from "../../../_metronic/helpers";

type AppConsultantContextProps = {
}

const AppConsultantContextPropsState = {

}

const AppConsultantContext = createContext<AppConsultantContextProps>(AppConsultantContextPropsState)

const useAppConsultant = () => {
    return useContext(AppConsultantContext)
}

const AppConsultantProvider: FC<WithChildren> = ({children}) => {
    return (
        <AppConsultantContext.Provider value={{}}>
            {children}
        </AppConsultantContext.Provider>
    )
}


export {useAppConsultant, AppConsultantProvider}
