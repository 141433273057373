import { useLocation, useNavigate } from "react-router-dom"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { toast } from "react-toastify"
import MyAlert from "../../../../../../utils/MyAlert"
import { craDetails } from "../../../core/_request"
import { ConsultantCraModel } from "../../../../../../models"
import { useIntl } from "react-intl"
import "./styles.scss"
import { CraUpdateGrilleTempsWrapper } from "./CraUpdateGrilleTemps"
import { CraUpdateTicketsRestaurantWrapper } from "./CraUpdateTicketsRestaurant"

const urlParams = new URLSearchParams(window.location.search)

export function CraUpdate() {
    const navigate = useNavigate()
    const location_state = useLocation().state as { cra_id: string, default_step: "one" | "two" | "three" }

    const cra_id = location_state?.cra_id || urlParams.get('cra_id') as string
    const default_step = location_state?.default_step || urlParams.get('default_step') as string

    const [data, setData] = useState<{ loading: boolean, error: false | string, cra: ConsultantCraModel | null }>({loading: true, error: false, cra: null});
    const [stepperStep, setStepperStep] = useState<"one" | "two" | "three">(default_step || "two");

    const intl = useIntl()

    useEffect(() => {
        if (cra_id) {
            craDetails(parseInt(cra_id)).then(r => {
                setData(prev => ({...prev, loading: false, cra: r.data}))
            }).catch(e => {
                const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE"})
                toast.error(error, {autoClose: false})
                setData(prev => ({...prev, loading: false, error: error}))
            })
        } else {
            navigate(`/activites/cra/new`)
        }
    }, [])


    return <div className='card mb-5 mb-xl-10'>
        <div className='card-body p-2 p-lg-4 p-xl-6 p-xxl-9'>

            {data.loading && <div className={"text-center p-4"}>
                <span className='spinner-border mb-2'></span>
                <p style={{fontWeight: 500}}>Chargement en cours ...</p>
            </div>}

            {!data.loading && data.error && <MyAlert type={"danger"}>{data.error}</MyAlert>}

            {!data.loading && data.cra && <>
                <StepperHeaderForCra stepperStep={stepperStep} setStepperStep={setStepperStep}/>

                {stepperStep == "two" && <CraUpdateGrilleTempsWrapper data={data.cra}
                                                                      setData={cra => setData(prev => ({...prev, cra}))}
                                                                      setStepperStep={setStepperStep} editionMode={data.cra.modifiable}/>}
                {stepperStep == "three" && <CraUpdateTicketsRestaurantWrapper data={data.cra}
                                                                              setData={cra => setData(prev => ({...prev, cra}))}
                                                                              setStepperStep={setStepperStep} editionModeEnabled={data.cra.modifiable}/>}
            </>
            }

        </div>
    </div>
}

type StepperHeaderForCraProps = {
    stepperStep: "one" | "two" | "three"
    setStepperStep?: Dispatch<SetStateAction<"one" | "two" | "three">>

}


export const StepperHeaderForCra: React.FC<StepperHeaderForCraProps> = ({stepperStep, setStepperStep}) => {
    return <div className="stepper stepper-links mb-5">
        <div className="stepper-nav">
            {/* begin::Step 1 */}
            <div className={`stepper-item ${stepperStep == "one" && "current"} ${["two", "three"].includes(stepperStep) && "completed"}`}>
                <h3 className="stepper-title">
                    <span className={"d-none d-sm-block"}>1. Période</span>
                    <span className={"d-block d-sm-none fas fa-calendar-plus"} style={{fontSize: '2rem'}}></span>
                </h3>
            </div>
            {/* end::Step 1 */}
            {/* begin::Step 2 */}
            <div
                className={`stepper-item ${stepperStep == "two" && "current"} ${["one"].includes(stepperStep) && "pending"} ${["three"].includes(stepperStep) && "completed"} ${!["one", "two"].includes(stepperStep) && "cursor-pointer"}`}
                onClick={() => !["one", "two"].includes(stepperStep) && setStepperStep!("two")}
            >
                <h3 className="stepper-title">
                    <span className={"d-none d-sm-block"}>2. CRA</span>
                    <span className={"d-block d-sm-none fas fa-calendar-days"} style={{fontSize: '2rem'}}></span>
                </h3>
            </div>
            {/* end::Step 2 */}
            {/* begin::Step 3 */}
            <div className={`stepper-item ${stepperStep == "three" && "current"} ${["two"].includes(stepperStep) && "pending"} ${!["one", "three"].includes(stepperStep) && "cursor-pointer"}`}
                 onClick={() => !["one", "three"].includes(stepperStep) && setStepperStep!("three")}
            >
                <h3 className="stepper-title">
                    <span className={"d-none d-sm-block"}>3. Tickets restaurant</span>
                    <span className={"d-block d-sm-none fas fa-utensils"} style={{fontSize: '2rem'}}></span>
                </h3>
            </div>
            {/* end::Step 3 */}
        </div>
    </div>
}
