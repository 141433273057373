/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from "react"
import {Link} from "react-router-dom"
import {useAuth} from "../../../../app/modules/auth"
import {toAbsoluteUrl} from "../../../helpers"
import {getThumbnailDriveUrl} from "../../../../app/helpers/helper-global"
import {CONST_USER_ROLE_CANDIDAT} from "../../../../app/constants"

const HeaderUserMenu: FC = () => {
     const { currentUser, logout } = useAuth()

     return (
          <div className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px" data-kt-menu="true">
               <div className="menu-item px-3">
                    <div className="menu-content d-flex align-items-center px-3">
                         <div className="symbol symbol-50px me-5">
                              <img
                                   alt="Logo"
                                   src={currentUser?.user?.avatarGoogleDriveId ? getThumbnailDriveUrl(currentUser.user.avatarGoogleDriveId) : toAbsoluteUrl("/media/avatars/blank.png")}
                              />
                         </div>

                         <div className="d-flex flex-column">
                              <div className="fw-bolder d-flex align-items-center fs-5">
                                   {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && (
                                        <>
                                             {currentUser?.candidat?.candidat?.prenom} {currentUser?.candidat?.candidat?.nom}
                                        </>
                                   )}
                                   {!currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && (
                                        <>
                                             {currentUser?.consultant?.consultant?.prenom} {currentUser?.consultant?.consultant?.nom}
                                        </>
                                   )}
                              </div>
                         </div>
                    </div>
               </div>

               <div className="separator my-2"></div>

               <div className="menu-item px-5">
                    <Link to={"/mon-compte/profil"} className="menu-link px-5">
                         Mon profil
                    </Link>
               </div>

               <div className="separator my-2"></div>

               {/*<Languages/>*/}

               <div className="menu-item px-5 my-1">
                    <Link to="/mon-compte/settings" className="menu-link px-5">
                         Paramètres du compte
                    </Link>
               </div>

               <div className="menu-item px-5">
                    <a onClick={logout} className="menu-link px-5">
                         Déconnexion
                    </a>
               </div>
          </div>
     )
}

export { HeaderUserMenu }
