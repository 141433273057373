import { Link, useNavigate } from "react-router-dom"
import React from "react"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FormikStepOneValuesModel } from "../../../core/_models"
import moment from "moment"
import MySelectField from "../../../../../../utils/fields/MySelectField"
import { CONST_MOIS_LIST } from "../../../../../../constants"
import MyAlert from "../../../../../../utils/MyAlert"
import { declarationCreate, declarationGetDeclarationByDate } from "../../../core/_request"
import { toast } from "react-toastify"
import { useIntl } from "react-intl"
import { DeclarationStepHeader } from "../declarationUpdate/DeclarationUpdate"
import { FirstLetterUppercase } from "../../../../../../helpers/helper-global"

const urlParams = new URLSearchParams(window.location.search)

export function DeclarationCreate() {
    const intl = useIntl()
    const urlParamsMois = urlParams.get('mois') as string | null
    const urlParamsAnnee = urlParams.get('annee') as string | null
    const navigate = useNavigate()

    const formStepPeriodeValidationSchema = Yup.object().shape({
        mois: Yup.string().required("Le mois est requis."),
        annee: Yup.string().required("L'année est requise.")
    })

    const formikStepOne = useFormik<FormikStepOneValuesModel>({
        initialValues: {
            mois: urlParamsMois || moment().format('MM'),
            annee: urlParamsAnnee || moment().format('YYYY'),
        },
        validationSchema: formStepPeriodeValidationSchema,
        onSubmit: (values, {setStatus, setSubmitting}) => {
            setSubmitting(true)


            if (!CONST_MOIS_LIST.find(item => values.mois == item.value) || ![moment().subtract(1, 'year').format("YYYY"), moment().format('YYYY')].includes(values.annee)) {
                setStatus(`Vous ne pouvez pas soumettre pas soumettre une déclaration pour le mois de ${values.mois}/${values.annee}`)
                setSubmitting(false)
            } else {
                // Check si le CRA existe
                declarationGetDeclarationByDate(values.mois, values.annee).then(r => {
                    if (!r.data) {
                        declarationCreate(values.mois, values.annee).then(r => {
                            setSubmitting(false)
                            navigate(`/activites/declarations/update`, {
                                state: {
                                    declaration_id: r.data.id,
                                    breadCrumbPageTitle: `${FirstLetterUppercase(moment(`${values.annee}-${values.mois}-01`).format('MMMM YYYY'))}`
                                }
                            })
                        }).catch(e => {
                            const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE"})
                            setStatus(error)
                            toast.error(error, {autoClose: false})
                            setSubmitting(false)
                        })
                    } else {
                        navigate(`/activites/declarations/update`, {
                            state: {
                                declaration_id: r.data.id,
                                breadCrumbPageTitle: `${FirstLetterUppercase(moment(`${values.annee}-${values.mois}-01`).format('MMMM YYYY'))}`
                            }
                        })
                    }
                }).catch(e => {
                    const error = e.response?.data?.detail ? e.response.data.detail : intl.formatMessage({id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE"})
                    setStatus(error)
                    toast.error(error, {autoClose: false})
                    setSubmitting(false)
                })
            }
        },
    })


    return <div className='card mb-5 mb-xl-10'>
        <div className='card-body p-9'>
            <div className="text-center">
                <Link to="/activites/declarations " className={`btn btn-primary btn-primary mb-5`}>
                    Retour à la liste des déclarations <span className={"fas fa-list-dots fs-1 ms-3 align-middle"}/>
                </Link>
            </div>


            <DeclarationStepHeader stepperStep={"periode"}/>

            <form onSubmit={formikStepOne.handleSubmit} className={"mx-auto mw-1000px"}>

                {formikStepOne.status && <MyAlert type={"danger"} classNames={"mb-5"}>{formikStepOne.status}</MyAlert>}

                <h5 className={"mb-3"}>Choisissez une période</h5>
                <div className="row mb-5">
                    <div className='col-sm-6 mb-sm-0 mb-3'>
                        <MySelectField options={CONST_MOIS_LIST} name={"mois"} value={formikStepOne.values.mois} isSearchable={false} isClearable={false}
                                       onChange={value => formikStepOne.setFieldValue("mois", value)} placeholder={"Mois"}/>
                        {formikStepOne.errors.mois && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formikStepOne.errors.mois}</div>
                            </div>
                        )}
                    </div>
                    <div className='col-sm-6'>
                        <MySelectField options={[{label: moment().subtract(1, 'year').format("YYYY"), value: moment().subtract(1, 'year').format("YYYY")}, {
                            label: moment().format('YYYY'),
                            value: moment().format('YYYY')
                        }]} isSearchable={false} isClearable={false} name={"annee"} value={formikStepOne.values.annee} onChange={value => formikStepOne.setFieldValue("annee", value)} placeholder={"Année"}/>
                        {formikStepOne.errors.annee && (
                            <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>{formikStepOne.errors.annee}</div>
                            </div>
                        )}
                    </div>
                </div>

                {/* FOOTER */}
                <div className="d-flex flex-stack">
                    <Link to="/activites/declarations " className={`btn btn-sm btn-light-primary me-2`}>
                        <span className={"fas fa-list-dots fs-1 me-3 align-middle"}/> Retour aux déclarations
                    </Link>


                    <button type="submit" className="btn btn-md-lg btn-sm btn-primary" disabled={formikStepOne.isSubmitting}>
                        {!formikStepOne.isSubmitting && <>
                            Suivant <span className={"fas fa-arrow-right fs-3 ms-1 align-middle"}/>
                        </>}

                        {formikStepOne.isSubmitting && <span>Un instant ...</span>}
                    </button>
                </div>
            </form>

        </div>
    </div>
}
