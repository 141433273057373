import {PageLink, PageTitle} from "../../../../_metronic/layout/core";
import {Navigate, Outlet, Route, Routes, useLocation} from "react-router-dom";
import {NdfListe} from "./components/ndf/NdfListe";
import {DeclarationList} from "./components/declaration/DeclarationList";
import {DeclarationCreate} from "./components/declaration/declarationCreate/DeclarationCreate";
import {DeclarationUpdate} from "./components/declaration/declarationUpdate/DeclarationUpdate";
import {NdfCreate} from "./components/ndf/NdfCreate/NdfCreate";
import {NdfUpdate} from "./components/ndf/NdfUpdate/NdfUpdate";
import {DdcWrapper} from "./components/ddc/DdcListe";
import {CraList} from "./components/cra/CraList";
import {CraCreate} from "./components/cra/craCreate/CraCreate";
import {CraUpdate} from "./components/cra/craUpdate/CraUpdate";
import {MaladieWrapper} from "./components/maladie/MaladieListe";

const urlParams = new URLSearchParams(window.location.search);

export const ActivitesPage = () => {
    const location_state = useLocation().state as { breadCrumbPageTitle: string }
    const breadCrumbPageTitle = location_state?.breadCrumbPageTitle || urlParams.get('breadCrumbPageTitle') as string

    return <Routes>
        <Route element={
            <>
                <Outlet/>
            </>
        }>

            <Route path={'declarations'}>
                <Route
                    path=''
                    element={
                        <>
                            <PageTitle breadcrumbs={declarationsBreadCrumbs} />
                            <DeclarationList/>
                        </>
                    }
                />

                <Route
                    path='new'
                    element={
                        <>
                            <PageTitle breadcrumbs={declarationsBreadCrumbs}>Ajouter</PageTitle>
                            <DeclarationCreate/>
                        </>
                    }
                />

                <Route
                    path='update'
                    element={
                        <>
                            <PageTitle breadcrumbs={declarationsBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                            <DeclarationUpdate/>
                        </>
                    }
                />
            </Route>

            <Route path={'cra'}>
                <Route
                    path=''
                    element={
                        <>
                            <PageTitle breadcrumbs={craBreadCrumbs} />
                            <CraList/>
                        </>
                    }
                />

                <Route
                    path='new'
                    element={
                        <>
                            <PageTitle breadcrumbs={craBreadCrumbs}>Nouveau</PageTitle>
                            <CraCreate/>
                        </>
                    }
                />

                <Route
                    path='update'
                    element={
                        <>
                            <PageTitle breadcrumbs={craBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                            <CraUpdate/>
                        </>
                    }
                />
            </Route>


            <Route path={'ndf'}>
                <Route
                    path=''
                    element={
                        <>
                            <PageTitle breadcrumbs={ndfBreadCrumbs} />
                            <NdfListe/>
                        </>
                    }
                />

                <Route
                    path='new'
                    element={
                        <>
                            <PageTitle breadcrumbs={ndfBreadCrumbs}>Nouvelle note de frais</PageTitle>
                            <NdfCreate/>
                        </>
                    }
                />

                <Route
                    path='update'
                    element={
                        <>
                            <PageTitle breadcrumbs={ndfBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                            <NdfUpdate/>
                        </>
                    }
                />
            </Route>

            <Route path={'ddc'}>
                <Route
                    path=''
                    element={
                        <>
                            <PageTitle breadcrumbs={ddcBreadCrumbs} />
                            <DdcWrapper/>
                        </>
                    }
                />
            </Route>

            <Route path={'maladie'}>
                <Route
                    path=''
                    element={
                        <>
                            <PageTitle breadcrumbs={maladieBreadCrumbs} />
                            <MaladieWrapper/>
                        </>
                    }
                />
            </Route>


            <Route index element={<Navigate to='declarations/list'/>}/>

        </Route>
    </Routes>

}

const activiteBreadCrumbs: Array<PageLink> = [
    {
        title: "Activités",
        path: '#'
    }
]

const declarationsBreadCrumbs: Array<PageLink> = [
    ...activiteBreadCrumbs,
    {
        title: "Déclarations",
        path: '/activites/declarations'
    }
]
const craBreadCrumbs: Array<PageLink> = [
    ...activiteBreadCrumbs,
    {
        title: "Comptes rendus d'activité",
        path: '/activites/cra'
    }
]
const ndfBreadCrumbs: Array<PageLink> = [
    ...activiteBreadCrumbs,
    {
        title: "Notes de frais",
        path: '/activites/ndf'
    }
]
const ddcBreadCrumbs: Array<PageLink> = [
    ...activiteBreadCrumbs,
    {
        title: "Demandes de congés",
        path: '/activites/ddc'
    }
]

const maladieBreadCrumbs: Array<PageLink> = [
    ...activiteBreadCrumbs,
    {
        title: "Arrêts maladie",
        path: '/activites/maladie'
    }
]

export default ActivitesPage