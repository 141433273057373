import {
     CONST_MIME_APPLICATION_DOC,
     CONST_MIME_APPLICATION_DOCX,
     CONST_MIME_APPLICATION_GOOGLE_DOC,
     CONST_MIME_APPLICATION_GOOGLE_PRESENTATION,
     CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET,
     CONST_MIME_APPLICATION_ODP,
     CONST_MIME_APPLICATION_ODS,
     CONST_MIME_APPLICATION_ODT,
     CONST_MIME_APPLICATION_PDF,
     CONST_MIME_APPLICATION_PPT,
     CONST_MIME_APPLICATION_PPTX,
     CONST_MIME_APPLICATION_XLS,
     CONST_MIME_APPLICATION_XLSX,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
} from "../constants"

export function FirstLetterUppercase(string: string | undefined) {
     return string ? string[0].toUpperCase() + string.slice(1) : ""
}

export function getThumbnailDriveUrl(driveFileId) {
     return `https://drive.google.com/thumbnail?id=${driveFileId}`
}

export function isNumeric(str: string): boolean {
     if (str.trim() === "") {
          return false
     }

     return !Number.isNaN(Number(str))
}

export function sumTwoNumbers(one: number | null, two: number | null): number {
     if (one != null && two == null) return one
     if (one == null && two != null) return two

     if (one != null && two != null) {
          return one + two
     } else {
          return 0
     }
}

export function getExtensionAccordingToMimetype(mimetype: string) {
     const extensions = {
          [CONST_MIME_APPLICATION_DOCX]: "docx",
          [CONST_MIME_APPLICATION_DOC]: "doc",
          [CONST_MIME_APPLICATION_ODT]: "odt",
          [CONST_MIME_APPLICATION_GOOGLE_DOC]: "docx",
          [CONST_MIME_APPLICATION_PPT]: "ppt",
          [CONST_MIME_APPLICATION_PPTX]: "pptx",
          [CONST_MIME_APPLICATION_ODP]: "odp",
          [CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET]: "pptx",
          [CONST_MIME_IMAGE_JPEG]: "jpeg",
          [CONST_MIME_IMAGE_JPG]: "jpg",
          [CONST_MIME_IMAGE_PNG]: "png",
          [CONST_MIME_APPLICATION_XLS]: "xls",
          [CONST_MIME_APPLICATION_XLSX]: "xlsx",
          [CONST_MIME_APPLICATION_GOOGLE_PRESENTATION]: "xlsx",
          [CONST_MIME_APPLICATION_ODS]: "ods",
          [CONST_MIME_APPLICATION_PDF]: "pdf",
     }

     return extensions[mimetype]
}

export function randomId(length = 6) {
     return Math.random()
          .toString(36)
          .substring(2, length + 2)
}
