import * as Yup from "yup"
import React, { useState } from "react"
import { Form, Formik, FormikHelpers } from "formik"
import MyAlert from "../../utils/MyAlert"
import MySimpleSpinner from "../../utils/MySimpleSpinner"
import axios, { AxiosError } from "axios"
import { API_URL_ESPACE_AVENTURIER, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "../../constants"
import MyInputField from "../../utils/fields/MyInputField"
import { IHttpErrorResponseModel } from "../../models"
import { toast } from "react-toastify"
import { useCandidatureWithoutAuthContext } from "./CandidatureWithoutAuth"

const CandidatureWithoutAuthAutoSubmitedByJobboard = () => {
     const context = useCandidatureWithoutAuthContext()
     const [isCandidatureFinished, setIsCandidatureFinished] = useState<boolean>(false)

     const schema = Yup.object().shape({
          nom: Yup.string().label(`"Nom"`).required(),
          prenom: Yup.string().label(`"Prénom"`).required(),
          email: Yup.string().label(`"Email"`).required(),
          tel: Yup.string().label(`"Téléphone"`).required(),
          cv: Yup.mixed()
               .required()
               .label(`"CV"`)
               .test("fileSize", "Le CV doit avoir une taille inférieure ou égale à 10MB", (value: any) => {
                    return value && value.size <= 10000000
               })
               .test("fileFormat", "Le CV doit être au format PDF ou de type image", (value: any) => {
                    return value && ["application/pdf", "media/*"].includes(value.type)
               }),
     })

     const initialValues: ICandidatureModel = {
          referenceAnnonce: context.referenceAnnonce,
          jobboard: context.jobBoard,
     }

     function handleSubmit(values: ICandidatureModel, helpers: FormikHelpers<ICandidatureModel>) {
          helpers.setStatus(null)

          const formData = new FormData()
          for (const [key, value] of Object.entries(values)) {
               if (key === "referenceAnnonce" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "jobboard" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "nom" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "prenom" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "email" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "tel" && value !== null) formData.append(`data[${key}]`, value)
               if (key === "cv" && value !== null) formData.append(`cv`, value)
          }

          axios.post(`${API_URL_ESPACE_AVENTURIER}/candidature-sans-auth-auto-submited-by-jobboard`, formData)
               .then(() => {
                    setIsCandidatureFinished(true)
                    toast.success("Candidature envoyée.")
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     if (!isCandidatureFinished) {
          return (
               <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                    {helpers => {
                         console.log(helpers.errors)
                         return (
                              <Form noValidate autoComplete="off">
                                   {helpers.status && (
                                        <MyAlert type={"danger"} classNames={"mb-4"}>
                                             {helpers.status}
                                        </MyAlert>
                                   )}

                                   {/* Nom */}
                                   <div className="row mb-4">
                                        <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Nom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"nom"}
                                                  value={helpers.values.nom}
                                                  onChange={val => helpers.setFieldValue("nom", val)}
                                                  isInvalid={!!helpers.errors.nom}
                                                  placeholder={"Nom"}
                                                  className={"bg-transparent"}
                                             />
                                             {helpers.errors.nom && <div className={"text-danger"}>{helpers.errors.nom}</div>}
                                        </div>
                                   </div>

                                   {/* Prénom */}
                                   <div className="row mb-4">
                                        <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Prénom</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"prenom"}
                                                  value={helpers.values.prenom}
                                                  onChange={val => helpers.setFieldValue("prenom", val)}
                                                  isInvalid={!!helpers.errors.prenom}
                                                  placeholder={"Prénom"}
                                                  className={"bg-transparent"}
                                             />
                                             {helpers.errors.prenom && <div className={"text-danger"}>{helpers.errors.prenom}</div>}
                                        </div>
                                   </div>

                                   {/* Email */}
                                   <div className="row mb-4">
                                        <label className={`col-lg-4 col-form-label fw-bold fs-6 required`}>Email</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"email"}
                                                  value={helpers.values.email}
                                                  onChange={val => helpers.setFieldValue("email", val)}
                                                  isInvalid={!!helpers.errors.email}
                                                  placeholder={"Email"}
                                                  className={"bg-transparent"}
                                             />
                                             {helpers.errors.email && <div className={"text-danger"}>{helpers.errors.email}</div>}
                                        </div>
                                   </div>

                                   {/* Tél */}
                                   <div className="row mb-6">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Téléphone</label>

                                        <div className="col-lg-8">
                                             <MyInputField
                                                  name={"tel"}
                                                  value={helpers.values.tel}
                                                  onChange={val => helpers.setFieldValue("tel", val)}
                                                  isInvalid={!!helpers.errors.tel}
                                                  placeholder={"Tél."}
                                                  className={"bg-transparent"}
                                             />
                                             {helpers.errors.tel && <div className={"text-danger"}>{helpers.errors.tel}</div>}
                                        </div>
                                   </div>

                                   <div className="row mb-6">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">
                                             <span>Curriculum</span>
                                        </label>

                                        <div className="col-lg-8">
                                             <input
                                                  type="file"
                                                  className="form-control form-control-lg bg-transparent"
                                                  placeholder="Curriculum"
                                                  name={"cv"}
                                                  onChange={e => helpers.setFieldValue("cv", e.target.files && e.target.files.length > 0 ? e.target.files[0] : "")}
                                             />
                                             {helpers.errors.cv && (
                                                  <div className="fv-plugins-message-container">
                                                       <div className="fv-help-block">{helpers.errors.cv}</div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>

                                   {/* Submit button */}
                                   <div className={"d-flex justify-content-end"}>
                                        <button
                                             className={"btn btn-sm btn-primary d-inline-flex align-items-center justify-content-center mb-sm-0 mb-4"}
                                             type={"submit"}
                                             disabled={helpers.isSubmitting}
                                        >
                                             {!helpers.isSubmitting ? (
                                                  <span>
                                                       Valider <span className={"fas fa-paper-plane ms-1"} />
                                                  </span>
                                             ) : (
                                                  <MySimpleSpinner size={"sm"} />
                                             )}
                                        </button>
                                   </div>
                              </Form>
                         )
                    }}
               </Formik>
          )
     } else {
          return <MyAlert type={"success"}>Candidature envoyée.</MyAlert>
     }
}

interface ICandidatureModel {
     referenceAnnonce?: string
     nom?: string
     prenom?: string
     email?: string
     tel?: string
     jobboard?: string
     cv?: File
}

export default CandidatureWithoutAuthAutoSubmitedByJobboard
