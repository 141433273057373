import React from "react"
import { ConsultantNdfDetailModel } from "../../../../../../../models"
import MyAlert from "../../../../../../../utils/MyAlert"
import { useMutation, useQueryClient } from "react-query"
import { ndfDetailRemove } from "../../../../core/_request"
import { toast } from "react-toastify"
import { useNdfUpdateDetailsContext } from "./NdfUpdateDetailsWrapper"
import MyTooltip from "../../../../../../../utils/MyTooltip"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import axios, { AxiosError } from "axios"
import { API_URL_ESPACE_AVENTURIER, CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI } from "../../../../../../../constants"

export const NdfUpdateDetailsWrapperListing = () => {
     const MySwal = withReactContent(Swal)

     const context = useNdfUpdateDetailsContext()
     const queryClient = useQueryClient()

     // Query + mutation
     const deleteDetailsMutation = useMutation((id: number) => ndfDetailRemove(id))

     function handleDeleteDetails(id: number) {
          MySwal.fire({
               icon: "warning",
               text: `Êtes-vous sûr de vouloir supprimer cette dépense?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deleteDetailsMutation
                         .mutateAsync(id)
                         .then(() => {
                              queryClient.setQueryData(["ndfDetails", context.ndfId], (details: ConsultantNdfDetailModel[] | undefined) => {
                                   const dataLeft = details!.filter(item => item.id !== id)
                                   context.computeTotalExpenses(dataLeft)
                                   return dataLeft
                              })
                              toast.info("La dépense a bien été supprimée")
                         })
                         .catch(e => {
                              toast.error(e.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !Swal.isLoading(),
          }).then()
     }

     function handleViewJustificatif(e, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Chargement du justificatif en cours ...", { autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "arraybuffer",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data], { type: mimeType }))
                    window.open(url)

                    toast.dismiss()
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {!context.getNdfDetailsQuery.isFetching && context.getNdfDetailsQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{context.getNdfDetailsQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => context.getNdfDetailsQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {context.getNdfDetailsQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement des dépenses ...</p>
                    </div>
               )}

               {!context.getNdfDetailsQuery.isFetching && !context.getNdfDetailsQuery.isError && context.getNdfDetailsQuery.data && context.getNdfDetailsQuery.data.length == 0 && (
                    <MyAlert type={"primary"} classNames={"mb-4"}>
                         <div>Aucun justificatif de trouvé</div>
                    </MyAlert>
               )}

               {!context.getNdfDetailsQuery.isFetching && !context.getNdfDetailsQuery.isError && context.getNdfDetailsQuery.data && context.getNdfDetailsQuery.data.length > 0 && (
                    <>
                         <div className={"row p-3"}>
                              {context.getNdfDetailsQuery.data.map((item, key) => (
                                   <div className="card shadow-sm col-sm-6 col-lg-4 col-xl-3 mb-4 p-0 pe-sm-2" key={key}>
                                        <div className="card-header bg-info border border-info" style={{ minHeight: "2rem" }}>
                                             <h3 className="card-title text-white align-items-center">
                                                  <span className={"fas fa-file-invoice me-2"}></span>
                                                  {item.isParent ? `Dépenses groupées ( ${item.enfants?.length} )` : item.categorieLabel}
                                             </h3>
                                        </div>
                                        <div className="card-body border-info border">
                                             {!item.isParent && item.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                  <>
                                                       {!item.invites && (
                                                            <MyAlert type={"danger"} classNames={"mb-4"}>
                                                                 <span className={"fw-bold"}>Repas seul</span>
                                                            </MyAlert>
                                                       )}

                                                       {item.invites && (
                                                            <MyAlert type={"primary"} classNames={"mb-4"}>
                                                                 <span className={"fw-bold"}>Repas en groupe</span>
                                                            </MyAlert>
                                                       )}
                                                  </>
                                             )}

                                             <div className="d-flex justify-content-center">
                                                  {item.consultantNdfDocuments?.map((item_, key_) => (
                                                       <MyTooltip title={"Voir la pièce jointe"} key={key_}>
                                                            <div
                                                                 className={`mb-4 d-flex justify-content-center fas fa-file-invoice cursor-pointer ${key_ > 0 ? "ms-4" : ""}`}
                                                                 style={{ fontSize: "2rem" }}
                                                                 onClick={e => handleViewJustificatif(e, item_.googleDriveID as string, item_.googleDriveMimeType as string)}
                                                            />
                                                       </MyTooltip>
                                                  ))}
                                             </div>

                                             {/* Justificatif simple */}
                                             {!item.isParent && (
                                                  <div className={"mb-4"}>
                                                       <div className={"block"}>
                                                            <span className={"fw-bold"}>Date : </span> {item.date?.format("DD/MM/YYYY")}
                                                       </div>
                                                       {item.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && item.invites && (
                                                            <div className={"block"}>
                                                                 <span className={"fw-bold"}>Invités : </span> {item.invites.map(item => item).join(", ")}
                                                            </div>
                                                       )}
                                                       {item.description && (
                                                            <MyTooltip title={item.description}>
                                                                 <div className={"block d-flex align-items-center cursor-default text-hover-dark"}>
                                                                      <span className={"fw-bold"}>Description : </span> <i className={"fas fa-eye fs-5 ms-2"} />
                                                                 </div>
                                                            </MyTooltip>
                                                       )}
                                                  </div>
                                             )}

                                             {/* Justificatif groupée */}
                                             {item.isParent && (
                                                  <>
                                                       {item.enfants && item.enfants.length > 0 && (
                                                            <>
                                                                 {item.enfants.map((child, key) => (
                                                                      <div className={"mb-4"} key={key}>
                                                                           <div className={"block fs-5 border-bottom border-dark mb-1 fw-bold align-items-center"}>
                                                                                Ticket n°{child.numeroTicket}
                                                                           </div>
                                                                           {child.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && (
                                                                                <div className={"my-2"}>
                                                                                     {!child.invites && (
                                                                                          <MyAlert type={"danger"}>
                                                                                               <span className={"fw-bold"}>Repas seul</span>
                                                                                          </MyAlert>
                                                                                     )}

                                                                                     {child.invites && (
                                                                                          <MyAlert type={"primary"}>
                                                                                               <span className={"fw-bold"}>Repas en groupe</span>
                                                                                          </MyAlert>
                                                                                     )}
                                                                                </div>
                                                                           )}
                                                                           <div className={"block"}>
                                                                                <span className={"fw-bold"}>Date : </span> {child.date!.format("dddd DD MMMM YYYY")}
                                                                           </div>
                                                                           <div className={"block"}>
                                                                                <span className={"fw-bold"}>Catégorie : </span> {child.categorieLabel}
                                                                           </div>
                                                                           <div className={"block"}>
                                                                                <span className={"fw-bold"}>Montant TTC : </span> {child.montantTtc} €
                                                                           </div>
                                                                           {child.categorie === CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI && child.invites && (
                                                                                <div className={"block"}>
                                                                                     <span className={"fw-bold"}>Invités : </span> {child.invites?.map(item => item).join(", ")}
                                                                                </div>
                                                                           )}
                                                                           {child.description && (
                                                                                <MyTooltip title={child.description}>
                                                                                     <div className={"block d-flex align-items-center cursor-default text-hover-dark"}>
                                                                                          <span className={"fw-bold"}>Description : </span> <i className={"fas fa-eye fs-5 ms-2"} />
                                                                                     </div>
                                                                                </MyTooltip>
                                                                           )}
                                                                      </div>
                                                                 ))}
                                                            </>
                                                       )}
                                                  </>
                                             )}

                                             <div className="row border-top-dashed border-info pt-3">
                                                  <div className="col-6 p-0">
                                                       {context.editionMode && (
                                                            <>
                                                                 <button
                                                                      type="button"
                                                                      className="btn btn-sm btn-warning p-0 px-3 py-1 fs-4 me-3"
                                                                      onClick={() => context.setCurrentDepenseInEdition(item)}
                                                                 >
                                                                      <span className={"fas fa-edit"} />
                                                                 </button>
                                                                 <button
                                                                      type="button"
                                                                      className="btn btn-sm btn-danger p-0 px-3 py-1 fs-4"
                                                                      onClick={() => handleDeleteDetails(item.id as number)}
                                                                 >
                                                                      <span className={"fas fa-trash"} />
                                                                 </button>
                                                            </>
                                                       )}
                                                  </div>
                                                  <div className="col-6 fw-bold fs-5 d-flex align-items-center p-0 justify-content-end">
                                                       Total :{" "}
                                                       {item.isParent
                                                            ? item.enfants
                                                                 ? Object.values(item.enfants.map(child => child.montantTtc as number))
                                                                        .reduce((acc, val) => acc + val, 0)
                                                                        ?.toFixed(2)
                                                                 : 0
                                                            : item.montantTtc}{" "}
                                                       €
                                                  </div>
                                             </div>
                                        </div>
                                   </div>
                              ))}
                         </div>
                         {context.getNdfDetailsQuery.data.length > 1 && (
                              <div className="d-flex justify-content-end me-2">
                                   <div className={"alert alert-info px-2 py-1 text-dark fw-bold m-0"}>TOTAL: {context.totalDepenses.toFixed(2)} €</div>
                              </div>
                         )}
                    </>
               )}
          </>
     )
}
