import React from "react"

interface Props {
     name: string
     value: any
     isInvalid: boolean
     inputMode?: "text" | "numeric" | "tel" | "email"
     className?: string
     placeholder?: string
     onChange: (val) => void
     classNames?: string
     type?: "text" | "number"
}

const MyInputField: React.FC<Props> = ({ name, className = "", value, onChange, placeholder, inputMode = "text", isInvalid, classNames = "", type = "text" }) => {
    const val = (() => {
        if (value === undefined || value === null) {
            return ""
        }

        return value
    })()

    return (
        <input
            type={type}
            name={name}
            inputMode={inputMode}
            placeholder={placeholder}
            autoComplete={"new-password"}
            onChange={e => {
                if (type === "number" && /[0-9.,]/.test(e.target.value)) {
                    const parsedFloat = parseFloat(e.target.value)
                    return onChange(!isNaN(parsedFloat) ? parsedFloat : "")
                } else {
                    onChange(e.target.value)
                }
            }}
            value={val}
            className={`form-control ${className} ${isInvalid ? "is-invalid" : ""} ${classNames}`}
        />
    )
}

export default MyInputField
