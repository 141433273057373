import React, { useState } from "react"
import { KTSVG } from "../../../../../../../_metronic/helpers"
import {
     disableTwoFactorAuthGenerateCode,
     disableTwoFactorAuthValidateCode,
     enableTwoFactorAuthEmailGenerateCode,
     enableTwoFactorAuthEmailValidateCode,
} from "../../../../core/_requests"
import { AxiosError } from "axios"
import MyModal from "../../../../../../utils/MyModal"
import { useAuth } from "../../../../../auth"
import * as Yup from "yup"
import { useFormik } from "formik"
import { Slide, toast } from "react-toastify"

const TwoFactorAuth: React.FC = () => {
     const { currentUser } = useAuth()
     const [isModalEnableTwoFactorAuthShown, setIsModalEnableTwoFactorAuthShown] = useState<boolean>(false)
     const [isModalDisableTwoFactorAuthShown, setIsModalDisableTwoFactorAuthShown] = useState<boolean>(false)

     return (
          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
               <KTSVG path="/media/icons/duotune/general/gen048.svg" className="svg-icon-2tx svg-icon-primary me-4" />
               <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                    <div className="mb-3 mb-md-0 fw-bold">
                         <h4 className="text-gray-800 fw-bolder">Sécuriser votre compte</h4>
                         <div className="fs-6 text-gray-600 pe-7">
                              L'authentification à deux facteurs (2FA) ajoute une couche de sécurité à votre compte. Vous devrez, à chaque authentification, saisir un code qui vous
                              sera envoyé par email ou SMS.
                         </div>
                    </div>
                    <button
                         className="btn btn-primary px-6 align-self-center text-nowrap"
                         onClick={() => (!currentUser?.user?.isTwoFaEnabled ? setIsModalEnableTwoFactorAuthShown(true) : setIsModalDisableTwoFactorAuthShown(true))}
                    >
                         {currentUser?.user?.isTwoFaEnabled ? "Désactiver" : "Activer"}
                    </button>
               </div>

               {isModalEnableTwoFactorAuthShown && (
                    <ModalEnable2FactorAuthentication show={isModalEnableTwoFactorAuthShown} handleClose={() => setIsModalEnableTwoFactorAuthShown(false)} />
               )}
               {isModalDisableTwoFactorAuthShown && (
                    <ModalDisable2FactorAuthentication show={isModalDisableTwoFactorAuthShown} handleClose={() => setIsModalDisableTwoFactorAuthShown(false)} />
               )}
          </div>
     )
}

type PropsModalEnable2FactorAuthentication = {
     show: boolean
     handleClose: () => void
}
const ModalEnable2FactorAuthentication: React.FC<PropsModalEnable2FactorAuthentication> = ({ show, handleClose }) => {
     const EMAIL_2FA = "EMAIL_2FA"
     const SMS_2FA = "SMS_2FA"
     const [checkedMethod, setCheckedMethod] = useState<string>(EMAIL_2FA)
     const [submittedMethod, setSubmittedMethod] = useState<string | null>(null)
     const [error, setError] = useState<string | null>(null)
     const [loading, setLoading] = useState<boolean>(false)

     function HandleSubmit() {
          setLoading(true)
          setError(null)

          enableTwoFactorAuthEmailGenerateCode()
               .then(() => {
                    setSubmittedMethod(checkedMethod)
                    setLoading(false)
               })
               .catch((r: AxiosError) => {
                    if (r.response?.status === 409 || r.response?.status === 400) {
                         setError(r.response?.data?.detail)
                    } else {
                         setError("Une erreur est survenue lors de l'envoi du code. Veuillez re-essayer.")
                    }
                    setLoading(false)
               })
     }

     return (
          <MyModal title={<>Activation de l'authentification à deux facteurs (2FA)</>} show={show} handleClose={handleClose}>
               <>
                    {submittedMethod === EMAIL_2FA && <ModalEnable2FactorAuthenticationEmail handleClose={handleClose} cancel={() => setSubmittedMethod(null)} />}
                    {submittedMethod === SMS_2FA && <ModalEnable2FactorAuthenticationSms />}

                    {submittedMethod === null && (
                         <>
                              {error && (
                                   <div className="mb-lg-15 alert alert-danger">
                                        <div className="alert-text font-weight-bold">{error}</div>
                                   </div>
                              )}

                              <p className="text-muted fs-5 fw-semibold mb-10">
                                   En plus de votre identifiant et mot de passe, vous devrez saisir le code envoyé par mail ou par sms.
                              </p>

                              <div className="pb-10">
                                   {/*begin::Option*/}
                                   <input
                                        type="radio"
                                        className="btn-check"
                                        name="auth_option"
                                        value={EMAIL_2FA}
                                        checked={checkedMethod === EMAIL_2FA}
                                        onChange={e => setCheckedMethod(e.target.value)}
                                        id="kt_modal_two_factor_authentication_option_1"
                                   />
                                   <label
                                        className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-5"
                                        htmlFor="kt_modal_two_factor_authentication_option_1"
                                   >
                                        {/*begin::Svg Icon*/}
                                        <span className="svg-icon svg-icon-4x me-4">
                                             <KTSVG path="/media/icons/duotune/communication/com002.svg" className="svg-icon-2" />
                                        </span>
                                        {/*end::Svg Icon*/}
                                        <span className="d-block fw-semibold text-start">
                                             <span className="text-dark fw-bold d-block fs-3">Email</span>
                                             <span className="text-muted fw-semibold fs-6">Vous devrez saisir un code envoyé par mail à chaque authentification.</span>
                                        </span>
                                   </label>
                                   {/*end::Option*/}
                                   {/*begin::Option*/}
                                   <input
                                        type="radio"
                                        className="btn-check"
                                        name="auth_option"
                                        value={SMS_2FA}
                                        checked={checkedMethod === SMS_2FA}
                                        onChange={e => setCheckedMethod(e.target.value)}
                                        disabled={true}
                                        id="kt_modal_two_factor_authentication_option_2"
                                   />
                                   <label
                                        className="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                                        htmlFor="kt_modal_two_factor_authentication_option_2"
                                   >
                                        {/*begin::Svg Icon*/}
                                        <span className="svg-icon svg-icon-4x me-4">
                                             <KTSVG path="/media/icons/duotune/communication/com003.svg" className="svg-icon-2" />
                                        </span>
                                        {/*end::Svg Icon*/}
                                        <span className="d-block fw-semibold text-start">
                                             <span className="text-dark fw-bold d-block fs-3">SMS</span>
                                             <span className="text-muted fw-semibold fs-6">Vous devrez saisir un code envoyé par SMS à chaque authentification.</span>
                                        </span>
                                   </label>
                                   {/*end::Option*/}
                              </div>
                              <button className="btn btn-primary w-100" data-kt-element="options-select" onClick={() => HandleSubmit()}>
                                   {!loading && "Continuer"}
                                   {loading && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                             Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                   )}
                              </button>
                         </>
                    )}
               </>
          </MyModal>
     )
}

type PropsModalEnable2FactorAuthenticationEmail = {
     handleClose: () => void
     cancel: () => void
}
const ModalEnable2FactorAuthenticationEmail: React.FC<PropsModalEnable2FactorAuthenticationEmail> = ({ handleClose, cancel }) => {
     const { setCurrentUser, currentUser } = useAuth()

     const enable2FactorAuthEmailFormSchema = Yup.object().shape({
          password: Yup.string().required("Ce champ est requis"),
     })
     const [loading, setLoading] = useState<boolean>(false)
     const [error, setError] = useState<string | null>(null)

     const formik = useFormik<{ password: string }>({
          initialValues: {
               password: "",
          },
          validationSchema: enable2FactorAuthEmailFormSchema,
          onSubmit: values => {
               setLoading(true)
               setError(null)

               enableTwoFactorAuthEmailValidateCode(values.password)
                    .then(() => {
                         setCurrentUser(prev => (prev ? { ...prev, user: prev.user ? {...prev.user, isTwoFaEnabled: true} : undefined } : undefined))
                         toast.success("La double couche d'authentification a bien été activée pour ce compte.", {
                              position: "top-right",
                              autoClose: 2500,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              transition: Slide,
                              draggable: true,
                              theme: "light",
                         })
                         setLoading(false)
                         handleClose()
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 409 || e.response?.status === 400) {
                              setError(e.response?.data?.detail)
                         } else {
                              setError("Une erreur inconnue est survenue. Veuillez ré-essayer.")
                         }
                         setLoading(false)
                    })
          },
     })

     return (
          <form onSubmit={formik.handleSubmit} noValidate className={"form"}>
               {error && (
                    <div className="mb-lg-15 alert alert-danger">
                         <div className="alert-text font-weight-bold">{error}</div>
                    </div>
               )}

               <h3 className="text-dark fw-bold fs-3 mb-5">Email: Vérifiez votre boite de réception</h3>
               <div className="text-muted fw-semibold mb-10">Entrez le code envoyé sur votre adresse email.</div>
               <div className="mb-10 fv-row fv-plugins-icon-container">
                    <input
                         type="text"
                         className="form-control form-control-lg form-control-solid"
                         placeholder="Saisissez le code ..."
                         autoComplete={"off"}
                         {...formik.getFieldProps("password")}
                    />
                    {formik.touched.password && formik.errors.password && (
                         <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.password}</div>
                         </div>
                    )}
               </div>

               <div className="d-flex flex-center">
                    <button type="reset" data-kt-element="sms-cancel" className="btn btn-light me-3" onClick={() => cancel()}>
                         Annuler
                    </button>
                    <button type="submit" data-kt-element="sms-submit" className="btn btn-primary">
                         {!loading && "Activer"}
                         {loading && (
                              <span className="indicator-progress" style={{ display: "block" }}>
                                   Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                         )}
                    </button>
               </div>
          </form>
     )
}

const ModalEnable2FactorAuthenticationSms = () => {
     return <>Unavaible</>
}

type PropsModalEnable2FactorAuthenticationSms = {
     show: boolean
     handleClose: () => void
}
const ModalDisable2FactorAuthentication: React.FC<PropsModalEnable2FactorAuthentication> = ({ show, handleClose }) => {
     const { setCurrentUser, currentUser } = useAuth()

     const enable2FactorAuthEmailFormSchema = Yup.object().shape({
          password: Yup.string().required("Ce champ est requis"),
     })
     const [loadingDisable, setLoadingDisable] = useState<boolean>(false)
     const [loadingSendCode, setLoadingSendCode] = useState<boolean>(false)
     const [isCodeSent, setIsCodeSent] = useState<boolean>(false)
     const [error, setError] = useState<string | null>(null)

     function HandleSendMailWithCode() {
          setLoadingSendCode(true)
          setError(null)

          disableTwoFactorAuthGenerateCode()
               .then(() => {
                    setLoadingSendCode(false)
                    setIsCodeSent(true)
                    toast.success("Le code a bien été envoyé par mail.", {
                         position: "top-right",
                         autoClose: 2500,
                         hideProgressBar: false,
                         closeOnClick: true,
                         pauseOnHover: true,
                         transition: Slide,
                         draggable: true,
                         theme: "light",
                    })
               })
               .catch((r: AxiosError) => {
                    if (r.response?.status === 409 || r.response?.status === 400) {
                         setError(r.response?.data?.detail)
                    } else {
                         setError("Une erreur est survenue lors de l'envoi du code. Veuillez re-essayer.")
                    }
                    setLoadingSendCode(false)
               })
     }

     const formik = useFormik<{ password: string }>({
          initialValues: {
               password: "",
          },
          validationSchema: enable2FactorAuthEmailFormSchema,
          onSubmit: values => {
               setLoadingDisable(true)
               setError(null)

               disableTwoFactorAuthValidateCode(values.password)
                    .then(() => {
                         setCurrentUser(prev => (prev ? { ...prev, user: prev.user ? {...prev.user, isTwoFaEnabled: false} : undefined } : undefined))
                         toast.success("La double couche d'authentification a bien été désactivée pour ce compte.", {
                              position: "top-right",
                              autoClose: 2500,
                              hideProgressBar: false,
                              closeOnClick: true,
                              pauseOnHover: true,
                              transition: Slide,
                              draggable: true,
                              theme: "light",
                         })
                         setLoadingDisable(false)
                         handleClose()
                    })
                    .catch((e: AxiosError) => {
                         if (e.response?.status === 409 || e.response?.status === 400) {
                              setError(e.response?.data?.detail)
                         } else {
                              setError("Une erreur inconnue est survenue. Veuillez ré-essayer.")
                         }
                         setLoadingDisable(false)
                    })
          },
     })

     return (
          <MyModal title={<>Désactivation de l'authentification à deux facteurs (2FA)</>} show={show} handleClose={handleClose}>
               <form onSubmit={formik.handleSubmit} noValidate className={"form"}>
                    {error && (
                         <div className="mb-lg-15 alert alert-danger">
                              <div className="alert-text font-weight-bold">{error}</div>
                         </div>
                    )}

                    <div className="text-muted fw-semibold mb-10">Désactivez la double authentification en saisissant le code envoyé par mail.</div>
                    <div className="row">
                         <div className="col-8">
                              <input
                                   type="text"
                                   className="form-control form-control-lg form-control-solid"
                                   placeholder="Saisissez le code ..."
                                   autoComplete={"off"}
                                   {...formik.getFieldProps("password")}
                              />
                         </div>
                         <div className="col-4 d-grid">
                              <button type="button" data-kt-element="sms-submit" className="btn btn-primary me-2" onClick={() => HandleSendMailWithCode()} disabled={isCodeSent}>
                                   {!loadingSendCode && "1: Envoyer le code"}
                                   {loadingSendCode && (
                                        <span className="indicator-progress" style={{ display: "block" }}>
                                             Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                        </span>
                                   )}
                              </button>
                         </div>
                    </div>
                    {formik.touched.password && formik.errors.password && (
                         <div className="fv-plugins-message-container">
                              <div className="fv-help-block">{formik.errors.password}</div>
                         </div>
                    )}

                    <div className="d-flex flex-center mt-10">
                         <button type="submit" data-kt-element="sms-submit" className="btn btn-primary me-2">
                              {!loadingDisable && "2: Désactiver"}
                              {loadingDisable && (
                                   <span className="indicator-progress" style={{ display: "block" }}>
                                        Veuillez patienter... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                   </span>
                              )}
                         </button>
                         <button type="reset" data-kt-element="sms-cancel" className="btn btn-light" onClick={() => handleClose()}>
                              Annuler
                         </button>
                    </div>
               </form>
          </MyModal>
     )
}

export { TwoFactorAuth }
