import { Link } from "react-router-dom"
import React from "react"
import { useAuth } from "../../../../auth"
import InfoPersoEditFormEspaceCandidat from "./InfoPersoEditFormEspaceCandidat"
import InfoPersoEditFormEspacePreembaucheSousTraitant from "./InfoPersoEditFormEspacePreembaucheSousTraitant"
import InfoPersoEditFormEspacePreembaucheSalarie from "./InfoPersoEditFormEspacePreembaucheSalarie"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_USER_ROLE_CANDIDAT, CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE, CONST_USER_ROLE_CONSULTANT } from "../../../../../constants"
import InfoPersoEditFormEspaceConsultantSousTraitant from "./InfoPersoEditFormEspaceConsultantSousTraitant"
import InfoPersoEditFormEspaceConsultantSalarie from "./InfoPersoEditFormEspaceConsultantSalarie"

export function InfoPersoEdit() {
     const { currentUser } = useAuth()

     return (
          <>
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT) && <InfoPersoEditFormEspaceCandidat />}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.consultant?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST && (
                    <InfoPersoEditFormEspacePreembaucheSousTraitant />
               )}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE) && currentUser?.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                    <InfoPersoEditFormEspacePreembaucheSalarie />
               )}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST && (
                    <InfoPersoEditFormEspaceConsultantSousTraitant />
               )}
               {currentUser?.user?.roles?.includes(CONST_USER_ROLE_CONSULTANT) && currentUser?.consultant?.consultant?.contrat !== CONST_CONSULTANT_CONTRAT_ST && (
                    <InfoPersoEditFormEspaceConsultantSalarie />
               )}
          </>
     )
}

export function InfoPersoEditWrapper() {
    return <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
        <div className='card-header'>
            <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>Mes informations personnelles</h3>
            </div>

            <Link to="/mon-compte/profil" className='btn btn-sm btn-primary align-self-center d-none d-sm-block'>
                Abandonner le mode édition
            </Link>

            <Link to="/mon-compte/profil" className={`d-sm-none d-block align-self-center`}>
                <i className={"fas fa-eye text-primary"} style={{fontSize: '1.5rem'}}/>
            </Link>
        </div>

        <div className='card-body'>
            <InfoPersoEdit/>
        </div>
    </div>
}



