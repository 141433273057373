import { useAuth } from "../../../../../auth"
import React from "react"
import { CONST_CONSULTANT_CONTRAT_ST } from "../../../../../../constants"
import { InfoPersoViewOnlyEspacePreemabaucheSousTraitant } from "./InfoPersoViewOnlyEspacePreemabaucheSousTraitant"
import { InfoPersoViewOnlyEspacePreemabaucheSalarie } from "./InfoPersoViewOnlyEspacePreemabaucheSalarie"

export function InfoPersoViewOnlyEspacePreemabauche() {
     const { currentUser } = useAuth()

     return (
          <>
               {currentUser?.consultant?.consultant?.contrat === CONST_CONSULTANT_CONTRAT_ST ? (
                    <InfoPersoViewOnlyEspacePreemabaucheSousTraitant />
               ) : (
                    <InfoPersoViewOnlyEspacePreemabaucheSalarie />
               )}
          </>
     )
}

