import clsx from 'clsx'
import {KTSVG} from '../../../helpers'
import {ThemeModeComponent} from '../../../assets/ts/layout'
import {ThemeModeType, useThemeMode} from './ThemeModeProvider'

/* eslint-disable jsx-a11y/anchor-is-valid */
type Props = {
    toggleBtnClass?: string
    toggleBtnIconClass?: string
}

const systemMode = ThemeModeComponent.getSystemMode() as 'light' | 'dark'

const ThemeModeSwitcher = ({
                               toggleBtnClass = '',
                               toggleBtnIconClass = 'svg-icon-2'
                           }: Props) => {
    const {mode, menuMode, updateMode, updateMenuMode} = useThemeMode()
    const calculatedMode = mode === 'system' ? systemMode : mode
    const switchMode = (_mode: ThemeModeType) => {
        updateMenuMode(_mode)
        updateMode(_mode)
    }

    return (
        <>
            <a
                href='#'
                className={clsx('btn btn-icon ', toggleBtnClass)}
                onClick={() => menuMode == 'dark' ? switchMode('light') : switchMode('dark')}
            >
                {calculatedMode === 'dark' && (
                    <KTSVG

                        path='/media/icons/duotune/general/gen061.svg'
                        className={clsx('theme-light-hide', toggleBtnIconClass)}
                    />
                )}

                {calculatedMode === 'light' && (
                    <KTSVG
                        path='/media/icons/duotune/general/gen060.svg'
                        className={clsx('theme-dark-hide', toggleBtnIconClass)}
                    />
                )}
            </a>
        </>
    )
}

export {ThemeModeSwitcher}
