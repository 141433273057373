import React, { useCallback, useState } from "react"
import Select from "react-select"
import axios, { AxiosResponse } from "axios"
import { useIntl } from "react-intl"
import useSelectReact from "../../hooks/useSelectReact"

type Props = {
     url: string
     name: string
     value: string | number | Array<string | number> | undefined | null
     onChange: (value: string | number | undefined) => void
     isClearable?: boolean
     placeholder?: string
     method?: "POST" | "GET" /* TODO: get rid of method after adding common submodule to Talent. Only GET Requests will be sent. */
     payload?: {}
     isMulti?: boolean
     isSearchable?: boolean
     defaultOptions?: Array<{ label: string; value: string | number }>
     className?: string
}

const MySelectGetAllDataFromServer: React.FC<Props> = ({
     name,
     value,
     onChange,
     url,
     isClearable = true,
     isSearchable = true,
     placeholder = "Choisissez",
     method = "GET",
     payload = undefined,
     isMulti = false,
     defaultOptions = [],
     className = "",
}) => {
     const { formatGroupLabel, styles } = useSelectReact()
     const intl = useIntl()

     const [isLoading, setIsLoading] = useState<boolean>(false)
     const [options, setOptions] = useState<any>(defaultOptions)
     const [noOptionsMessage, setNoOptionsMessage] = useState<string>("Aucun résultat")

     function getOptions() {
          setIsLoading(true)

          let result
          if (method === "GET") {
               /* Convert true to 1 and false to 0 for simplifying backend operations. */
               for (const key in payload) {
                    if (payload[key] === true) payload[key] = 1
                    if (payload[key] === false) payload[key] = 0
               }
               result = axios.get<Array<{ label: string; value: string | number }>>(url, { params: payload })
          } else {
               result = axios.post<Array<{ label: string; value: string | number }>>(url, payload)
          }

          result
               .then((r: AxiosResponse) => {
                    setOptions(r.data)
               })
               .catch(() => {
                    setOptions([])
                    setNoOptionsMessage(intl.formatMessage({ id: "FORM.STATUS.UNEXPECTED_ERROR_MESSAGE" }))
               })
               .finally(() => {
                    setIsLoading(false)
               })
     }

     // selectValue is based on "value". It will get all options matching with value
     const selectValue = useCallback(() => {
          if (!value) return []
          if (Array.isArray(value)) {
               if (value.length == 0) return []

               let selectedOptions: any[] = []
               options.map(opt => {
                    // if opt has options it means that it's an optgroup
                    if ("options" in opt) {
                         opt.options.map(item => {
                              // @ts-ignore
                              if (value.includes(item.value)) selectedOptions.push(item)
                         })
                    } else {
                         // @ts-ignore
                         if (value.includes(opt.value)) selectedOptions.push(opt)
                    }
               })
               return selectedOptions
          } else {
               return options.find(opt => opt.value === value)
          }
     }, [value])

     return (
          <Select
               isClearable={isClearable}
               isSearchable={isSearchable}
               placeholder={placeholder}
               onFocus={getOptions}
               name={name}
               options={options}
               isLoading={isLoading}
               formatGroupLabel={formatGroupLabel}
               styles={styles(false)}
               isMulti={isMulti}
               loadingMessage={() => "Chargement en cours ..."}
               value={selectValue()}
               menuPortalTarget={document.body}
               onChange={val => {
                    if (Array.isArray(val)) {
                         // @ts-ignore
                         onChange(val.map(i => i.value)) // Form only needs values
                    } else {
                         onChange(val?.value) // Form only needs the value
                    }
               }}
               noOptionsMessage={() => (options.length > 0 ? "Aucun résultat" : noOptionsMessage)}
               className={className}
          />
     )
}

export default MySelectGetAllDataFromServer
