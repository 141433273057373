import {OverlayTrigger, Tooltip} from "react-bootstrap";
import React, {useState} from "react";
import {randomId} from "../helpers/helper-global";
import {isBrowser} from "react-device-detect";

type Props = {
    title: JSX.Element | string
    placement?: 'top' | 'bottom' | 'right' | 'left'
    children: JSX.Element
    trigger?: 'click' | 'hover' | ['click', 'hover']
}

const MyTooltip: React.FC<Props> = ({title, placement, children, trigger = ['click', 'hover']}) => {
    const id = `tooltip_${randomId()}`
    const [visible, setVisible] = useState<boolean>(false);
    return isBrowser ? <OverlayTrigger
        overlay={<Tooltip id={id}>{title}</Tooltip>}
        defaultShow={undefined}
        delay={{show: 200, hide: 100}}
        flip={undefined}
        onHide={undefined}
        placement={placement}
        popperConfig={undefined}
        target={undefined}
        rootClose={true}
        onToggle={undefined}
        trigger={trigger}
        show={undefined}>
        {children}
    </OverlayTrigger> : children
}

export default MyTooltip;
