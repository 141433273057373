import {createContext, FC, useContext,} from 'react'
import {WithChildren} from "../../../_metronic/helpers";

type AppCandidatContextProps = {
}

const AppCandidatContextPropsState = {

}

const AppCandidatContext = createContext<AppCandidatContextProps>(AppCandidatContextPropsState)

const useAppCandidat = () => {
    return useContext(AppCandidatContext)
}

const AppCandidatProvider: FC<WithChildren> = ({children}) => {
    return (
        <AppCandidatContext.Provider value={{}}>
            {children}
        </AppCandidatContext.Provider>
    )
}


export {useAppCandidat, AppCandidatProvider}
